import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import { encryptStorage } from "../../constants/EncryptStorage";
import {
  LoadingSpinner,
  isChildInHierarchy,
  isUrdu,
} from "../../constants/const"; 
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import useSorting from "../shared/useSorting";
import { ApiUnit } from "../unit/ApiUnit";
import { ApiEvent } from "./ApiEvent";
import SearchEvent from "./SearchEvent";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  unit: "neutral",
  date: "neutral",
  eventType: "neutral",
};

const Events = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const isIncharge = encryptStorage.getItem("canManage");
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [canAdd, setCanAdd] = useState();

  const currentDate = new Date();

  // Get the year and month of the next month
  const nextMonthYear =
    currentDate.getMonth() === 11
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();
  const nextMonth =
    currentDate.getMonth() === 11 ? 0 : currentDate.getMonth() + 1;

  // Create a new date object for the same day of the next month
  const dateOfNextMonth = new Date(
    nextMonthYear,
    nextMonth,
    currentDate.getDate()
  );
  const previousMonthYear =
    currentDate.getMonth() === 0
      ? currentDate.getFullYear() - 1
      : currentDate.getFullYear();
  const previousMonth =
    currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;

  // Create a new date object for the same day of the previous month
  const dateOfPreviousMonth = new Date(
    previousMonthYear,
    previousMonth,
    currentDate.getDate()
  );

  const [searchEventModel, setSearchEventModel] = useState({
    unit: { value: loggedInUnitId, label: "", label1: unitTypeName },
    startDate: dateOfPreviousMonth,
    endDate: dateOfNextMonth,
    isSubUnits: true,
  });
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (searchEventModel.unit.value === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setSearchEventModel({
            ...searchEventModel,
            unit: temp2,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [loggedInUnitId, searchEventModel]);

  useEffect(() => {
    setLoading(true);

    let tempSortObject = {
      isUnitDescending: sortObject.unit === "descending",
      isUnitAscending: sortObject.unit === "ascending",
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isEventTypeAscending: sortObject.eventType === "ascending",
      isEventTypeDescending: sortObject.eventType === "descending",
    };
    searchEventModel.sortModel = tempSortObject;
    if (searchButton) {
      ApiEvent()
        .get(searchEventModel)
        .then((res) => {
          setEvents(res.data.events);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchEventModel, sortObject, searchButton, deleted]);

  useEffect(() => {
    ApiEvent()
      .canAddEvent()
      .then((res) => {
        setCanAdd(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnDelete = (value, isRecurring) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: {
        cancel: {
          text: t("events_cancel_button"),
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        ok: {
          text: t("common_ok_button"),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (isRecurring) {
          swal({
            title: t("events_recurring_title_alert"),
            text: t("events_attendance_delete_text_alert"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: {
              cancel: {
                text: t("events_cancel_button"),
                value: false,
                visible: true,
                className: "",
                closeModal: true,
              },
              updateOne: {
                text: t("events_delete_this_button"),
                value: "updateOne",
                visible: true,
                className: "",
                closeModal: true,
              },
              updateAll: {
                text: t("events_delete_all_button"),
                value: "updateAll",
                visible: true,
                className: "",
                closeModal: true,
              },
            },
          }).then((updateValue) => {
            if (updateValue) {
              setLoading(true);
              ApiEvent()
                .delete(value, updateValue === "updateAll")
                .then((res) => {
                  swal({
                    title: t("events_delete_event_success"),
                    className: GetSelectedLanguage() === "en" ? "" : "ur",
                    icon: "success",
                    buttons: t("common_ok_button"),
                  });
                  setLoading(false);
                  setDeleted(!deleted);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }
          });
        } else {
          setLoading(true);
          ApiEvent()
            .delete(value, false)
            .then((res) => {
              swal({
                title: t("events_delete_event_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
              });
              setLoading(false);
              setDeleted(!deleted);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      }
    });
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    history.push({
      pathname: `${match.url}/editevent/${value}`,
      state: {
        editValue: value,
      },
    });
  };

  const handleParticipantsAttendance = (
    eventId,
    eventUnitId,
    eventUnitType,
    // subTypeValue,
    unitName,
    eventAddress,
    eventDate,
    eventType,
    forChildUnit,
    forAhbab,
    filterExist
  ) => {
    history.push({
      pathname: `${match.url}/eventAttendance`,
      state: {
        eventId: eventId,
        eventUnitId: isChildInHierarchy(unitTypeName, eventUnitType)
          ? loggedInUnitId
          : eventUnitId,
        unitName: unitName,
        eventAddress: eventAddress,
        eventDate: eventDate,
        eventType: eventType,
        forChildUnit: forChildUnit,
        forAhbab: forAhbab,
        filterExist: filterExist,
      },
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="event_search" />}</h5>
          {canAdd ? (
            <Button
              color="primary"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() =>
                history.push({
                  pathname: `${match.url}/addevent`,
                  state: {
                    unitId: searchEventModel.unit.value,
                  },
                })
              }
            >
              <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
              {<GetLanguageString props="events_add_event" />}
            </Button>
          ) : null}
        </div>
        <div className="ibox-content">
          <SearchEvent
            unitId={searchEventModel.unit.value}
            searchEventModel={searchEventModel}
            setSearchEventModel={setSearchEventModel}
            setSearchButton={setSearchButton}
          />
        </div>
      </div>
      {searchButton && (
        <div className="ibox ">
          <div className="ibox-title">
            <h5> {<GetLanguageString props="events" />}</h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              events.length !== 0 ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("eventType")}
                        >
                          <GetLanguageString props="events_event_type" />
                          <GetSortingUI
                            sortObject={sortObject}
                            name="eventType"
                          />
                        </th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("unit")}
                        >
                          <GetLanguageString props="events_unit" />
                          <GetSortingUI sortObject={sortObject} name="unit" />
                        </th>
                        <th
                          className="cursor-pointer text-center"
                          onClick={() => handleSorting("date")}
                        >
                          <GetLanguageString props="events_date" />
                          <GetSortingUI sortObject={sortObject} name="date" />
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="events_end_date" />}
                        </th>
                        <th>{<GetLanguageString props="events_address" />}</th>
                        <th>
                          {" "}
                          {<GetLanguageString props="events_description" />}
                        </th>
                        <th>
                          {" "}
                          {
                            <GetLanguageString props="settings_event_type_allow_sub_units" />
                          }
                        </th>
                        {isIncharge ? (
                          <th className="text-center">
                            {<GetLanguageString props="common_action" />}
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur">{record.eventType.label}</td>
                            <td className="ur">{record.unit.label}</td>
                            <td className="text-center en" dir="ltr">
                              {moment.utc(record.date).format(
                                "DD/MM/yyyy - hh:mm a"
                              )}
                            </td>
                            <td className="text-center en" dir="ltr">
                              {record.endDate !== null
                                ? moment.utc(record.endDate).format(
                                    "DD/MM/yyyy - hh:mm a"
                                  )
                                : ""}
                            </td>
                            <td className={isUrdu(record.address) ? "ur" : ""}>
                              {record.address}
                            </td>
                            <td className="ur">{record.description}</td>
                            <td>
                              {record.canSubunitMarkAttendance ? "Yes" : "No"}
                            </td>
                            <td className="text-center">
                              <ButtonGroup>
                                {/* {record.canMarkAttendance && record.unit.label1 ===
                                        searchEventModel.unit.label1 && record.canSubunitMarkAttendance ? ( */}
                                {/* moment(
                                    convertUTCToLocalTime(record.date)
                                  ).isBefore(Date.now()) ? ( */}

                                {record.canMarkAttendance &&
                                moment(record.date).isBefore(Date.now()) ? (
                                  record.unit.label1 ===
                                  searchEventModel.unit.label1 ? (
                                    <Button
                                      color="white"
                                      size="sm"
                                      onClick={() => {
                                        handleParticipantsAttendance(
                                          record.id,
                                          record.unit.value,
                                          record.unit.label1,
                                          // record.subType.value,
                                          record.unit.label,
                                          record.address,
                                          record.date,
                                          record.eventType.label,
                                          record.forChildUnit,
                                          record.forAhbab,
                                          record.filterExist
                                        );
                                      }}
                                    >
                                      {
                                        <GetLanguageString props="common_attendance" />
                                      }
                                    </Button>
                                  ) : record.canSubunitMarkAttendance &&
                                    record.unit.label1 !==
                                      searchEventModel.unit.label1 ? (
                                    <Button
                                      color="white"
                                      size="sm"
                                      onClick={() => {
                                        handleParticipantsAttendance(
                                          record.id,
                                          record.unit.value,
                                          record.unit.label1,
                                          // record.subType.value,
                                          record.unit.label,
                                          record.address,
                                          record.date,
                                          record.eventType.label,
                                          record.forChildUnit,
                                          record.forAhbab,
                                          record.filterExist
                                        );
                                      }}
                                    >
                                      {
                                        <GetLanguageString props="common_attendance" />
                                      }
                                    </Button>
                                  ) : null
                                ) : null}

                                {/* ) : null
                                ) : record.canSubunitMarkAttendance &&
                                  record.unit.label1 !==
                                    searchEventModel.unit.label1 &&
                                  record.canMarkAttendance ? (
                                  moment(
                                    convertUTCToLocalTime(record.date)
                                  ).isBefore(Date.now()) ? ( */}
                                {/* <Button
                                      color="white"
                                      size="sm"
                                      onClick={() =>
                                        handleParticipantsAttendance(
                                          record.id,
                                          record.unit.value,
                                          record.unit.label1,
                                          // record.subType.value,
                                          record.unit.label,
                                          record.address,
                                          record.date,
                                          record.eventType.label,
                                          record.forChildUnit,
                                          record.forAhbab,
                                          record.filterExist
                                        )
                                      }
                                    >
                                      {
                                        <GetLanguageString props="common_attendance" />
                                      }
                                    </Button> */}
                                {/* ) : null
                                ) : null} */}
                                {record.isParentEvent ? null : (
                                  <>
                                    {record.isEditable ? (
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={handleOnEdit}
                                        value={record.id}
                                      >
                                        {
                                          <GetLanguageString props="common_edit" />
                                        }
                                      </Button>
                                    ) : null}
                                    {record.isDeletable ? (
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={() =>
                                          handleOnDelete(
                                            record.id,
                                            record.isRecurring
                                          )
                                        }
                                      >
                                        {
                                          <GetLanguageString props="common_delete" />
                                        }
                                      </Button>
                                    ) : null}
                                  </>
                                )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Events);
