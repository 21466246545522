import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function addMember() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "member/";
  return {
    getMember: (searchMemberModel) =>
      authAxios.post(url + "getmember/", searchMemberModel),
    getMemberDocsReport: (searchValues) =>
      authAxios.post(url + "getMemberDocsReport/", searchValues),
    GetMemberDocsExcelReport: (searchValues) =>
      fileAuthAxios.post(url + "GetMemberDocsExcelReport/", searchValues),
    getUserMember: (searchMemberModel) =>
      authAxios.post(url + "getUserMember/", searchMemberModel),
    getMemberStatusChangeReport: (searchModel) =>
      authAxios.post(url + "GetMemberStatusChangeReport/", searchModel),
    getArrivalsDeparturesReport: (searchModel) =>
      authAxios.post(url + "GetArrivalsDeparturesReport/", searchModel),
    generateMemberStatusChangeReport: (reportData) =>
      fileAuthAxios.post(url + "GenerateMemberStatusChangeReport/", reportData),
    getMembersReport: (reportSearchMemberModel) =>
      authAxios.post(url + "getMembersReport/", reportSearchMemberModel),
    generateMembersReport: (reportData) =>
      fileAuthAxios.post(url + "GenerateMembersReport/", reportData),
    getMembersCustomReport: (reportSearchMemberModel) =>
      authAxios.post(url + "getMembersCustomReport/", reportSearchMemberModel),
    getResponsibleMembersCustomReport: (reportSearchMemberModel) =>
      authAxios.post(
        url + "getResponsibleMembersCustomReport/",
        reportSearchMemberModel
      ),
    GetResponsibleMembersOfficialsReport: (unitId) =>
      authAxios.get(url + "GetResponsibleMembersOfficialsReport/" + unitId),
    GetSubUnitResponsibileMembersMembersOfficialReport: (unitId) =>
      authAxios.get(
        url + "GetSubUnitResponsibileMembersMembersOfficialReport/" + unitId
      ),
    GetUnitJaizaReport: (unitId) =>
      authAxios.get(
        url + "GetUnitJaizaReport/" + unitId
      ),
    GetReappointmentMuqamiAmeerReport: (record) =>
      authAxios.post(url + "GetReappointmentMuqamiAmeerReport", record),
    GetExcelReappointmentMuqamiAmeerReport: (record) =>
      fileAuthAxios.post(
        url + "GetExcelReappointmentMuqamiAmeerReport",
        record
      ),
    GenerateMembersExcelReport: (reportSearchMemberModel) =>
      fileAuthAxios.post(
        url + "GenerateMembersExcelReport/",
        reportSearchMemberModel
      ),
    GenerateUnitJaizaExcelReport: (customMemberReportModel) =>
        fileAuthAxios.post(
          url + "GenerateUnitJaizaExcelReport/",
          customMemberReportModel
        ),
    getNonResponsibleMembersCustomReport: (reportSearchMemberModel) =>
      authAxios.post(
        url + "getNonResponsibleMembersCustomReport/",
        reportSearchMemberModel
      ),
    getSubUnitMembersCustomReport: (reportSearchMemberModel) =>
      authAxios.post(
        url + "getSubUnitMembersCustomReport/",
        reportSearchMemberModel
      ),
    generateMembersCustomReport: (reportData) =>
      fileAuthAxios.post(url + "GenerateMembersCustomReport/", reportData),
    getMemberEventDetails: (searchEventModel) =>
      authAxios.post(url + "getMemberEventDetails/", searchEventModel),
    getUnitMember: (unitId, isSubUnit = false) =>
      authAxios.get(url + "getunitmember/" + unitId + "/" + isSubUnit),
    getUnitMembersForSelecting: (unitId, isSubUnit = false) =>
      authAxios.get(
        url + "getUnitMembersForSelecting/" + unitId + "/" + isSubUnit
      ),
    GetMemberUnitsList: (memberId) =>
      authAxios.get(url + "GetMemberUnitsList/" + memberId),
    DeleteMemberUnit: (deleteData) =>
      authAxios.post(url + "DeleteMemberUnit", deleteData),

    getNonResponsibleUnitMember: (unitId, record) =>
      authAxios.post(url + "getNonResponsibleUnitMember/" + unitId, record),

    getUnitAhbaabMember: (unitId, record) =>
      authAxios.post(url + "getUnitAhbaabMember/" + unitId, record),

    getResponsibleUnitMember: (unitId, record) =>
      authAxios.post(url + "getResponsibleUnitMember/" + unitId, record),
    getMemberById: (id) => authAxios.get(url + "getmemberbyid/" + id),
    getMemberPledgesById: (id) =>
      authAxios.get(url + "GetMemberPledgesById/" + id),
    // Getting a Member's Books
    GetMemberBooksById: (id) => authAxios.get(url + "GetMemberBooksById/" + id),
    // Deleting a Member
    deleteMemberBooks: (id) =>
      authAxios.delete(url + "DeleteMemberBooks/" + id),
    editMemberPledges: (id, formData) =>
      authAxios.put(url + `EditMemberPledges/${id}`, formData),
    GetMemberMissingPlegdes: (memberId) =>
      authAxios.get(url + "GetMemberMissingPlegdes/" + memberId),
    AddMemberPledge: (record) =>
      authAxios.post(url + "AddMemberPledge", record),
    deleteMemberPledges: (id) =>
      authAxios.delete(url + "DeleteMemberPledges/" + id),
    setMember: (newRecord) => authAxios.post(url + "addmember/", newRecord),
    checkMemberCanSendSMS: (memberId) =>
      authAxios.get(url + "checkMemberCanSendSMS/" + memberId),

    cancelRafiqRegistration: (record) =>
      authAxios.put(url + "cancelRafiqRegistration/", record),
    updateMember: (id, updateRecord) =>
      authAxios.put(url + "putmember/" + id, updateRecord),
    getResponsibleMembersList: (responsibleMember) =>
      authAxios.post(url + "getResponsibleMembersList/", responsibleMember),
    getMeetingMembers: (searchMembersModel) =>
      authAxios.post(url + "getMeetingMembers/", searchMembersModel),
    updateMeetingMembersDetails: (meetingMembers) =>
      authAxios.post(url + "UpdateMeetingMembersDetails/", meetingMembers),
    getUnitParticipants: (unitId, name) =>
      authAxios.get(url + "getUnitParticipants/" + unitId + "/" + name),
    getMemberIdByRegNo: (regNo, unitId) =>
      authAxios.get(url + "getMemberIdByRegNo/" + regNo + "/" + unitId),
    getNewRafiqSerialNumber: () =>
      authAxios.get(url + "getNewRafiqSerialNumber"),
    getCancelledRafiqRegistrationSerialNumbers: () =>
      authAxios.get(url + "getCancelledRafiqRegistrationSerialNumbers"),
    GetMemberSimilarRecords: (records) =>
      authAxios.post(url + "GetMemberSimilarRecords", records),
    GetMemberFundsDetails: (record) =>
      authAxios.post(url + "GetMemberFundsDetails", record),

    //Mudariseen

    //GETTING DETAILED DATA OF A MEMBER
    getMemberByIdForDetailData: (id) =>
      authAxios.get(url + "getMemberbyIdForDetailData/" + id),
    getMemberUnitDetail: (id) =>
      authAxios.get(url + "getMemberUnitDetail/" + id + "/" + 0),
    getMemberMaritalStatusDetail: (id) =>
      authAxios.get(url + "GetMemberMaritalStatusDetail/" + id),
    getMemberAddressesDetail: (id) =>
      authAxios.get(url + "GetMemberAddressesDetail/" + id),
    getMemberContactsDetail: (id) =>
      authAxios.get(url + "GetMemberContactsDetail/" + id),
    getMemberTypeDetail: (id) =>
      authAxios.get(url + "GetMemberTypeDetail/" + id),
    getMemberEducationDetail: (id) =>
      authAxios.get(url + "GetMemberEducationDetail/" + id),
    getMemberOccupationDetail: (id) =>
      authAxios.get(url + "GetMemberOccupationDetail/" + id),
    // api to get Members Ijtimaat details
    GetMemberEventAttendance: (memberEventSearchtModel) =>
      authAxios.post(
        url + "GetMemberEventAttendance/",
        memberEventSearchtModel
      ),
    getMemberSkillsDetail: (id) =>
      authAxios.get(url + "GetMemberSkillsDetail/" + id),
    getMemberAccessRights: (memberId) =>
      authAxios.get(url + "getMemberAccessRights/" + memberId),
    //Adding or updating member data api
    AddNewMember: (notificationAction) =>
      authAxios.put(url + "addNewMember/", notificationAction),
    addMemberInfo: (id, addMemberInfo, addNewRafiq) =>
      authAxios.put(
        url + "putMemberDetailedInfo/" + id + "/" + addNewRafiq,
        addMemberInfo
      ),
    addMemberMaritalStatus: (addNewRafiq, addMemberMarital) =>
      authAxios.put(
        url + "PutMemberMaritalStatus/" + addNewRafiq,
        addMemberMarital
      ),
    addMemberAddress: (addNewRafiq, record) =>
      authAxios.put(url + "PutMemberAddress/" + addNewRafiq, record),
    addMemberContact: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberContact/" + addNewRafiq, record),
    addMemberType: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberType/" + addNewRafiq, record),
    addMemberEducation: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberEducation/" + addNewRafiq, record),
    addMemberOccupation: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberOccupation/" + addNewRafiq, record),
    addMemberSkills: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberSkills/" + addNewRafiq, record),
    addMemberUnit: (record, addNewRafiq) =>
      authAxios.put(url + "PutMemberUnit/" + addNewRafiq, record),
    uploadMemberPhoto: (id, formData) =>
      authAxios.post(url + "UploadMemberPhoto/" + id, formData),
    updateMemberPhoto: (id, formData) =>
      authAxios.post(url + "UpdateMemberPhoto/" + id, formData),
    getMemberPhoto: (memberId) =>
      authAxios.get(url + "GetMemberPhoto/" + memberId),
    saveCropImage: (id, formData) =>
      authAxios.post(url + "SaveCropImage/" + id, formData),
    getImageToCrop: () => authAxios.get(url + "GetImageToCrop"),
    deleteMemberPhoto: (memberId) =>
      authAxios.delete(url + "DeleteMemberPhoto/" + memberId),
    GetPreviousMembersList: (record) =>
      authAxios.post(url + "GetPreviousMembersList/", record),
    SetMemberPerviousRecord: (memberId, perviousId) =>
      authAxios.post(
        url + "setMemberPerviousRecord/" + memberId + "/" + perviousId
      ),
    GetAssociatedMembersList: (record) =>
      authAxios.post(url + "GetAssociatedMembersList/", record),
    AddAssociatedMember: (habibId, memberHabibId, memberId) =>
      authAxios.put(
        url +
          "AddAssociatedMember/" +
          habibId +
          "/" +
          memberHabibId +
          "/" +
          memberId
      ),
    DeleteMemberPerviousRecord: (memberId) =>
      authAxios.delete(url + "deleteMemberPerviousRecord/" + memberId),
    GetMemberDataForDetails: (memberId) =>
      authAxios.get(url + "GetMemberDataForDetails/" + memberId),

    //MemberResponsibility
    getMemberResponsibilityList: (id, getAllResponsibilities = false) =>
      authAxios.get(
        url + "GetMemberResponsibilityList/" + id + "/" + getAllResponsibilities
      ),
    memberResponsibilityRenewal: (data) =>
      authAxios.post(url + "MemberResponsibilityRenewal", data),
    getMemberResponsibilities: (memberId) =>
      authAxios.get(url + "getMemberResponsibilities/" + memberId),
    GetMemberResponsibilitiesToDelete: (memberId) =>
      authAxios.get(url + "GetMemberResponsibilitiesToDelete/" + memberId),
    addMemberResponsibility: (record, addNewRafiq) =>
      authAxios.post(url + "addMemberResponsibility/" + addNewRafiq, record),
    editMemberResponsibility: (record) =>
      authAxios.post(url + "EditMemberResponsibility", record),
    deleteMemberResponsibility: (values) =>
      authAxios.post(url + "DeleteMemberResponsibility/", values),
    checkCanAddMemberResponsibility: (id) =>
      authAxios.get(url + "checkCanAddMemberResponsibility/" + id),
    checkChangeMemberUnit: (values, responsibilityCount) =>
      authAxios.post(
        url + "CheckChangeMemberUnit/" + responsibilityCount,
        values
      ),

    //Documents
    GetMemberDocs: (memberId, docType) =>
      authAxios.get(url + "GetMemberDocs/" + memberId + "/" + docType),
    GeMemberDocsNames: (memberId) =>
      authAxios.get(url + "geMemberDocsNames/" + memberId),
    GetAddDocumentTypeDropdowns: (memberId) =>
      authAxios.get(url + "getAddDocumentTypeDropdowns/" + memberId),
    GetAddPledgesDropdowns: (memberId, docTypeId) =>
      authAxios.get(
        url + "getAddPledgesDropdowns/" + memberId + "/" + docTypeId
      ),
    GetFileToSave: (memberId, docType, file) =>
      authAxios.put(url + "GetFileToSave/" + memberId + "/" + docType, file),
    AddNewMemberDocuments: (model) =>
      authAxios.put(url + "AddNewMemberDocuments/", model),
    EditDocument: (documentId) =>
      authAxios.get(url + "EditDocument/" + documentId),
    EditDocumentSave: (documentId, model) =>
      authAxios.put(url + "EditDocumentSave/" + documentId, model),
    DeleteDocument: (documentId) =>
      authAxios.delete(url + "DeleteDocument/" + documentId),
    GeMemberPLedgeDocsNames: (memberId, pledgeId) =>
      authAxios.get(
        url + "GeMemberPLedgeDocsNames/" + memberId + "/" + pledgeId
      ),

    getDropDown: (id) =>
      authAxios.get(url + "getMemberResposibilityDropDown/" + id),

    GetMemberLetterForCounselingData: (id) =>
      authAxios.get(url + "GetMemberLetterForCounselingData/" + id),
    GetLetterForCounsellingQuestions: (id, memberId) =>
      authAxios.get(
        url + "GetLetterForCounsellingQuestions/" + id + "/" + memberId
      ),
    AddMemberLetterForCounselling: (record) =>
      authAxios.post(url + "AddMemberLetterForCounselling", record),
    EditMemberLetterForCounselling: (record) =>
      authAxios.post(url + "EditMemberLetterForCounselling", record),
    EditReceivedLetterForCounselling: (id) =>
      authAxios.get(url + "EditReceivedLetterForCounselling/" + id),
    DeleteReceivedLetterForCounselling: (id) =>
      authAxios.delete(url + "DeleteReceivedLetterForCounselling/" + id),

    //HABIB FROM CONTACT US FORM
    AddHabibFromContactUsForm: (record) =>
      authAxios.put(url + "AddHabibFromContactUsForm/", record),
  };
}
export function memberTypeApi() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "membertype/";
  return {
    getMemberTypes: () => authAxios.get(url + "GetMemberTypeList"),
    getModifiableMemberTypes: () =>
      authAxios.get(url + "GetModifiableMemberTypes"),
    getModificationRequestableMemberTypes: () =>
      authAxios.get(url + "GetModificationRequestableMemberTypes"),
    GetModifyAndRequestCombinedMemberTypes: () =>
      authAxios.get(url + "GetModifyAndRequestCombinedMemberTypes"),
  };
}

export function setupApi() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "setup/";
  return {
    //SETUP
    getAddressTypes: () => authAxios.get(url + "GetAddressTypes"),
    getContactTypes: () => authAxios.get(url + "GetContactTypes"),
    getCountries: (lang) => authAxios.get(url + "GetCountries/" + lang),
    getCities: (id, lang) =>
      authAxios.get(url + "GetCities/" + id + "/" + lang),
    getMaritalStatus: () => authAxios.get(url + "GetMaritalStatus"),
    getOccupations: () => authAxios.get(url + "GetOccupations"),
    getSkills: () => authAxios.get(url + "GetSkills"),

    //From Setup Places Tables
    GetCountries: () => axios.get(url + "GetCountries/"),
    GetStates: (countryId) => axios.get(url + "GetStates/" + countryId),
    GetCities: (stateId) => axios.get(url + "GetCities/" + stateId),

    //DOCUMENT TAGS
    AddDocumentTag: (record) => authAxios.post(url + "AddDocumentTag/", record),
    GetDocumentTags: () => authAxios.get(url + "GetDocumentTags/"),
    DeleteDocumentTag: (id) =>
      authAxios.delete(url + "DeleteDocumentTag/" + id),
  };
}
