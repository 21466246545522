import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { convertToDateString, LoadingSpinner } from "../../constants/const";
import { ApiUnitMember } from "../unit/ApiUnit";
import Draggable from "react-draggable";

const EndMoveMemberResponsibilityDate = ({
  setEndMoveMemberResponsibilityDateModal,
  unitMembers,
  memberResponsibilities,
  setMemberResponsibilities,
  values,
  check,
  setMoveMemberModal,
}) => {
  const toggle = () => {
    setEndMoveMemberResponsibilityDateModal(false);
  };
console.log(memberResponsibilities);

  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.endDate =
      memberResponsibilities.findIndex(
        (obj) => obj.delete === true && obj.responsibilityEndDate === null
      ) !== -1 ? (
        memberResponsibilities[
          memberResponsibilities.findIndex(
            (obj) => obj.delete === true && obj.responsibilityEndDate === null
          )
        ].responsibilityEndDate !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_responsibility_end_date" />
        )
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeResponsibilityEndDate = (value, index) => {
    if (value !== null) {
      if (value <= new Date()) {
        let tempValues = [...memberResponsibilities];
        if (new Date(tempValues[index].responsibilityDate) >= value) {
          setErrors({
            ...errors,
            endDate:
              GetSelectedLanguage() === "en"
                ? "End Date must be Greater than Create Responsibility Date."
                : "ذمہ داری ک ختم ہونے ک تاریخ ذمہ داری ملنے کی  تاریخ سے بڑی ہونی چاہیے",
          });
          tempValues[index].responsibilityEndDate = null;
        } else {
          tempValues[index].responsibilityEndDate = value;
          setMemberResponsibilities(tempValues);
          setErrors({
            ...errors,
            endDate: "",
          });
        }
      } else {
        setErrors({
          ...errors,
          endDate:
            GetSelectedLanguage() === "en"
              ? "The End date must not be greater than current date."
              : "ذمہ داری ک ختم ہونے ک تاریخ موجودہ تاریخ سے بڑی نہیں ہو سکتی",
        });
      }
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (validate()) {
      let unitMembersList = [];
      if (values !== null) {
        unitMembers.newUnitId = values.unit.value;
        const updatedData = memberResponsibilities.map((item) => {
          if (item.responsibilityEndDate) {
            // Assign a value to responsibilityEndDate if it's null
            return {
              ...item,
              responsibilityEndDate: convertToDateString(item.responsibilityEndDate)
            };
          }
          return item; // If responsibilityEndDate is not null, return as is
        });
    console.log(updatedData);
    
        unitMembers.memberResponsibiities = updatedData;
        unitMembersList.push(unitMembers);
      } else {
        unitMembersList = unitMembers.map((unitMember) => {
          const correspondingResponsibilities = memberResponsibilities.filter(
            (resp) => resp.memberId === unitMember.memberId
          );

          if (correspondingResponsibilities.length > 0) {
            return {
              ...unitMember,
              memberResponsibiities: correspondingResponsibilities,
            };
          } else {
            return unitMember;
          }
        });
      }

      setButtonDisable(true);
      setLoading(true);
      ApiUnitMember()
        .moveUnitMembers({
          unitMemberModels: unitMembersList,
          permission: check,
        })
        .then((res) => {
          if (
            res.data.isRequest &&
            res.data.message === "request_exists_for_transfer_rafiq"
          ) {
            swal({
              title: t("request_exists_for_transfer_rafiq"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "error",
              buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
            });
            setLoading(false);
            setButtonDisable(false);
            toggle();
            setMoveMemberModal(false);
          } else if (res.data.includes("Move")) {
            notify(res.data);
            setLoading(false);
            setButtonDisable(false);
            setMoveMemberModal(false);
            toggle();
          } else if (res.data === "") {
            swal({
              title: t("unit_unitmember_move_rufaqa_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
            });
            setLoading(false);
            toggle();
            setMoveMemberModal(false);
          }
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_responsibility_end_date_title" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit1}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  {memberResponsibilities?.map((record, index) => {
                    return record.delete ? (
                      <>
                        <Row>
                          <FormGroup>
                            <Label className="col-form-label">
                              {GetSelectedLanguage() === "en" ? (
                                <h5>
                                  End Date for{" "}
                                  <span className="ur" style={{ fontSize: 17 }}>
                                    {record.responsibilityName}
                                  </span>{" "}
                                </h5>
                              ) : (
                                <h5 className="ur no-drag">
                                  <span
                                    style={{ fontWeight: "bold", fontSize: 20 }}
                                  >
                                    {" "}
                                    {record.responsibilityName}
                                  </span>{" "}
                                  کے ختم ہونے کی تاریخ{" "}
                                </h5>
                              )}
                            </Label>
                            <DatePicker
                              showYearDropdown
                              placeholderText="dd/mm/yyyy"
                              className="form-control no-drag"
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              selected={record.responsibilityEndDate}
                              onChange={(value) =>
                                handleInputChangeResponsibilityEndDate(
                                  value,
                                  index
                                )
                              }
                            />
                          </FormGroup>
                        </Row>
                      </>
                    ) : null;
                  })}
                  {errors?.endDate && (
                    <h4 className="text-error">{errors?.endDate}</h4>
                  )}
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default EndMoveMemberResponsibilityDate;
