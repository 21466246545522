import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Button, Table, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { ApiCourse } from "../../courses/ApiCourse";
import SearchCourseAttendees from "./SearchCourseAttendees";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { saveAs } from "file-saver";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const CourseAttendees = () => {
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [courseattendees, setCourseAttendees] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const loggedInUnitId = encryptStorage.getItem("loggedInUnitId");
  const [modal, setModal] = useState(false);
  const [searchCourseModel, setSearchCourseModel] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    startDate: null,
    endDate: null,
    isSubUnits: true,
    courseTypeIds: [],
    loggedInUnitId: loggedInUnitId,
    memberName: null
  });

  const customTableStyles = {
    border: "1px solid black",
  };

  if (searchCourseModel.unit.value === 0) {
    ApiUnit()
      .getUnit(0, 0)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setSearchCourseModel({
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setLoading(true);
    if (searchButton) {
      ApiCourse()
        .GetCourseParticipantsReport(searchCourseModel)
        .then((res) => {
          setCourseAttendees(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchCourseModel, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    ApiCourse()
      .GetCourseParticipantsExcelReport(searchCourseModel)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "CourseAttendees.xlsx");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
            <Button
              color="default"
              size="sm"
              onClick={handleReportTitle}
              className={GetCustomStyles().err_btn_style}
            >
              <FcPrint size={15} className={GetCustomStyles().fa_style} />
              {<GetLanguageString props="common_print" />}
            </Button>
          </div>
          <div className="ibox-content">
            <SearchCourseAttendees
              unitId={searchCourseModel.unit.value}
              searchCourseModel={searchCourseModel}
              setSearchCourseModel={setSearchCourseModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox" id="section-to-print">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="quick_reports_button6" />}</h5>
              {courseattendees.length !== 0 ? (
                <Button
                  color="default"
                  size="sm"
                  onClick={excelDownload}
                  className={GetCustomStyles().btn_style}
                >
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
              ) : null}
            </div>
            <div className="ibox-content">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                courseattendees.length !== 0 ? (
                  <div>
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th style={customTableStyles}>#</th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_reg_number" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_first_name" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="book_update_member_type" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="quick_reports3_unit_name" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_member_detail_course_name" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="courses_start_date" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="courses_end_date" />
                          </th>
                          <th style={customTableStyles}>
                            {<GetLanguageString props="courses_address" />}
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_member_detail_course_comments" />
                          </th>
                          <th style={customTableStyles}>
                            <GetLanguageString props="member_member_detail_course_status" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseattendees.map((record, index) => {
                          return record.courses.map((item, index1) => {
                            let unitName = record.memberUnitName?.split("/");
                            return (
                              <tr key={index1}>
                                {index1 === 0 ? (
                                  <>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                    >
                                      {index + 1}
                                    </td>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                    >
                                      {record.reg_no}
                                    </td>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                      className="ur"
                                    >
                                      {record.name}
                                    </td>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                      className="ur"
                                    >
                                      <tr>{record.fatherName}</tr>
                                    </td>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                    >
                                      {
                                        <Button
                                          size="xs"
                                          color="info"
                                          className="label label-info m-r-xs"
                                        >
                                          <h5 className="ur">
                                            {record.memberType}
                                          </h5>
                                        </Button>
                                      }
                                    </td>
                                    <td
                                      style={customTableStyles}
                                      rowSpan={record.courses.length}
                                      className="ur"
                                    >
                                      <ol className="breadcrumb">
                                        {unitName?.map((item, index) => {
                                          return (
                                            <li
                                              className={
                                                GetSelectedLanguage() === "ur"
                                                  ? "breadcrumb-item-ur"
                                                  : "breadcrumb-item"
                                              }
                                              key={index}
                                            >
                                              <span>{item}</span>
                                            </li>
                                          );
                                        })}
                                      </ol>
                                    </td>
                                  </>
                                ) : null}
                                <td
                                  style={{
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                  className="ur"
                                >
                                  {item.courseName}
                                </td>
                                <td
                                  style={{
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {item.startDate
                                    ? moment(
                                        convertUTCToLocalTime(item.startDate)
                                      ).format("DD/MM/yyyy")
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {item.endDate
                                    ? moment(
                                        convertUTCToLocalTime(item.endDate)
                                      ).format("DD/MM/yyyy")
                                    : "-"}
                                </td>{" "}
                                <td
                                  style={{
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                  className="ur"
                                >
                                  {item.courseLocation}
                                </td>
                                <td
                                  style={{
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                  className="ur"
                                >
                                  {item.comments}
                                </td>
                                <td
                                  style={{
                                    color: item.status ? "green" : "red",
                                    fontWeight: "bold",
                                    borderBottom:
                                      index1 + 1 === record.courses.length
                                        ? "1px solid black"
                                        : "",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {item.status ? (
                                    <GetLanguageString props="courses_status_completed" />
                                  ) : (
                                    <GetLanguageString props="courses_status_incompleted" />
                                  )}
                                </td>
                              </tr>
                            );
                          });
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(CourseAttendees);
