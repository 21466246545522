import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  CheckPermission,
  convertToDateString,
  DateOfBirthValidater,
  JoiningDateValidater,
  LoadingSpinner,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiNotifications } from "../notification/ApiNotifications";
import TableView from "../shared/SimilarRecordTable";
import useForm from "../shared/useForm";
import { addMember } from "./ApiMember";
import SelectMemberUnit from "./SelectMemberUnit";
import SelectSerialNumber from "./SelectSerialNumber";

const AddMemberModal = ({
  history,
  match,
  setSelectSerialNumberModal,
  unit,
  fromMemberCheck,
}) => {
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const [selectedJoiningDate, setSelectedJoiningDate] = useState(null);
  const notify = (string) => toast.success(string);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [buttonDisable, setButtonDisable] = useState(false);
  const [errorFiles, setErrorFiles] = useState({
    baitFormFront: "",
    baitFormBack: "",
    kawaifForm: "",
  });
  const memberValues = {
    unit: {
      value: loggedInUnitId === 0 ? 1 : loggedInUnitId,
      label: "",
      unitTypeName: loggedInUnitId === 0 ? "Markaz" : "",
    },
    serialNumber: 0,
    birthDate: null,
    cnic: "",
    fatherName: "",
    fullName: "",
    joiningDate: null,
    referenceNote: "",
    docs: "",
    proceedRequest: false,
  };
  const { t } = useTranslation();
  const toggle = () => setSelectSerialNumberModal(false);
  const [loading, setLoading] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(fromMemberCheck);

  const [exactMatchRecords, setExactMatchRecords] = useState(null);
  const [similarMatchRecords, setSimilarMatchRecords] = useState(null);
  const [cnicMatchRecords, setCnicMatchRecords] = useState(null);

  const [showSimilarRecords, setShowSimilarRecords] = useState(false);
  const [similarRecords, setSimilarRecords] = useState({
    cnic: "",
    fullName: "",
    fatherName: "",
  });
  const [valuesFiles, setValuesFiles] = useState({
    baitFormFront: "",
    baitFormBack: "",
    kawaifForm: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const { errors, setErrors, values, setValues } = useForm(memberValues);
  const [serialNumber, setSerialNumber] = useState();
  const validate = () => {
    let temp = {};
    temp.fullName =
      values.fullName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_first_name_error_message" />
      );
    temp.fatherName =
      values.fatherName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_father_name_error_message" />
      );
    temp.cnic =
      values.cnic !== "" ? (
        values.cnic.length === 13 ? (
          ""
        ) : (
          "CNIC is not valid. It must be 13 numbers."
        )
      ) : (
        <GetLanguageString props="member_cnic_error_message" />
      );
    temp.birthDate =
      selectedDateOfBirth !== null ? (
        DateOfBirthValidater(selectedDateOfBirth) ? (
          ""
        ) : (
          <GetLanguageString props="member_birth_date_less_error_message" />
        )
      ) : (
        <GetLanguageString props="member_birth_date_error_message" />
      );
    temp.joiningDate =
      selectedJoiningDate !== null ? (
        JoiningDateValidater(selectedJoiningDate) ? (
          ""
        ) : (
          <GetLanguageString props="member_joining_date_range_error_message" />
        )
      ) : (
        <GetLanguageString props="member_joining_date_error_message" />
      );

    temp.unitError =
      values.unit.unitTypeName !== "Markaz" &&
      values.unit.unitTypeName !== "Zone" &&
      values.unit.unitTypeName !== "ZoneOS" ? (
        ""
      ) : (
        <GetLanguageString props="member_unit_check_error" />
      );

    if (CheckPermission("CanRequestAddMember")) {
      temp.baitFormFront =
        valuesFiles.baitFormFront === null ||
        valuesFiles.baitFormFront === "" ? (
          <GetLanguageString props="member_add_file_not_uploaded" />
        ) : (
          ""
        );

      temp.baitFormBack =
        valuesFiles.baitFormBack === null || valuesFiles.baitFormBack === "" ? (
          <GetLanguageString props="member_add_file_not_uploaded" />
        ) : (
          ""
        );
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size < 10 * 1024 * 1024) {
      const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      let compressedFile;
      if (!selectedFile.type.includes("pdf")) {
        compressedFile = await imageCompression(selectedFile, options);
      }

      if (e.target.name === "baitFormFront") {
        setValuesFiles({
          ...valuesFiles,
          baitFormFront: compressedFile,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormFront: "",
        });
      } else if (e.target.name === "baitFormBack") {
        setValuesFiles({
          ...valuesFiles,
          baitFormBack: compressedFile,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormBack: "",
        });
      } else if (e.target.name === "kawaifForm") {
        setValuesFiles({
          ...valuesFiles,
          kawaifForm:
            compressedFile !== undefined ? compressedFile : selectedFile,
        });
      }
    } else {
      if (e.target.name === "baitFormFront") {
        setValuesFiles({
          ...valuesFiles,
          baitFormFront: null,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormFront:
            selectedFile.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      } else if (e.target.name === "baitFormBack") {
        setValuesFiles({
          ...valuesFiles,
          baitFormBack: null,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormBack:
            selectedFile.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : valuesFiles.baitFormBack === null ||
              valuesFiles.baitFormBack === "" ? (
              "hello"
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      } else if (e.target.name === "kawaifForm") {
        setValuesFiles({
          ...valuesFiles,
          kawaifForm: null,
        });
        setErrorFiles({
          ...errorFiles,
          kawaifForm:
            selectedFile?.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : valuesFiles.kawaifForm === null ||
              valuesFiles.kawaifForm === "" ? (
              "hello"
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      }
    }
  };

  useEffect(() => {
    if (!Object.values(similarRecords).every((x) => x === "")) {
      const timeoutId = setTimeout(() => {
        setShowSimilarRecords(true);
        setLoadingRecords(true);
        addMember()
          .GetMemberSimilarRecords(similarRecords)
          .then((res) => {
            setCnicMatchRecords(res.data.cnicRecords);
            setExactMatchRecords(res.data.exactMatchRecords);
            setSimilarMatchRecords(res.data.similarRecords);
            setLoadingRecords(false);
            if (
              Array.isArray(res.data.exactMatchRecords) &&
              res.data.exactMatchRecords.length === 0 &&
              Array.isArray(res.data.similarRecords) &&
              res.data.similarRecords.length === 0 &&
              (res.data.cnicRecords === null ||
                (Array.isArray(res.data.cnicRecords) &&
                  res.data.cnicRecords.length === 0))
            ) {
              setShowSimilarRecords(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoadingRecords(false);
          });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [similarRecords]);

  const handleInputChangeCnic = (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      cnic: value.replace(/-/g, ""),
    });

    if (!CheckPermission("CanRequestAddMember")) {
      setSimilarRecords({
        ...similarRecords,
        cnic: value.replace(/-/g, ""),
      });
    }

    setErrors({
      ...errors,
      cnic: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (!CheckPermission("CanRequestAddMember")) {
      if (name === "fullName" || name === "fatherName") {
        setSimilarRecords({
          ...similarRecords,
          [name]: value,
        });
      }
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleInputChangeJoiningDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedJoiningDate(value);
        setErrors({
          ...errors,
          joiningDate: "",
        });
      } else {
        setErrors({
          ...errors,
          joiningDate:
            "The Joining date must not be greater than current date.",
        });
      }
    } else {
      setSelectedJoiningDate(null);
    }
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDateOfBirth(value);
        setErrors({
          ...errors,
          birthDate: "",
        });
      } else {
        setErrors({
          ...errors,
          birthDate: "The Birth date must not be greater than current date.",
        });
      }
    } else {
      setValues({
        ...values,
        birthDate: null,
      });
      setSelectedDateOfBirth(null);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const files = new FormData();
    files.append("BaitFormFront", valuesFiles.baitFormFront);
    files.append("BaitFormBack", valuesFiles.baitFormBack);
    files.append("KawaifForm", valuesFiles.kawaifForm);

    const performSubmit = () => {
      // Function declaration to call handleSubmit again
      if (
        validate() &&
        Object.values(errorFiles).every((value) => value === "")
      ) {
        setButtonDisable(true);
        setLoading(true);
        values.birthDate = convertToDateString(selectedDateOfBirth);
        values.joiningDate = convertToDateString(selectedJoiningDate);
        if (CheckPermission("CanRequestAddMember")) {
          ApiNotifications()
            .NotificationDocsSave(files)
            .then((res) => {
              values.docs = res.data;
              values.serialNumber = serialNumber;
              addMember()
                .setMember(values)
                .then((res) => {
                  if (
                    res.data.isRequest &&
                    res.data.message === "request_exists_for_rafiq"
                  ) {
                    swal({
                      title: t("request_exists_for_rafiq"),
                      className: GetSelectedLanguage() === "en" ? "" : "ur",
                      icon: "error",
                      buttons:
                        GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
                    });
                    setLoading(false);
                    setButtonDisable(false);
                  } else if (
                    res.data.isRequest &&
                    res.data.proceedRequest &&
                    res.data.message ===
                      "request_exists_for_rafiq_proceed_or_not"
                  ) {
                    setLoading(false);
                    setButtonDisable(false);
                    swal({
                      title: t("request_exists_for_rafiq_proceed_or_not"),
                      className: GetSelectedLanguage() === "en" ? "" : "ur",
                      icon: "error",
                      buttons: {
                        cancel: true,
                        confirm: t("request_proceed_anyway"),
                      },
                    }).then((proceed) => {
                      if (proceed) {
                        values.proceedRequest = true;
                        performSubmit(); // Call handleSubmit again
                      } else {
                        setLoading(false);
                        setButtonDisable(false);
                        toggle();
                      }
                    });
                  } else if (
                    res.data.isRequest &&
                    res.data.message === "Request Sent"
                  ) {
                    notify(t("request_to_add_rafiq"));
                    setLoading(false);
                    toggle();
                  }
                })
                .catch((err) => {
                  const messageMatch =
                    err.response.data.match(/Message\s*=\s*(.*?),/);

                  // Extract the message if it's found, otherwise use a fallback message
                  const errorMessage = messageMatch
                    ? messageMatch[1]
                    : setLoading(false);
                  swal({
                    title: errorMessage,
                    icon: "error",
                    buttons: "OK",
                  });
                  toggle();
                  console.log(errorMessage);
                });
            })
            .catch((err) => {
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              toggle();
              console.log(err);
            });
        } else {
          values.serialNumber = serialNumber;
          addMember()
            .setMember(values)
            .then((res) => {
              if (!res.data.isRequest) {
                setLoading(false);
                swal({
                  title: t("member_added_success_message"),
                  className: GetSelectedLanguage() === "en" ? "" : "ur",
                  icon: "success",
                  buttons: {
                    cancel: "Go Back",
                    confirm: t("member_Add_detailed_data"),
                  },
                }).then(
                  (response) =>
                    response &&
                    history.push({
                      pathname: `${match.url}/updateMember/${res.data.value}`,
                      state: {
                        unitId: unit.unitId,
                        unitName: unit.unitName,
                        canModifyType: true,
                        addNewRafiq: true,
                      },
                    })
                );
                toggle();
              }
            })
            .catch((err) => {
              setLoading(false);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              toggle();
              console.log(err);
            });
        }
      }
    };

    // Call the performSubmit function
    performSubmit();
  };

  return (
    <div>
      {!addMemberModal ? (
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
          <Modal isOpen={true} className="inmodal">
            <ModalHeader className="modal-title" tag="h4">
              {<GetLanguageString props="member_select_serial_number" />}
            </ModalHeader>
            <ModalBody>
              <SelectSerialNumber
                setSerialNumber={setSerialNumber}
                loading={loading}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setAddMemberModal(true);
                }}
              >
                {<GetLanguageString props="Next" />}
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
      ) : (
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
          <Modal size="xl" isOpen={true} className="inmodal" autoFocus={false}>
            <ModalHeader className="modal-title" tag="h4">
              {<GetLanguageString props="member_add_member" />}
            </ModalHeader>
            <ModalBody>
              {!loading ? (
                <div className="ibox ">
                  <div className="ibox-content">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_first_name" />}
                          </Label>
                          <Input
                            className="ur no-drag"
                            autoFocus
                            type="text"
                            name="fullName"
                            value={values.fullName}
                            onChange={handleInputChange}
                            {...(errors.fullName && { invalid: true })}
                          />
                          {errors.fullName && (
                            <FormFeedback>
                              <h4>{errors.fullName}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_father_name" />}
                          </Label>
                          <Input
                            className="ur no-drag"
                            type="text"
                            name="fatherName"
                            value={values.fatherName}
                            onChange={handleInputChange}
                            {...(errors.fatherName && { invalid: true })}
                          />
                          {errors.fatherName && (
                            <FormFeedback>
                              <h4>{errors.fatherName}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_joining_date" />}
                          </Label>
                          <DatePicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mm/yyyy"
                            className="form-control no-drag"
                            selected={selectedJoiningDate}
                            maxDate={new Date()}
                            onChange={handleInputChangeJoiningDate}
                            dateFormat="dd/MM/yyyy"
                          />
                          {errors.joiningDate && (
                            <h4 className="text-error">{errors.joiningDate}</h4>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_cnic" />}
                          </Label>
                          <InputMask
                            mask="99999-9999999-9"
                            value={values.cnic}
                            onChange={handleInputChangeCnic}
                            name="cnic"
                            maskChar=""
                          >
                            {(inputProps) => (
                              <Input
                                {...inputProps}
                                type="text"
                                className="no-drag"
                                placeholder="______-_______-_"
                                {...(errors.cnic && { invalid: true })}
                              />
                            )}
                          </InputMask>
                          {errors.cnic && (
                            <FormFeedback>
                              <h4>{errors.cnic}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_date_of_birth" />}
                          </Label>
                          <DatePicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mm/yyyy"
                            className="form-control no-drag"
                            selected={selectedDateOfBirth}
                            maxDate={new Date()}
                            onChange={handleInputChangeBirthDate}
                            dateFormat="dd/MM/yyyy"
                          />
                          {errors.birthDate && (
                            <h4 className="text-error">{errors.birthDate}</h4>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_reference_note" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="referenceNote"
                            className="no-drag"
                            value={values.referenceNote}
                            onChange={handleInputChange}
                            {...(errors.referenceNote && { invalid: true })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {CheckPermission("CanRequestAddMember") ? (
                      <>
                        <Row>
                          <Col md="6">
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_add_bait_form_front" />
                              }
                            </Label>
                            <Input
                              type="file"
                              accept="image/*"
                              name="baitFormFront"
                              maxSize={10 * 1024 * 1024}
                              onChange={handleUpload}
                            />
                            {errorFiles && (
                              <div className="text-error">
                                {errorFiles.baitFormFront}
                              </div>
                            )}
                            {errors.baitFormFront && (
                              <h4 className="text-error">
                                {errors.baitFormFront}
                              </h4>
                            )}
                          </Col>
                          <Col md="6">
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_add_bait_form_back" />
                              }
                            </Label>
                            <Input
                              type="file"
                              accept="image/*"
                              name="baitFormBack"
                              maxSize={10 * 1024 * 1024}
                              onChange={handleUpload}
                            />
                            {errorFiles && (
                              <div className="text-error">
                                {errorFiles.baitFormBack}
                              </div>
                            )}
                            {errors.baitFormBack && (
                              <h4 className="text-error">
                                {errors.baitFormBack}
                              </h4>
                            )}
                          </Col>
                          <Col md="6">
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_add_kawaif_form" />
                              }
                            </Label>
                            <Input
                              type="file"
                              accept="image/*,application/pdf"
                              name="kawaifForm"
                              maxSize={10 * 1024 * 1024}
                              onChange={handleUpload}
                            />

                            {errorFiles && (
                              <div className="text-error">
                                {errorFiles.kawaifForm}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <br></br>
                      </>
                    ) : null}
                    <Row>
                      <SelectMemberUnit
                        values={values}
                        setValues={setValues}
                        unitId={values.unit.value}
                        showExcludeUnit={false}
                      />
                      {errors.unitError && (
                        <h4 className="text-error">{errors.unitError}</h4>
                      )}
                    </Row>
                  </div>
                  <br></br>
                  {!CheckPermission("CanRequestAddMember") ? (
                    showSimilarRecords ? (
                      !loadingRecords ? (
                        <>
                          <div className="ibox">
                            <div className="ibox-title">
                              <h5>
                                {
                                  <GetLanguageString props="notifications_see_changes_similar_records_exact" />
                                }
                              </h5>
                            </div>
                            <div
                              className="ibox-content no-drag"
                              style={{
                                maxHeight: 300,
                                overflowY: "auto",
                              }}
                            >
                              <div>
                                {exactMatchRecords?.length !== 0 ? (
                                  <TableView data={exactMatchRecords} />
                                ) : (
                                  <NoResult />
                                )}
                              </div>
                            </div>
                          </div>

                          <br></br>
                          <div className="ibox">
                            <div className="ibox-title">
                              <h5>
                                {
                                  <GetLanguageString props="notifications_see_changes_similar_records_similar" />
                                }
                              </h5>
                            </div>
                            <div
                              className="ibox-content no-drag"
                              style={{
                                maxHeight: 300,
                                overflowY: "auto",
                              }}
                            >
                              <div>
                                {similarMatchRecords?.length !== 0 ? (
                                  <TableView data={similarMatchRecords} />
                                ) : (
                                  <NoResult />
                                )}
                              </div>
                            </div>
                          </div>
                          <br></br>
                          <div className="ibox">
                            <div className="ibox-title">
                              <h5>
                                {
                                  <GetLanguageString props="notifications_see_changes_similar_records_cnic" />
                                }
                              </h5>
                            </div>
                            <div
                              className="ibox-content no-drag"
                              style={{
                                maxHeight: 300,
                                overflowY: "auto",
                              }}
                            >
                              <div>
                                {cnicMatchRecords?.length !== 0 &&
                                cnicMatchRecords !== null ? (
                                  <TableView data={cnicMatchRecords} />
                                ) : (
                                  <NoResult />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        LoadingSpinner()
                      )
                    ) : null
                  ) : null}
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={buttonDisable}
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
      )}
    </div>
  );
};

export default withRouter(AddMemberModal);
