import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import {
  applyClassToCharacters,
  isUrdu,
  LoadingSpinner,
} from "../../constants/const";

const SearchAutoComplete = ({
  members,
  setMembers,
  setName,
  name,
  setSelectedName,
  handleOnSelect,
  setSearchType,
  calledFrom = "",
  loading,
  type = "",
  placeholder,
  focus,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  // Create a useRef hook to store the list container element
  const listContainerRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setFocusedIndex((prevIndex) =>
        prevIndex < members.length - 1 ? prevIndex + 1 : prevIndex
      );
      // Scroll the list if the focused item is outside the viewport
      scrollListIfNeeded();
    } else if (e.key === "ArrowUp") {
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : members.length - 1
      );
      scrollListIfNeeded();
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      if (calledFrom === "course") handleOnSelect(members[focusedIndex], type);
      else handleOnSelect(members[focusedIndex]);
    } else if (e.key === "Escape") {
      handleClear();
    }
  };
  // Function to scroll the list container if needed
  const scrollListIfNeeded = () => {
    if (!listContainerRef.current) return;

    const listContainer = listContainerRef.current;
    const listItemHeight =
      listContainer.querySelector("li")?.offsetHeight || 30; // Default height if no list items found
    const visibleItems = Math.floor(
      listContainer.clientHeight / listItemHeight
    );
    const scrollTop = listContainer.scrollTop;

    // Check if the focused item is outside the visible area
    if (
      focusedIndex >= visibleItems &&
      focusedIndex > scrollTop / listItemHeight
    ) {
      listContainer.scrollTo({
        top: (focusedIndex + 1) * listItemHeight - listContainer.clientHeight,
        behavior: "smooth",
      });
    } else if (
      focusedIndex < visibleItems &&
      focusedIndex < scrollTop / listItemHeight
    ) {
      listContainer.scrollTo({
        top: focusedIndex * listItemHeight,
        behavior: "smooth",
      });
    }
  };
  const handleMouseEnter = (index) => {
    setFocusedIndex(index);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [focusedIndex, members]);

  const handleInputChange = (event, type) => {
    const searchString = event.target.value;
    if (calledFrom === "course") handleOnSearch(searchString, type);
    else handleOnSearch(searchString);
  };
  const handleOnSearch = (string, type) => {
    if (calledFrom === "course") setSearchType(type);
    setName(string);
  };

  const handleClear = () => {
    setName("");
    if (calledFrom === "course") setSelectedName("select");
    setMembers([]);
  };

  const formatResult = (item) => {
    var str = item.name;
    let remove = str.split(" - ").pop();
    let reqiuredName = str.replace(" - " + remove, "   ");
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          <b>{applyClassToCharacters(reqiuredName, "ur")}</b> -{" "}
          {applyClassToCharacters(remove.replace("/ ", "  "), "ur")}
        </span>
      </>
    );
  };

  return (
    <Row>
      <Col md="12">
        <div>
          <InputGroup>
            <InputGroupText>
              {" "}
              <BsSearch size={18} />
            </InputGroupText>
            <Input
              type="text"
              autoFocus={type === "rufqa" || type === "rafiq"}
              className={isUrdu(name) ? "ur no-drag" : "no-drag"}
              value={name}
              onChange={(e) => handleInputChange(e, type)}
              placeholder={placeholder}
            />
            <InputGroupText onClick={handleClear}>
              {" "}
              <RxCross2 size={18} />
            </InputGroupText>
          </InputGroup>
          {!loading ? (
            members.length !== 0 ? (
              <div
                ref={listContainerRef}
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                }}
              >
                <ListGroup>
                  {members.map((member, index) => (
                    <ListGroupItem
                      key={member.id}
                      action
                      onMouseEnter={() => handleMouseEnter(index)}
                      onClick={() => handleOnSelect(member, type)}
                      style={{
                        backgroundColor:
                          index === focusedIndex ? "#bbeded" : "transparent",
                      }}
                    >
                      {formatResult(member)}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            ) : null
          ) : (
            LoadingSpinner()
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SearchAutoComplete;
