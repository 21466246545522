export default function getUrl() {
  let url = "";
  if (process.env.NODE_ENV === "development") {
    //url = "https://localhost:50708/"; //DEBUG
    url = "http://localhost/oms/";
  } else if (process.env.NODE_ENV === "production") {
    //url = "https://api.dhtr.pk/";
    url = "https://devapi.dhtr.pk/";
  }
  return url;
}
 