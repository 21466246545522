import axios from "axios";
import jwtDecode from "jwt-decode";
import Logout from "../components/logout/Logout";
import { encryptStorage } from "./EncryptStorage";
import getUrl from "./urlConst";
import { components } from "react-select";

export const BASEURL = getUrl();

export function RefreshToken() {
  const values = {
    token: encryptStorage.getItem("token"),
    refreshToken: encryptStorage.getItem("refreshToken"),
  };

  const url = BASEURL + "api/account/refreshtoken";
  axios
    .post(url, values)
    .then((res) => {
      if (res.data.success) {
        encryptStorage.setItem("token", JSON.stringify(res.data.token));
        encryptStorage.setItem(
          "refreshToken",
          JSON.stringify(res.data.refreshToken)
        );
      } else {
        Logout();
      }
    })
    .catch((err) => console.log(err));
}

export function customOption(props) {
  return (
    <components.Option {...props}>
      <div>{applyClassToCharacters(props.children, "ur")}</div>
    </components.Option>
  );
}

export function customMultiValue(props) {
  return (
    <components.MultiValue {...props}>
      {applyClassToCharacters(props.children, "ur")}
    </components.MultiValue>
  );
}

export function LoadingSpinner() {
  return (
    <div className="spiner-example">
      <div className="sk-spinner sk-spinner-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </div>
  );
}

export function TransferSessionStorage() {
  if (!sessionStorage.length) {
    localStorage.setItem("getSessionStorage", Date.now());
  }

  window.addEventListener("storage", function (event) {
    if (event.key === "getSessionStorage") {
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
    } else if (event.key === "sessionStorage" && !sessionStorage.length) {
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }

      window.location.reload();
    }
  });
}
export function CheckPermission(permission) {
  const decodedToken = jwtDecode(encryptStorage.getItem("token"));
  const isSuperAdmin =
    decodedToken.role === "admin" && encryptStorage.getItem("memberId") === "0";
  const userClaims = encryptStorage.getItem("userClaims");

  // Handle special case for super admin
  if (isSuperAdmin && permission === "CanDelegatePermissions") {
    return false;
  }

  if (decodedToken.role === "admin") return true;

  const permissionClaim = userClaims.find((claim) =>
    claim.type.includes(permission)
  );
  // If a matching claim is found, check its value
  if (permissionClaim) {
    // Handle multiple values (if applicable)
    if (Array.isArray(permissionClaim.value)) {
      return permissionClaim.value.includes("True");
    } else {
      return permissionClaim.value === "True";
    }
  }

  // If no matching claim is found, return false
  return false;
}
// export function CheckPermission(permission) {
//   const decodedToken = jwtDecode(encryptStorage.getItem("token"));
//   if (
//     decodedToken.role === "admin" &&
//     permission === "CanDelegatePermissions" &&
//     encryptStorage.getItem("memberId") === "0"
//   )
//     return false;
//   else if (decodedToken.role === "admin") return true;
//   else if (
//     Array.isArray(decodedToken[permission]) &&
//     decodedToken[permission].includes("True")
//   ) {
//     return true;
//   } else if (
//     Object.keys(decodedToken).find((key) => key.includes(permission))
//   ) {
//     return true;
//   } else return false;
// }

export function CheckIsAdmin() {
  const decodedToken = jwtDecode(encryptStorage.getItem("token"));
  if (decodedToken.role === "admin") return true;
  else return false;
}

export function isUrdu(text) {
  const urduRegex = /[\u0600-\u06FF]/; // Range of Unicode characters for Urdu

  return urduRegex.test(text);
}

export function applyClassToCharacters(inputString, className) {
  return inputString.split("").map((char, index) => (
    <span key={index} className={isUrdu(char) ? className : "en"}>
      {char}
    </span>
  ));
}

export function capitalizeFirstLetter(str) {
  if (!str) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Recive date of birth as input parameter and returns true if a person is atleast 12
export function DateOfBirthValidater(dob) {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 12); // make sure to that member is atleast 12 years old

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100); // make sure that member is not more then 100 years old

  const dateOfBirth = new Date(dob);
  return dateOfBirth <= maxDate && dateOfBirth >= minDate;
}

//  CHECK IF CODE IS Unique
export function isCodeUnique(arr, code) {
  let isNameUnique = true;
  arr.forEach((c) => {
    if (c.toLowerCase() === code.toLowerCase()) isNameUnique = false;
  });
  return isNameUnique;
}

//  CHECK IF CODE IS Unique
export function isCodeUniqueEdit(arr, prevCode, code) {
  let isNameUnique = true;
  arr.forEach((c) => {
    if (c !== prevCode)
      if (c.toLowerCase() === code.toLowerCase()) isNameUnique = false;
  });
  return isNameUnique;
}
//  CHECK IF SORT ORDER IS A NUMBER
export function isNumeric(value) {
  return !isNaN(value);
}
//  CHECK IF CODE IS ALPHA NUMERIC WITHOUT SPACE
export function isAlphaNumeric(val) {
  return /^[a-zA-Z0-9_]+$/.test(val);
}

// Recive joiening date as input and return true if joining date is between Today-1975
export function JoiningDateValidater(joiningDate) {
  const today = new Date();
  const joiningDateNew = new Date(joiningDate);
  const minDate = new Date(1975, 0, 1);

  return joiningDateNew <= today && joiningDateNew >= minDate;
}

// pledge date validate from pledge from date to current date
export function PledgeDateValidator(pledgeDate, pledgeFromDate) {
  const today = new Date();
  const minDate = new Date(pledgeFromDate);
  const pledgeDateResponse = new Date(pledgeDate);

  return pledgeDateResponse <= today && pledgeDateResponse >= minDate;
}
// Regular expression pattern to match non-alphanumeric characters
export const nonAlphanumericRegex = /[^a-zA-Z0-9]/;

export function convertUTCToLocalTime(utcTimeString) {
  var utcTime = new Date(Date.parse(utcTimeString));
  var timeZoneOffset = utcTime.getTimezoneOffset();
  var hours = Math.abs(Math.floor(timeZoneOffset / 60));

  return utcTime.setHours(utcTime.getHours() + hours);
}

export function convertToDateString(date) {
  // Create a Date object from the input date string
  if (date === null) return null;

  const dateObject = new Date(date);

  // Get the local year, month, and day
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
  const day = String(dateObject.getDate()).padStart(2, "0");

  // Combine to get the desired format 'yyyy-mm-dd'
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const removeDuplicates = (array) => {
  return array.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))
  );
};

export const isChildInHierarchy = (child, parent) => {
  const hierarchy = {
    Markaz: ["Zone", "Halqa", "Tanzeem", "Usra"],
    Zone: ["Halqa", "Tanzeem", "Usra"],
    Halqa: ["Tanzeem", "Usra"],
    Tanzeem: ["Usra"],
    Usra: [],
  };

  const directChildren = hierarchy[parent] || [];

  return directChildren.includes(child);
};
