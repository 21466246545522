import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import useSorting from "../shared/useSorting";
import { ApiUnit } from "../unit/ApiUnit";
import AddAhbabModel from "./AddAhbabModel";
import { ApiAhbab } from "./ApiAhbab";
import SelectAssociatedMember from "./ContactFormAhbab/SelectAssociatedMember";
import SearchAhbab from "./SearchAhbab";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  birthDate: "neutral",
  memberType: "neutral",
};

const Ahbab = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [ahbab, setAhbab] = useState([]);
  const [habibId, setHabibId] = useState();
  const [habibUnitId, setHabibUnitId] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [callAddAhbabModel, setCallAddAhbabModel] = useState(false);
  const [showAssociatedMemberModal, setShowAssociatedMemberModal] =
    useState(false);
  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
    unitTypeName: unitTypeName,
  });
  const [searchButton, setSearchButton] = useState(false);
  const [searchAhbabModel, setSearchAhbabModel] = useState({
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    activePage: 1,
    isSubUnits: true,
    sortModel: {},
  });
  const { t } = useTranslation();
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;
  const { sortObject, handleSorting } = useSorting(initialValues);

  const toggleDropdownOpen = (index) => {
    let temp = [...ahbab];
    temp[index].dropDown = !ahbab[index].dropDown;
    setAhbab(temp);
  };

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          setSearchAhbabModel({
            unitId: temp.id,
            activePage: 1,
          });
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }
  }, [unit.unitId, loggedInUnitId]);

  const cancelHabib = (id) => {
    swal({
      title: t("common_delete_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      text: t("common_delete_detail_message"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiAhbab()
          .CancelHabib(id)
          .then((res) => {
            swal({
              title: t("ahbab_delete_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setRefreshList(!refreshList);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isFullNameDescending: sortObject.name === "descending",
      isFullNameAscending: sortObject.name === "ascending",
      isBirthDateDescending: sortObject.birthDate === "descending",
      isBirthDateAscending: sortObject.birthDate === "ascending",
      isJoiningDateDescending: sortObject.joiningDate === "descending",
      isJoiningDateAscending: sortObject.joiningDate === "ascending",
      isMemberTypeAscending: sortObject.memberType === "ascending",
      isMemberTypeDescending: sortObject.memberType === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;
    if (searchButton) {
      ApiAhbab()
        .GetAhbab(searchAhbabModel)
        .then((res) => {
          setAhbab(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  }, [searchAhbabModel, sortObject, searchButton, refreshList]);

  const handlePageChange = (pageNumber) => {
    setSearchAhbabModel({
      ...searchAhbabModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  return (
    <div>
      {callAddAhbabModel && (
        <AddAhbabModel setCallAddAhbabModel={setCallAddAhbabModel} />
      )}
      {showAssociatedMemberModal && (
        <SelectAssociatedMember
          memberHabibId={habibId}
          habibUnitId={habibUnitId}
          setShowAssociatedMemberModal={setShowAssociatedMemberModal}
          setRefreshList={setRefreshList}
          refreshList={refreshList}
        />
      )}
      <div className="wrapper wrapper-custom wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="ahbab_search" />}</h5>
            {CheckPermission("CanAddAhbab") ? (
              <Button
                color="primary"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => {
                  setCallAddAhbabModel(true);
                }}
              >
                {<GetLanguageString props="ahbab_add" />}
              </Button>
            ) : null}
          </div>
          <div className="ibox-content">
            <SearchAhbab
              unitId={unit.unitId}
              setUnit={setUnit}
              searchAhbabModel={searchAhbabModel}
              setSearchAhbabModel={setSearchAhbabModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox ">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="ahbab_details" />}</h5>
            </div>
            <div className="ibox-content">
              {!loading ? (
                ahbab.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("name")}
                          >
                            <GetLanguageString props="common_name" />
                            <GetSortingUI sortObject={sortObject} name="name" />
                          </th>
                          <th>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th>
                            {
                              <GetLanguageString props="ahbab_associated_member" />
                            }
                          </th>
                          <th>
                            {<GetLanguageString props="member_address" />}
                          </th>
                          <th>{<GetLanguageString props="member_phone" />}</th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="common_action" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ahbab?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td className="p-t-custom">
                                {index + recordIndex}
                              </td>
                              <td className="p-t-custom">{record.regNo}</td>
                              <td>
                                {record.fullName
                                  ? applyClassToCharacters(
                                      record.fullName,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>{" "}
                              <td>
                                {record.fatherName
                                  ? applyClassToCharacters(
                                      record.fatherName,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {record.associatedMember
                                  ? applyClassToCharacters(
                                      record.associatedMember,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>{" "}
                              <td>
                                {record.currentAddress
                                  ? applyClassToCharacters(
                                      record.currentAddress,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>
                              <td className="p-t-custom p-t-custom">
                                {record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))}
                              </td>
                              <td>
                                {
                                  <Button
                                    size="xs"
                                    color="info"
                                    className="label label-info m-r-xs"
                                  >
                                    <h5 className="ur">{record.unitName}</h5>
                                  </Button>
                                }
                              </td>
                              <td className="text-center">
                                <Dropdown
                                  isOpen={record.dropDown}
                                  toggle={() => {
                                    toggleDropdownOpen(index);
                                  }}
                                  style={{ position: "static" }}
                                >
                                  <DropdownToggle
                                    className="btn btn-default"
                                    tag="span"
                                    aria-expanded={record.dropDown}
                                  >
                                    {
                                      <GetLanguageString props="common_action" />
                                    }
                                    <b className="caret m-l-xs" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{
                                      minWidth: "0rem",
                                      fontSize: "13px",
                                    }}
                                  >
                                    <div>
                                      <DropdownItem
                                        onClick={() => {
                                          setHabibId(record.id);
                                          setHabibUnitId(record.unitId);
                                          setShowAssociatedMemberModal(true);
                                        }}
                                      >
                                        {record.isAssociatedWithMember ? (
                                          <GetLanguageString props="ahbab_update_associated_habib" />
                                        ) : (
                                          <GetLanguageString props="ahbab_associate_with_habib" />
                                        )}
                                      </DropdownItem>
                                    </div>
                                    <Link
                                      to={{
                                        pathname: `member/membersdetails/${record.id}`,
                                        state: {
                                          unitId: record.unitId,
                                          isEditable: record.isEditable,
                                          isDeletable: record.isDeletable,
                                          canModifyType: record.canModifyType,
                                          memberTypeId: record.memberTypeId,
                                        },
                                      }}
                                    >
                                      <DropdownItem>
                                        {
                                          <GetLanguageString props="member_detail_btn" />
                                        }
                                      </DropdownItem>
                                    </Link>
                                    {record.isEditable ? (
                                      <Link
                                        to={{
                                          pathname: `member/updateMember/${record.id}`,
                                          state: {
                                            unitId: loggedInUnitId,
                                            currentUnitId: record.unitId,
                                            unitName: "",
                                            canModifyType: record.canModifyType,
                                            memberTypeId: record.memberTypeId,
                                            addNewRafiq: false,
                                          },
                                        }}
                                      >
                                        <DropdownItem>
                                          {
                                            <GetLanguageString props="common_edit" />
                                          }
                                        </DropdownItem>{" "}
                                      </Link>
                                    ) : null}
                                    {record.isDeletable ? (
                                      <DropdownItem
                                        onClick={() => {
                                          cancelHabib(record.id);
                                        }}
                                      >
                                        {
                                          <GetLanguageString props="common_delete" />
                                        }
                                      </DropdownItem>
                                    ) : null}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="11">
                            <ul className={GetCustomStyles().page_style}>
                              <Pagination
                                activePage={pagination.activePage}
                                itemsCountPerPage={pagination.pageSize}
                                totalItemsCount={pagination.totalItem}
                                pageRangeDisplayed={pagination.pageRange}
                                onChange={handlePageChange}
                              />
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Ahbab);
