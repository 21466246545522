import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { applyClassToCharacters } from "../../constants/const";
import { setupApi } from "../member/ApiMember";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

const CreateableTags = ({ selectedTags, setSelectedTags }) => {
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setupApi()
      .GetDocumentTags()
      .then((res) => {
        setAllTags(res.data);
        const updatedTags = res.data.filter(
          (item1) => !selectedTags.some((item2) => item2.label === item1.label)
        );
        setTags(updatedTags);
        setSelectedTags((prevTags) =>
          prevTags.map((prevTag) => {
            const match = res.data.find(
              (newTag) => newTag.label === prevTag.label
            );
            return match ? { ...prevTag, value: match.value } : prevTag;
          })
        );
      })
      .catch((err) => {
        swal({ title: err, icon: "error", buttons: "OK" });
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (newValue) => {
    setSelectedTags(newValue || []);
    const updatedTags = allTags.filter(
      (item1) => !newValue.some((item2) => item2.label === item1.label)
    );
    setTags(updatedTags);
  };

  const handleCreate = (inputValue) => {
    const newTag = { label: inputValue, value: 0 };
    setSelectedTags((prev) => [...prev, newTag]);

    setupApi()
      .AddDocumentTag(newTag)
      .then((res) => {
        setAllTags(res.data);
        handleChange([...selectedTags, newTag]);
        setSelectedTags((prevTags) =>
          prevTags.map((prevTag) => {
            const match = res.data.find(
              (newTag) => newTag.label === prevTag.label
            );
            return match ? { ...prevTag, value: match.value } : prevTag;
          })
        );
      })
      .catch((err) => {
        swal({ title: err, icon: "error", buttons: "OK" });
      });
  };

  const handleRemoveTag = (tagValue) => {
    if (tagValue.isDeletable) {
      setTags(tags.filter((tag) => tag.value !== tagValue.value));
      setSelectedTags(
        selectedTags.filter((tag) => tag.value !== tagValue.value)
      );
      setupApi()
        .DeleteDocumentTag(tagValue.value)
        .then((res) => {
          setAllTags(res.data);
          setTags(
            res.data.filter(
              (item1) =>
                !selectedTags.some((item2) => item2.label === item1.label)
            )
          );
          setSelectedTags((prevTags) =>
            prevTags.map((prevTag) => {
              const match = res.data.find(
                (newTag) => newTag.label === prevTag.label
              );
              return match ? { ...prevTag, value: match.value } : prevTag;
            })
          );
        })
        .catch((err) => {
          swal({ title: err.response.data, icon: "error", buttons: "OK" });
        });
    } else {
      swal({
        title: t("tag_have_documents"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const CustomOption = (props) => {
    const { data } = props;
    const isExistingOption = tags.some((tag) => tag.value === data.value);

    return (
      <components.Option {...props}>
        {applyClassToCharacters(data.label, "ur")}
        {isExistingOption && (
          <RxCross2
            className={GetCustomStyles().unit_btn_style}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveTag(data);
            }}
          />
        )}
      </components.Option>
    );
  };

  const CustomMultiValue = (props) => (
    <components.MultiValue {...props}>
      {applyClassToCharacters(props.children, "ur")}
    </components.MultiValue>
  );

  return (
    <CreatableSelect
      id="tag-selector"
      isMulti
      options={tags}
      value={selectedTags}
      onChange={handleChange}
      onCreateOption={handleCreate}
      isClearable
      components={{
        Option: CustomOption,
        MultiValue: CustomMultiValue,
      }}
    />
  );
};

export default CreateableTags;
