import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { FaListUl } from "react-icons/fa";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { CheckPermission } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";

const QuickReports = ({ history }) => {
  return (
    <div className="wrapper wrapper-content wrapper-custom">
      <div className="ibox">
        <div className="ibox-content panel-body">
          <h3>{<GetLanguageString props="quick_reports_heading" />}</h3>
          <p>{<GetLanguageString props="quick_reports_message" />}</p>
          <Row>
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-danger dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/unitMember`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button1" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-warning dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/unitOfficials`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button5" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewFunds") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-primary dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/fundsmanagement/report/infaq`,
                    })
                  }
                >
                  <FaRegMoneyBill1 size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button2" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-success dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/unitMemberCount`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button3" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-info dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/memberStatusChange`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button4" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-danger dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/courseAttendees`,
                    })
                  }
                >
                  <FaListUl />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button6" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-warning dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/vacantResponsibilitiesReport`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button7" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMudaris") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-primary dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/mudariseen`,
                    })
                  }
                >
                  <FaListUl />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button8" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-success dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/attachedDocumentsReport`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button9" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-warning dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/reappointmentMuqamiAmeer`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button10" />}</h4>
              </Col>
            ) : null}
            {CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-danger dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/unitJaiza`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button11" />}</h4>
              </Col>
            ) : null}{CheckPermission("CanViewMember") ? (
              <Col xl="1" className="text-center">
                <Button
                  className="btn-primary dim btn-large-dim"
                  onClick={() =>
                    history.push({
                      pathname: `/reports/arrivalsDepartures`,
                    })
                  }
                >
                  <TbReportAnalytics size={50} />
                </Button>
                <h4>{<GetLanguageString props="quick_reports_button12" />}</h4>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(QuickReports);
