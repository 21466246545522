import Lottie from "lottie-react";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch, BsTrash3Fill } from "react-icons/bs";
import { FaRegFileArchive } from "react-icons/fa";
import { FaFolderPlus, FaRegFileImage, FaRegFilePdf } from "react-icons/fa6";
import {
  FcFile,
  FcFolder,
  FcLink,
  FcOpenedFolder,
  FcVideoCall,
} from "react-icons/fc";
import { FiDownload, FiUpload } from "react-icons/fi";
import { PiBroomDuotone, PiEyeDuotone, PiLinkDuotone } from "react-icons/pi";
import { RiFileExcel2Line, RiFilePpt2Line } from "react-icons/ri";
import {
  TbFileTypeZip,
  TbHomeFilled,
  TbTrash,
  TbTrashOff,
} from "react-icons/tb";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import { encryptStorage } from "../../constants/EncryptStorage";
import {
  applyClassToCharacters,
  CheckPermission,
  customMultiValue,
  customOption,
  isUrdu,
  LoadingSpinner,
} from "../../constants/const";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import FormPrompt from "../shared/FormPrompt";
import AddNewFolder from "./AddNewFolder";
import { ApiDocuments, ApiFolders } from "./ApiDocuments";
import DetailBox from "./DetailBox";
import EditFile from "./EditFile";
import EditFolder from "./EditFolder";
import UploadFile from "./UploadFile";
import UploadStatus from "./UploadStatus";
import downloadingAnimation from "./downloadingIconAnimation.json";
import UploadFolder from "./UploadFolder";

const Documents = ({ history }) => {
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [folderUnitId, setFolderUnitId] = useState(loggedInUnitId);
  const [activeTab, setActiveTab] = useState("unitFolders");
  const [unitFolderUnitId, setUnitFolderUnitId] = useState(0);
  const [folderId, setFolderId] = useState(0);
  const [editValue, setEditValue] = useState();
  const [hierarchyDisable, setHierarchyDisable] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [unitSelectedRowIndex, setUnitSelectedRowIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [unitFolders, setUnitFolders] = useState([]);
  const [foldersBreadCrumbs, setFoldersBreadCrumbs] = useState([]);
  const [folderParentUnitIds, setFolderParentUnitIds] = useState([]);
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [uploadFolderModal, setUploadFolderModal] = useState(false);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [editFileModal, setEditFileModal] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [unitHoveredRowIndex, setUnitHoveredRowIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [detailBox, setDetailBox] = useState(false);
  const [documentTagsList, setDocumentTagsList] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [resultFromSearch, setResultFromSearch] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [showUploadOpions, setShowUploadOptions] = useState(false);
  const [selectedDocumentsForDelete, setSelectedDocumentsForDelete] = useState(
    []
  );
  const [droppedFolder, setDroppedFolder] = useState(null);
  const searchBoxRef = useRef(null);

  const toggleUploadOptions = () => setShowUploadOptions(!showUploadOpions);
  const toggleFileModal = () => setUploadFileModal(!uploadFileModal);
  const toggleFolderModal = () => setUploadFolderModal(!uploadFolderModal);

  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        toggleUploadOptions();
      }
    };

    if (showUploadOpions) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [showUploadOpions]);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const buttonStyle = {
    width: "180px",
    height: "100px",
    margin: "0 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "grey",
    fontSize: "1.2rem",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    border: "none",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    borderRadius: "15px",
    transform: "translateY(0)",
  };

  useEffect(() => {
    if (droppedFolder?.length > 0) setUploadFolderModal(true);
  }, [droppedFolder]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.filter((file) => !file.path.includes("/"));
      const folders = acceptedFiles.filter((file) => file.path.includes("/"));

      // Check if both files and folders are dropped
      if (files.length > 0 && folders.length > 0) {
        setError(t("document_files_and_folder_upload_error"));
        clearErrorAfterDelay(); // Clear the error after a delay (adjust this as needed)
        return; // Stop further processing
      }

      if (
        acceptedFiles.length > 0 &&
        acceptedFiles.every((e) => e.path.includes("/"))
      ) {
        const rootFolders = new Set(
          acceptedFiles
            .map((file) => file.path.split("/")[1]) // Get the first folder name after initial '/'
            .filter(Boolean) // Ensure it is not undefined or empty
        );

        // Check if there is only one unique root folder
        if (rootFolders.size === 1) {
          setDroppedFolder(acceptedFiles);
        } else {
          setError(t("document_multiple_folder_upload_error"));
          clearErrorAfterDelay();
        }
      } else {
        // Check the condition
        if (
          !folderParentUnitIds.includes(unitFolderUnitId) &&
          !resultFromSearch
        ) {
          acceptedFiles.forEach((uploadedFile) => {
            // If condition is true, continue with the file handling logic
            // const uploadedFile = acceptedFiles[0];
            // File size limit (26 MB = 26 * 1024 * 1024 bytes)
            const maxSizeBytes = 26214400; // 26 MB in bytes

            if (uploadedFile.size > maxSizeBytes) {
              setError(t("file_size_limit_exceed_error"));
              clearErrorAfterDelay();
              return;
            }

            // File type check (no executable files)
            const fileType =
              uploadedFile.type ||
              uploadedFile.name.split(".").pop().toLowerCase();
            if (
              fileType === "exe" ||
              fileType === "msi" ||
              fileType === "application/x-msdownload"
            ) {
              setError(t("file_type_error"));
              clearErrorAfterDelay();
              return;
            }
          });
          setDroppedFile(acceptedFiles);
          setError(null); // Reset error state
          setUploadFileModal(true);
        }
        // If the condition is false, the function will exit and no action is taken
      }
    },
    // eslint-disable-next-line
    [t, folderParentUnitIds, unitFolderUnitId, resultFromSearch]
  );

  const clearErrorAfterDelay = () => {
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    directory: true,
  });

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (action, index) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [`${action}-${index}`]: !prev[`${action}-${index}`],
    }));
  };

  useEffect(() => {
    ApiDocuments()
      .GetDocumentTags()
      .then((res) => {
        setDocumentTagsList(res.data);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, []);

  useEffect(() => {
    setDetailBox(false);
    setLoading(true);
    setHierarchyDisable(true);
    setFolderParentUnitIds([]);
    ApiFolders()
      .getFolderHierarchy(folderUnitId, folderId)
      .then((res) => {
        setFolderParentUnitIds(res.data.parentUnitIds);
        setFoldersBreadCrumbs(res.data.hierarchy);
        setResultFromSearch(res.data.resultFromSearch);
        setDetailBox(false);
        if (res.data.length > 0)
          setFolderId(
            res.data.hierarchy[res.data.hierarchy.length - 1]?.folderId
          );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHierarchyDisable(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [folderId]);

  useEffect(() => {
    setHierarchyDisable(true);
    setLoading(true);
    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        handleData(res);
        setHoveredRowIndex(null);
        setHierarchyDisable(false);
        setResultFromSearch(res.data.resultFromSearch);
        setSelectedDocumentsForDelete([]);
        setDeleteMultiple(false);

        if (folderId !== 0) setLoading(false);
        if (foldersBreadCrumbs.length <= 0) {
          setFolderId(res.data.folderId);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHierarchyDisable(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [folderId, refresh]);

  const uploadRefreshDocuments = () => {
    if (isCalled) return; // Prevents duplicate requests

    setIsCalled(true); // Set loading state to true
    setHierarchyDisable(true); // Optionally disable hierarchy or UI elements

    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        handleData(res);
        setHierarchyDisable(false);
        setUploadInProgress(false);
        setHoveredRowIndex(null);
      })
      .catch((err) => {
        console.log(err);
        setHierarchyDisable(false);
        swal({
          title: err.message || "Error occurred",
          icon: "error",
          buttons: "OK",
        });
      })
      .finally(() => {
        setIsCalled(false); // Reset loading state after request completes
      });
  };

  const handleData = (res) => {
    const files = res.data.files;

    // Split the array into two: one for folderUnitId === null, and the other for folderUnitId !== null
    const documents = files.filter((file) => file.folderUnitId === null); // folderUnitId is null
    const unitFolders = files.filter((file) => file.folderUnitId !== null); // folderUnitId is not null

    // Save to state
    setDocuments(documents);
    setUnitFolders(unitFolders);
  };

  const [isDownloading, setIsDownloading] = useState(
    Array(documents?.length).fill(false)
  );

  const handleUnitMouseEnter = (index) => {
    setUnitHoveredRowIndex(index);
  };

  const handleUnitMouseLeave = () => {
    setUnitHoveredRowIndex(null);
  };
  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleRowClick = (e, index) => {
    if (!e.target.closest(".unclickable-column")) {
      setSelectedRowIndex(index === selectedRowIndex ? null : index);
    }
  };
  const handleUnitRowClick = (e, index) => {
    if (!e.target.closest(".unclickable-column")) {
      setUnitSelectedRowIndex(index === unitSelectedRowIndex ? null : index);
    }
  };

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notify(t("document_link_copied"));
      })
      .catch((err) => {
        notify("Failed to copy: ", err);
      });
  };

  const showIcon = (index) => {
    return hoveredRowIndex === index || selectedRowIndex === index;
  };

  const formatSize = (sizeInBytes) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };

  const handleOnDelete = async (id, type, folderContainsFiles) => {
    const isFolder = type.includes("folder");
    swal({
      title: t("common_delete_message"),
      text: t(
        isFolder && folderContainsFiles
          ? "folder_delete_detail_message"
          : "common_delete_detail_message"
      ),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      // <-- Notice "async" here
      if (!willDelete) return; // Exit if user cancels
      const toastId = toast.loading(
        t(
          isFolder
            ? "document_delete_folder_toast"
            : "document_delete_single_file_toast"
        ),
        {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#40576d",
            color: "#DFE4ED",
          },
        }
      );

      setDeletionInProgress(true);
      const api = isFolder ? ApiFolders() : ApiDocuments();

      try {
        setLoading(true);

        await api.delete(id);
        toast.update(toastId, {
          render:
            1 +
            t(
              isFolder
                ? "document_delete_folder_toast_success"
                : "document_delete_single_file_toast_success"
            ),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          style: {
            backgroundColor: "#ffffff",
            color: "grey",
          },
        });
        setDeletionInProgress(false);
        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
        setDeletionInProgress(false);
        swal({
          title: err.response?.data,
          icon: "error",
          buttons: "OK",
        });
        toast.update(toastId, {
          render: "Error occurred",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          style: {
            backgroundColor: "#ffffff",
            color: "grey",
          },
        });
      } finally {
        setLoading(false);
      }
    });
  };

  const handleMultipleDelete = () => {
    const containsFolder = selectedDocumentsForDelete.some((item) =>
      item.type.includes("folder")
    );

    if (!containsFolder) {
      // Proceed directly if there are no folders in the selection
      performMultipleDelete();
      return;
    }

    // Show confirmation dialog if there's at least one folder
    swal({
      title: t("common_delete_message"),
      text: t("folder_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        performMultipleDelete();
      }
    });
  };

  // Separate delete logic to reuse for both cases
  const performMultipleDelete = () => {
    let tempValues = [...documents];
    const updatedDocuments = documents.filter(
      (d) => !selectedDocumentsForDelete.some((file) => file.id === d.id)
    );
    setDocuments(updatedDocuments);

    const toastId = toast.loading(t("document_delete_file_toast"), {
      position: toast.POSITION.TOP_CENTER,
      style: {
        backgroundColor: "#40576d",
        color: "#DFE4ED",
      },
    });

    setDeletionInProgress(true);
    setDeleteMultiple(false);

    ApiDocuments()
      .deleteMultiple(selectedDocumentsForDelete)
      .then((res) => {
        toast.update(toastId, {
          render:
            selectedDocumentsForDelete.length +
            t("document_delete_file_toast_success"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          style: {
            backgroundColor: "#ffffff",
            color: "grey",
          },
        });
        setDeletionInProgress(false);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        console.error(err);
        setDeletionInProgress(false);
        setDocuments(tempValues); // Revert documents if error occurs
        toast.update(toastId, {
          render: "Error occurred",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          style: {
            backgroundColor: "#ffffff",
            color: "grey",
          },
        });
        swal({
          title: err.response?.data || "Error occurred",
          icon: "error",
          buttons: "OK",
        });
      });
  };

  const getUnitName = (unitName) => {
    return (
      <ol
        className="breadcrumb"
        style={{
          backgroundColor: "transparent",
        }}
      >
        {unitName?.split("/").map((item, index) => {
          return (
            <li
              className={
                GetSelectedLanguage() === "ur"
                  ? "breadcrumb-item-ur"
                  : "breadcrumb-item"
              }
              key={index}
            >
              <span>{applyClassToCharacters(item, "ur")}</span>
            </li>
          );
        })}
      </ol>
    );
  };

  const handleDownload = async (fileId, index) => {
    const updateLoadingState = (isLoading) => {
      setIsDownloading((prevStates) => {
        const newLoadingStates = [...prevStates];
        newLoadingStates[index] = isLoading;
        return newLoadingStates;
      });
    };

    updateLoadingState(true);

    try {
      const response = await ApiDocuments().downloadDocument(fileId);

      if (response.status === 200) {
        const modifiedLink = `${response.data.downloadLink}&confirm=t`;

        // Open link in the current tab/window:
        window.location.href = modifiedLink;
      } else {
        swal({
          title: t(response.data),
          icon: "error",
          buttons: "OK",
        });
        console.error("Error getting download link:", response.data);
      }
    } catch (error) {
      swal({
        title: error.message,
        icon: "error",
        buttons: "OK",
      });
    } finally {
      updateLoadingState(false);
    }
  };

  const handleBreadCrumbs = (item) => {
    if (!loading) {
      setSearchTerm("");
      setSelectedTags([]);
      setFolderId(item.folderId);
      setFolderUnitId(item.folderUnitId ? item.folderUnitId : 0);
      if (item.folderUnitId) setUnitFolderUnitId(item.folderUnitId);
    }
  };

  const ButtonsComponent = (className) => {
    return CheckPermission("CanAddDocuments") ? (
      <ButtonGroup className={className}>
        <Button
          disabled={deletionInProgress || uploadInProgress}
          color="white"
          onClick={() => setNewFolderModal(true)}
        >
          <FaFolderPlus size={15} color="#FFCA28" />
          &nbsp; <GetLanguageString props={"document_new_folder"} />
        </Button>
        &nbsp;&nbsp;
        <Button
          disabled={deletionInProgress || uploadInProgress}
          color="white"
          onClick={() => {
            toggleUploadOptions();
          }}
        >
          {" "}
          <FiUpload size={15} color="#35A29F" />
          &nbsp;
          <GetLanguageString props={"document_upload_file"} />
        </Button>{" "}
      </ButtonGroup>
    ) : null;
  };

  const getFileIcon = (fileType) => {
    const iconStyle = { marginRight: 5, marginBottom: 3, size: 16 };

    if (fileType.includes("folder")) {
      return <FcFolder size={iconStyle.size} style={iconStyle} />;
    } else if (fileType.includes("pdf")) {
      return (
        <FaRegFilePdf size={iconStyle.size} color="red" style={iconStyle} />
      );
    } else if (
      fileType.includes("jpg") ||
      fileType.includes("png") ||
      fileType.includes("jpeg")
    ) {
      return (
        <FaRegFileImage
          size={iconStyle.size}
          color="#35A29F"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("pptx") || fileType.includes("presentation")) {
      return (
        <RiFilePpt2Line
          size={iconStyle.size}
          color="#B94623"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("mp4")) {
      return (
        <FcVideoCall size={iconStyle.size} color="#5C6E91" style={iconStyle} />
      );
    } else if (fileType.includes("x-zip-compressed")) {
      return (
        <TbFileTypeZip
          size={iconStyle.size}
          color="#5A509B"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("x-compressed")) {
      return (
        <FaRegFileArchive
          size={iconStyle.size}
          color="#5A509B"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("spreadsheet")) {
      return (
        <RiFileExcel2Line
          size={iconStyle.size}
          color="#009440"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("link")) {
      return (
        <FcLink
          size={iconStyle.size}
          // color="#009440"
          style={iconStyle}
        />
      );
    } else {
      return <FcFile size={iconStyle.size} style={iconStyle} />;
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    // Call handleSearch whenever searchTerm or selectedTags changes
    if (
      searchTerm === "" &&
      selectedTags.length === 0 &&
      resultFromSearch &&
      clearSearch
    ) {
      handleSearch();
    }
    setClearSearch(false);
    // eslint-disable-next-line
  }, [searchTerm, selectedTags, clearSearch]);

  const handleClearSearch = () => {
    if (searchTerm !== "" || selectedTags.length !== 0) {
      setSearchTerm("");
      setSelectedTags([]);
      setClearSearch(true);
    }
  };

  const handleSearch = () => {
    setDetailBox(false);
    setLoading(true);
    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        handleData(res);
        setHoveredRowIndex(null);
        setHierarchyDisable(false);
        setResultFromSearch(res.data.resultFromSearch);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  // Toggle selection for a row
  const handleDocumentsSelect = (file) => {
    setSelectedDocumentsForDelete((prevSelected) => {
      const exists = prevSelected.some((item) => item.id === file.id);

      if (exists) {
        // Deselect by removing the item from the list
        return prevSelected.filter((item) => item.id !== file.id);
      } else {
        // Select by adding the item to the list
        return [...prevSelected, { id: file.id, type: file.fileType }];
      }
    });
  };

  // Select all rows
  const handleSelectAllDocuments = () => {
    if (selectedDocumentsForDelete.length === documents.length) {
      setSelectedDocumentsForDelete([]); // Deselect all if all are selected
    } else {
      let temp = [];
      documents.map((file) => temp.push({ id: file.id, type: file.fileType })); // Map only the ids of the filtered files

      setSelectedDocumentsForDelete(temp);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      minHeight: "30px",
      border: state.isFocused ? "1px solid #1AB394" : "",
      boxShadow: state.isFocused ? "0 0 0 1px #1AB394" : "none",
      "&:hover": {
        border: "1px solid #1AB394",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "15px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      backgroundColor: state.isSelected ? "#1AB394" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#BAE8DE",
        color: "black",
      },
    }),
  };

  // Handle input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearchTagsChange = (newValue) => {
    setSelectedTags(newValue || []);
  };

  const tabs = [
    {
      id: "unitFolders",
      label: "document_tab_1",
    },
    {
      id: "otherFiles",
      label: "document_tab_2",
    },
  ];

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (deletionInProgress) {
        return "Files deletion in progress. Are you sure you want to leave this page?\nفائلز حذف کی جا رہی ہیں ۔ کیا آپ اس پیج کو ریلوڈ کرنا چاھتے ہیں؟";
      } else if (uploadInProgress) {
        return "Files uploading in progress. Are you sure you want to leave this page?\nفائلز اپلوڈ کی جا رہی ہیں ۔ کیا آپ اس پیج کو ریلوڈ کرنا چاھتے ہیں؟";
      }
    });

    return () => unblock();
  }, [deletionInProgress, uploadInProgress, history]);

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <FormPrompt hasUnsavedChanges={deletionInProgress} />
      <FormPrompt hasUnsavedChanges={uploadInProgress} />
      <ToastContainer
        autoClose={3000}
        bodyClassName={GetSelectedLanguage()}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {newFolderModal ? (
        <AddNewFolder
          setNewFolderModal={setNewFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
        />
      ) : null}
      {editFolderModal ? (
        <EditFolder
          setEditFolderModal={setEditFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          folderId={editValue}
        />
      ) : null}
      {editFileModal ? (
        <EditFile
          setEditFileModal={setEditFileModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          fileId={editValue}
        />
      ) : null}
      {uploadFolderModal ? (
        <UploadFolder
          uploadFolderModal={uploadFolderModal}
          setUploadFolderModal={setUploadFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          refresh={refresh}
          setUploadInProgress={setUploadInProgress}
          uploadInProgress={uploadInProgress}
          droppedFolder={droppedFolder}
          setDroppedFolder={setDroppedFolder}
          documents={documents}
          setDocuments={setDocuments}
        />
      ) : null}
      {uploadFileModal ? (
        <UploadFile
          setUploadFileModal={setUploadFileModal}
          folderId={folderId}
          uploadRefreshDocuments={uploadRefreshDocuments}
          documents={documents}
          setDocuments={setDocuments}
          droppedFile={droppedFile}
          setDroppedFile={setDroppedFile}
          setUploadInProgress={setUploadInProgress}
        />
      ) : null}
      {showUploadOpions && (
        <div style={overlayStyle}>
          <div
            ref={overlayRef}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={buttonStyle}
              onClick={() => {
                toggleUploadOptions();
                toggleFileModal();
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "translateY(-10px)";
                e.target.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "translateY(0)";
                e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
              }}
            >
              <FiUpload size={30} color="#35A29F" />
              <GetLanguageString props={"upload_file"} />
            </Button>
            <Button
              style={buttonStyle}
              onClick={() => {
                toggleUploadOptions();
                toggleFolderModal();
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "translateY(-10px)";
                e.target.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "translateY(0)";
                e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
              }}
            >
              <FaFolderPlus size={30} color="#FFCA28" />
              <GetLanguageString props={"upload_folder"} />
            </Button>
          </div>
        </div>
      )}
      <div className="ibox">
        <div className="ibox-title">
          <Row>
            <Col md="3">
              <h5 style={{ paddingTop: 10 }}>
                <GetLanguageString props={"sidebar_documents"} />
              </h5>
            </Col>
            <Col md="4">
              <div
                ref={searchBoxRef}
                // className={GetCustomStyles().btn_group_style}
                style={{ position: "relative", width: "100%" }}
              >
                <InputGroup>
                  <InputGroupText
                    className="position-absolute bg-transparent border-0 text-muted"
                    style={{
                      zIndex: 4,
                      left: GetSelectedLanguage() === "ur" ? "unset" : "5px", // Adjust search icon position for RTL
                      right: GetSelectedLanguage() === "ur" ? "5px" : "unset", // Adjust for RTL
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <BsSearch size={17} />
                  </InputGroupText>
                  <Input
                    type="search"
                    placeholder={
                      GetSelectedLanguage() === "ur"
                        ? "ڈھونڈیں"
                        : "Search Documents/Folders"
                    }
                    className={isUrdu(searchTerm) ? "ur" : ""}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    style={{
                      textAlign:
                        GetSelectedLanguage() === "ur" ? "right" : "left",
                      direction: GetSelectedLanguage() === "ur" ? "rtl" : "ltr",
                      height: "37px",
                      borderRadius: "20px",
                      padding:
                        GetSelectedLanguage() === "ur"
                          ? "12px 45px 12px 16px"
                          : "12px 16px 12px 45px",
                      "&:hover": {
                        border: "1px solid #1AB394",
                      },
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col md="3">
              {" "}
              <Select
                styles={customStyles}
                options={documentTagsList}
                components={{
                  Option: customOption,
                  MultiValue: customMultiValue,
                }}
                value={selectedTags}
                isMulti
                onKeyDown={handleKeyDown}
                onChange={handleSearchTagsChange}
                className={
                  GetSelectedLanguage() === "ur"
                    ? "basic-multi-select ur no-drag"
                    : "basic-multi-select no-drag"
                }
                placeholder={GetSelectedLanguage() === "ur" ? "ٹیگز" : "Tags"}
                classNamePrefix="select"
              />
            </Col>
            <Col md="2">
              <ButtonGroup className={GetCustomStyles().btn_group_style}>
                <Button
                  disabled={deletionInProgress || uploadInProgress}
                  color="default"
                  size="sm"
                  onClick={handleSearch}
                >
                  <BsSearch size={14} style={{ marginBottom: 2 }} />
                  &nbsp;
                  <GetLanguageString props={"common_search"} />
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="default"
                  size="sm"
                  disabled={
                    (searchTerm === "" && selectedTags.length === 0) ||
                    deletionInProgress ||
                    uploadInProgress
                  }
                  // style={{ width: "120px" }}
                  onClick={handleClearSearch}
                >
                  <PiBroomDuotone size={14} style={{ marginBottom: 2 }} />
                  &nbsp;
                  <GetLanguageString props={"clear_search"} />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="6">
              <ol className="breadcrumb" style={{ marginTop: 8 }}>
                {foldersBreadCrumbs?.map((item, index) => {
                  return (
                    <li
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "breadcrumb-item-ur"
                          : "breadcrumb-item"
                      }
                      key={index}
                      onClick={() => {
                        if (
                          !hierarchyDisable &&
                          !deletionInProgress &&
                          !uploadInProgress
                        ) {
                          handleBreadCrumbs(item);
                        }
                      }}
                      style={{
                        cursor:
                          hierarchyDisable ||
                          deletionInProgress ||
                          uploadInProgress
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <Link
                        to="#"
                        style={{
                          cursor:
                            hierarchyDisable ||
                            deletionInProgress ||
                            uploadInProgress
                              ? "default"
                              : "pointer",
                          pointerEvents:
                            hierarchyDisable ||
                            deletionInProgress ||
                            uploadInProgress
                              ? "none"
                              : "auto",
                          color:
                            hierarchyDisable ||
                            deletionInProgress ||
                            uploadInProgress
                              ? "gray"
                              : "inherit",
                        }}
                      >
                        {index === 0 ? (
                          <TbHomeFilled
                            size={14}
                            color={
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black"
                            }
                          />
                        ) : index === foldersBreadCrumbs.length - 1 ? (
                          <FcOpenedFolder size={16} />
                        ) : (
                          <FcFolder size={16} />
                        )}
                        &nbsp;
                        <span
                          className={isUrdu(item.folderName) ? "ur" : ""}
                          style={{
                            display: "inline-block",
                            fontWeight:
                              foldersBreadCrumbs.length - 1 === index
                                ? 600
                                : 500,
                            color:
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black",
                          }}
                        >
                          {item.folderName}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </Col>
            {(documents.length !== 0 || unitFolders.length !== 0) &&
            !folderParentUnitIds.includes(unitFolderUnitId) &&
            !resultFromSearch &&
            activeTab !== "unitFolders" ? (
              <Col md="6">
                {ButtonsComponent(GetCustomStyles().unit_btn_style)}
              </Col>
            ) : null}
          </Row>
          <br></br>
          <Row>
            <Col md="12">
              <div className="tabs-container">
                <Nav tabs>
                  {tabs.map((tab) => (
                    <NavItem key={tab.id}>
                      <NavLink
                        className={activeTab === tab.id ? "active" : ""}
                        onClick={() => toggleTab(tab.id)}
                      >
                        <GetLanguageString props={tab.label} />
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {tabs.map((tab) => {
                    return (
                      <TabPane key={tab.id} tabId={tab.id}>
                        {tab.id === "unitFolders" ? (
                          <div className="panel-body">
                            {" "}
                            {!loading ? (
                              unitFolders.length !== 0 ? (
                                <Row>
                                  <Table responsive hover bordered>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>
                                          <GetLanguageString
                                            props={"common_name"}
                                          />
                                        </th>
                                        <th>
                                          <GetLanguageString
                                            props={
                                              "member_member_detail_document_upload_date"
                                            }
                                          />
                                        </th>
                                        {resultFromSearch ? (
                                          <th>
                                            <GetLanguageString
                                              props={"folder_path"}
                                            />
                                          </th>
                                        ) : null}
                                        <th>
                                          <GetLanguageString
                                            props={
                                              "member_member_detail_document_size"
                                            }
                                          />
                                        </th>
                                        <th>
                                          <GetLanguageString
                                            props={
                                              "dashboard_event_page_status"
                                            }
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {unitFolders.map((file, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            onMouseEnter={() =>
                                              handleUnitMouseEnter(index)
                                            }
                                            onMouseLeave={handleUnitMouseLeave}
                                            style={{
                                              backgroundColor:
                                                unitSelectedRowIndex ===
                                                  index ||
                                                unitHoveredRowIndex === index
                                                  ? "#DBECE6"
                                                  : "white",
                                              cursor: "default",
                                            }}
                                            onDoubleClick={(e) => {
                                              if (
                                                !e.target.closest(
                                                  ".unclickable-column"
                                                ) &&
                                                file.fileType.includes(
                                                  "folder"
                                                ) &&
                                                !uploadInProgress &&
                                                !deletionInProgress
                                              ) {
                                                setFolderId(file.id);
                                                setSearchTerm("");
                                                setSelectedTags([]);
                                                setFolderUnitId(
                                                  file.folderUnitId
                                                    ? file.folderUnitId
                                                    : 0
                                                );
                                                if (file.folderUnitId)
                                                  setUnitFolderUnitId(
                                                    file.folderUnitId
                                                  );
                                              }
                                            }}
                                            onClick={(e) => {
                                              handleUnitRowClick(e, index);
                                            }}
                                          >
                                            <td>{index + 1}</td>
                                            <td style={{ userSelect: "none" }}>
                                              {getFileIcon(file.fileType)}
                                              &nbsp;
                                              {applyClassToCharacters(
                                                file.name,
                                                "ur"
                                              )}
                                              &nbsp;
                                              {file.isUnitDeleted && (
                                                <GetLanguageString
                                                  props={"folder_unit_deleted"}
                                                />
                                              )}
                                            </td>
                                            <td className={"en"}>
                                              {moment(file.createdDate)
                                                .locale(GetSelectedLanguage())
                                                .format("DD/MM/YYYY")}{" "}
                                              &nbsp;
                                              {GetSelectedLanguage() === "ur"
                                                ? moment(file.createdDate)
                                                    .locale(
                                                      GetSelectedLanguage()
                                                    )
                                                    .format("a\u00a0h:mm")
                                                : moment(file.createdDate)
                                                    .locale(
                                                      GetSelectedLanguage()
                                                    )
                                                    .format("h:mm a")}
                                            </td>
                                            {resultFromSearch ? (
                                              <td>
                                                {getUnitName(
                                                  file.folderPath,
                                                  index
                                                )}
                                              </td>
                                            ) : null}
                                            <td>
                                              {file.size
                                                ? formatSize(file.size)
                                                : "-"}
                                            </td>
                                            <td>
                                              <UploadStatus
                                                fileStatus={file.fileStatus}
                                                jobId={
                                                  file.fileUploadBackgroundJobId
                                                }
                                                uploadRefreshDocuments={
                                                  uploadRefreshDocuments
                                                }
                                                fileId={file.id}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </Row>
                              ) : (
                                <NoResult />
                              )
                            ) : (
                              LoadingSpinner()
                            )}
                          </div>
                        ) : (
                          <div className="panel-body">
                            {" "}
                            {!loading ? (
                              documents.length !== 0 ? (
                                <>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {(documents.length !== 0 ||
                                      unitFolders.length !== 0) &&
                                    !folderParentUnitIds.includes(
                                      unitFolderUnitId
                                    ) &&
                                    !resultFromSearch ? (
                                      <>
                                        <ButtonGroup
                                          className={
                                            GetCustomStyles().btn_group_style
                                          }
                                        >
                                          <Button
                                            disabled={
                                              deletionInProgress ||
                                              uploadInProgress
                                            }
                                            color={"white"}
                                            size={"xs"}
                                            onClick={() =>
                                              setDeleteMultiple(!deleteMultiple)
                                            }
                                          >
                                            {deleteMultiple ? (
                                              <TbTrashOff size={15} />
                                            ) : (
                                              <TbTrash size={15} />
                                            )}
                                            &nbsp;{" "}
                                            <GetLanguageString
                                              props={
                                                deleteMultiple
                                                  ? "document_delete_multiple_cancel"
                                                  : "document_delete_multiple"
                                              }
                                            />
                                          </Button>{" "}
                                          &nbsp; &nbsp;
                                          {selectedDocumentsForDelete.length !==
                                            0 && deleteMultiple ? (
                                            <Button
                                              disabled={
                                                deletionInProgress ||
                                                uploadInProgress
                                              }
                                              color={"white"}
                                              size={"xs"}
                                              onClick={handleMultipleDelete}
                                            >
                                              <TbTrash size={15} /> &nbsp;
                                              <GetLanguageString
                                                props={
                                                  "document_delete_selected"
                                                }
                                              />
                                            </Button>
                                          ) : null}
                                        </ButtonGroup>
                                        <br></br>
                                        <br></br>
                                      </>
                                    ) : null}

                                    <Row>
                                      <Col md={detailBox ? "9" : "12"}>
                                        <Table responsive hover bordered>
                                          <thead>
                                            <tr>
                                              {" "}
                                              {deleteMultiple ? (
                                                <th className="unclickable-column text-center">
                                                  {documents.some(
                                                    (doc) =>
                                                      doc.canChangePermissions ===
                                                      true
                                                  ) ? (
                                                    <Input
                                                      type="checkbox"
                                                      checked={
                                                        selectedDocumentsForDelete.length ===
                                                        documents.length
                                                      }
                                                      onChange={
                                                        handleSelectAllDocuments
                                                      }
                                                    />
                                                  ) : null}
                                                </th>
                                              ) : null}
                                              <th>#</th>
                                              <th>
                                                <GetLanguageString
                                                  props={"common_name"}
                                                />
                                              </th>
                                              <th>
                                                <GetLanguageString
                                                  props={
                                                    "member_member_detail_document_upload_date"
                                                  }
                                                />
                                              </th>
                                              {resultFromSearch ? (
                                                <th>
                                                  <GetLanguageString
                                                    props={"folder_path"}
                                                  />
                                                </th>
                                              ) : null}
                                              <th>
                                                <GetLanguageString
                                                  props={
                                                    "member_member_detail_document_size"
                                                  }
                                                />
                                              </th>
                                              <th>
                                                <GetLanguageString
                                                  props={
                                                    "dashboard_event_page_status"
                                                  }
                                                />
                                              </th>
                                              <th className="unclickable-column"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {documents.map((file, index) => {
                                              return (
                                                <tr
                                                  className={
                                                    selectedDocumentsForDelete.includes(
                                                      index
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }
                                                  key={index}
                                                  onMouseEnter={() =>
                                                    handleMouseEnter(index)
                                                  }
                                                  onMouseLeave={
                                                    handleMouseLeave
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      selectedRowIndex ===
                                                        index ||
                                                      hoveredRowIndex === index
                                                        ? "#DBECE6"
                                                        : "white",
                                                    cursor: "default",
                                                  }}
                                                  onDoubleClick={(e) => {
                                                    if (
                                                      !e.target.closest(
                                                        ".unclickable-column"
                                                      ) &&
                                                      file.fileType.includes(
                                                        "folder"
                                                      ) &&
                                                      !uploadInProgress &&
                                                      !deletionInProgress
                                                    ) {
                                                      setFolderId(file.id);
                                                      setSearchTerm("");
                                                      setSelectedTags([]);
                                                      setFolderUnitId(
                                                        file.folderUnitId
                                                          ? file.folderUnitId
                                                          : 0
                                                      );
                                                      if (file.folderUnitId)
                                                        setUnitFolderUnitId(
                                                          file.folderUnitId
                                                        );
                                                    }
                                                  }}
                                                  onClick={(e) => {
                                                    handleRowClick(e, index);
                                                    if (
                                                      !file.fileType.includes(
                                                        "folder"
                                                      ) &&
                                                      !e.target.closest(
                                                        ".unclickable-column"
                                                      )
                                                    ) {
                                                      setSelectedFile(file);
                                                      setDetailBox(true);
                                                    } else setDetailBox(false);
                                                  }}
                                                >
                                                  {deleteMultiple ? (
                                                    <td className="unclickable-column text-center">
                                                      {file.folderUnitId ==
                                                        null &&
                                                      !isDownloading[index] &&
                                                      file.canChangePermissions ? (
                                                        <Input
                                                          type="checkbox"
                                                          checked={selectedDocumentsForDelete.some(
                                                            (item) =>
                                                              item.id ===
                                                              file.id
                                                          )}
                                                          onChange={() =>
                                                            handleDocumentsSelect(
                                                              file
                                                            )
                                                          }
                                                        />
                                                      ) : null}
                                                    </td>
                                                  ) : null}
                                                  <td>{index + 1}</td>
                                                  <td
                                                    style={{
                                                      userSelect: "none",
                                                    }}
                                                  >
                                                    {getFileIcon(file.fileType)}
                                                    &nbsp;
                                                    {applyClassToCharacters(
                                                      file.name,
                                                      "ur"
                                                    )}
                                                    &nbsp;
                                                    {file.isUnitDeleted && (
                                                      <GetLanguageString
                                                        props={
                                                          "folder_unit_deleted"
                                                        }
                                                      />
                                                    )}
                                                  </td>
                                                  <td className={"en"}>
                                                    {moment(file.createdDate)
                                                      .locale(
                                                        GetSelectedLanguage()
                                                      )
                                                      .format(
                                                        "DD/MM/YYYY"
                                                      )}{" "}
                                                    &nbsp;
                                                    {GetSelectedLanguage() ===
                                                    "ur"
                                                      ? moment(file.createdDate)
                                                          .locale(
                                                            GetSelectedLanguage()
                                                          )
                                                          .format("a\u00a0h:mm")
                                                      : moment(file.createdDate)
                                                          .locale(
                                                            GetSelectedLanguage()
                                                          )
                                                          .format("h:mm a")}
                                                  </td>
                                                  {resultFromSearch ? (
                                                    <td>
                                                      {getUnitName(
                                                        file.folderPath,
                                                        index
                                                      )}
                                                    </td>
                                                  ) : null}
                                                  <td>
                                                    {file.size
                                                      ? formatSize(file.size)
                                                      : "-"}
                                                  </td>
                                                  <td>
                                                    <UploadStatus
                                                      fileStatus={
                                                        file.fileStatus
                                                      }
                                                      jobId={
                                                        file.fileUploadBackgroundJobId
                                                      }
                                                      uploadRefreshDocuments={
                                                        uploadRefreshDocuments
                                                      }
                                                      fileId={file.id}
                                                    />
                                                  </td>
                                                  <td
                                                    className="text-center unclickable-column"
                                                    style={{ width: "150px" }}
                                                  >
                                                    <div>
                                                      {!file.fileType.includes(
                                                        "folder"
                                                      ) ? (
                                                        <>
                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null &&
                                                          !isDownloading[
                                                            index
                                                          ] ? (
                                                            <PiLinkDuotone
                                                              size={20}
                                                              id={`copyLink-${index}`}
                                                              style={{
                                                                outline: "none",
                                                                cursor:
                                                                  file.fileStatus ===
                                                                  "file_uploaded"
                                                                    ? "pointer"
                                                                    : "default",
                                                              }}
                                                              color={
                                                                showIcon(
                                                                  index
                                                                ) &&
                                                                file.fileStatus ===
                                                                  "file_uploaded"
                                                                  ? "#35A29F"
                                                                  : "white"
                                                              }
                                                              onClick={() => {
                                                                file.fileStatus ===
                                                                  "file_uploaded" &&
                                                                  copyToClipboard(
                                                                    file.fileUrl
                                                                  );
                                                              }}
                                                            />
                                                          ) : null}
                                                          &nbsp;&nbsp;
                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null &&
                                                          !isDownloading[
                                                            index
                                                          ] ? (
                                                            <PiEyeDuotone
                                                              size={20}
                                                              id={`viewIcon-${index}`}
                                                              style={{
                                                                outline: "none",

                                                                cursor:
                                                                  file.fileStatus ===
                                                                  "file_uploaded"
                                                                    ? "pointer"
                                                                    : "default",
                                                              }}
                                                              color={
                                                                showIcon(
                                                                  index
                                                                ) &&
                                                                file.fileStatus ===
                                                                  "file_uploaded"
                                                                  ? "#35A29F"
                                                                  : "white"
                                                              }
                                                              onClick={() => {
                                                                file.fileStatus ===
                                                                  "file_uploaded" &&
                                                                  window.open(
                                                                    file.fileUrl,
                                                                    "_blank"
                                                                  );
                                                              }}
                                                            />
                                                          ) : null}
                                                          &nbsp;&nbsp;
                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null ? (
                                                            !isDownloading[
                                                              index
                                                            ] ? (
                                                              <FiDownload
                                                                size={18}
                                                                id={`downloadIcon-${index}`}
                                                                style={{
                                                                  outline:
                                                                    "none",

                                                                  cursor:
                                                                    file.fileStatus ===
                                                                    "file_uploaded"
                                                                      ? "pointer"
                                                                      : "default",
                                                                }}
                                                                color={
                                                                  showIcon(
                                                                    index
                                                                  ) &&
                                                                  !file.fileType.includes(
                                                                    "folder"
                                                                  ) &&
                                                                  file.fileStatus ===
                                                                    "file_uploaded"
                                                                    ? "#35A29F"
                                                                    : "white"
                                                                }
                                                                onClick={() => {
                                                                  !file.fileType.includes(
                                                                    "folder"
                                                                  ) &&
                                                                    file.fileStatus ===
                                                                      "file_uploaded" &&
                                                                    handleDownload(
                                                                      file.driveId,
                                                                      index
                                                                    );
                                                                }}
                                                              />
                                                            ) : (
                                                              <Lottie
                                                                id={`downloadIcon-${index}`}
                                                                animationData={
                                                                  downloadingAnimation
                                                                }
                                                                loop={true}
                                                                autoplay={true}
                                                                style={{
                                                                  width: "38px",
                                                                  height:
                                                                    "38px",
                                                                  marginBottom:
                                                                    -87,
                                                                  marginTop:
                                                                    -30,
                                                                  marginLeft: 47,
                                                                }}
                                                              />
                                                            )
                                                          ) : null}
                                                          &nbsp;&nbsp;
                                                        </>
                                                      ) : null}
                                                      {CheckPermission(
                                                        "CanEditDocuments"
                                                      ) &&
                                                      file.folderUnitId ===
                                                        null &&
                                                      !isDownloading[index] &&
                                                      file.canChangePermissions ? (
                                                        <BiSolidEdit
                                                          size={20}
                                                          id={`editIcon-${index}`}
                                                          style={{
                                                            outline: "none",
                                                            cursor: "pointer",
                                                          }}
                                                          color={
                                                            showIcon(index)
                                                              ? "#35A29F"
                                                              : "white"
                                                          }
                                                          onClick={() => {
                                                            setEditValue(
                                                              file.id
                                                            );
                                                            file.fileType.includes(
                                                              "folder"
                                                            )
                                                              ? setEditFolderModal(
                                                                  true
                                                                )
                                                              : setEditFileModal(
                                                                  true
                                                                );
                                                          }}
                                                        />
                                                      ) : null}
                                                      &nbsp;&nbsp;
                                                      {CheckPermission(
                                                        "CanDeleteDocuments"
                                                      ) &&
                                                      file.folderUnitId ===
                                                        null &&
                                                      !isDownloading[index] &&
                                                      file.canChangePermissions ? (
                                                        <BsTrash3Fill
                                                          id={`deleteIcon-${index}`}
                                                          style={{
                                                            outline: "none",
                                                            cursor: "pointer",
                                                          }}
                                                          size={17}
                                                          color={
                                                            showIcon(index)
                                                              ? "#35A29F"
                                                              : "white"
                                                          }
                                                          onClick={() => {
                                                            handleOnDelete(
                                                              file.id,
                                                              file.fileType,
                                                              file.folderHaveFilesOrFolders
                                                            );
                                                          }}
                                                        />
                                                      ) : null}
                                                      {
                                                        //Tooltips for action buttons
                                                        <>
                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null
                                                            ? document.getElementById(
                                                                `downloadIcon-${index}`
                                                              ) &&
                                                              file.fileType !==
                                                                "folder" && (
                                                                <Tooltip
                                                                  isOpen={
                                                                    tooltipOpen[
                                                                      `download-${index}`
                                                                    ] || false
                                                                  }
                                                                  target={`downloadIcon-${index}`}
                                                                  toggle={() =>
                                                                    toggleTooltip(
                                                                      "download",
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <GetLanguageString
                                                                    props={
                                                                      "member_add_rafiq_request_download_document"
                                                                    }
                                                                  />
                                                                </Tooltip>
                                                              )
                                                            : null}

                                                          {CheckPermission(
                                                            "CanEditDocuments"
                                                          ) &&
                                                          file.canChangePermissions &&
                                                          file.folderUnitId ==
                                                            null
                                                            ? document.getElementById(
                                                                `editIcon-${index}`
                                                              ) && (
                                                                <Tooltip
                                                                  isOpen={
                                                                    tooltipOpen[
                                                                      `edit-${index}`
                                                                    ] || false
                                                                  }
                                                                  target={`editIcon-${index}`}
                                                                  toggle={() =>
                                                                    toggleTooltip(
                                                                      "edit",
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <GetLanguageString
                                                                    props={
                                                                      "common_edit"
                                                                    }
                                                                  />
                                                                </Tooltip>
                                                              )
                                                            : null}

                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null
                                                            ? document.getElementById(
                                                                `viewIcon-${index}`
                                                              ) && (
                                                                <Tooltip
                                                                  isOpen={
                                                                    tooltipOpen[
                                                                      `view-${index}`
                                                                    ] || false
                                                                  }
                                                                  target={`viewIcon-${index}`}
                                                                  toggle={() =>
                                                                    toggleTooltip(
                                                                      "view",
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <GetLanguageString
                                                                    props={
                                                                      "member_view"
                                                                    }
                                                                  />
                                                                </Tooltip>
                                                              )
                                                            : null}
                                                          {CheckPermission(
                                                            "CanDownloadDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null
                                                            ? document.getElementById(
                                                                `copyLink-${index}`
                                                              ) && (
                                                                <Tooltip
                                                                  isOpen={
                                                                    tooltipOpen[
                                                                      `copy-${index}`
                                                                    ] || false
                                                                  }
                                                                  target={`copyLink-${index}`}
                                                                  toggle={() =>
                                                                    toggleTooltip(
                                                                      "copy",
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <GetLanguageString
                                                                    props={
                                                                      "document_link_copy"
                                                                    }
                                                                  />
                                                                </Tooltip>
                                                              )
                                                            : null}
                                                          {CheckPermission(
                                                            "CanDeleteDocuments"
                                                          ) &&
                                                          file.folderUnitId ==
                                                            null &&
                                                          file.canChangePermissions
                                                            ? document.getElementById(
                                                                `deleteIcon-${index}`
                                                              ) && (
                                                                <Tooltip
                                                                  isOpen={
                                                                    tooltipOpen[
                                                                      `delete-${index}`
                                                                    ] || false
                                                                  }
                                                                  target={`deleteIcon-${index}`}
                                                                  toggle={() =>
                                                                    toggleTooltip(
                                                                      "delete",
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <GetLanguageString
                                                                    props={
                                                                      "common_delete"
                                                                    }
                                                                  />
                                                                </Tooltip>
                                                              )
                                                            : null}
                                                        </>
                                                      }
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </Table>
                                      </Col>
                                      <DetailBox
                                        detailBox={detailBox}
                                        setDetailBox={setDetailBox}
                                        selectedFile={selectedFile}
                                        formatSize={formatSize}
                                        getUnitName={getUnitName}
                                      />
                                    </Row>
                                    {isDragActive && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          backgroundColor:
                                            folderParentUnitIds.includes(
                                              folderUnitId
                                            ) || resultFromSearch
                                              ? "rgba(250, 197, 201, 0.8)" // Light red if the condition is true
                                              : "rgba(255, 255, 255, 0.8)", // Default white background
                                          border: "2px dashed #cccccc",
                                          borderRadius: "4px",
                                          cursor:
                                            folderParentUnitIds.includes(
                                              unitFolderUnitId
                                            ) || resultFromSearch
                                              ? "not-allowed" // Error cursor if the condition is true
                                              : "default",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "24px",
                                            color: "#333",
                                          }}
                                        >
                                          {folderParentUnitIds.includes(
                                            unitFolderUnitId
                                          ) || resultFromSearch ? (
                                            <GetLanguageString
                                              props={
                                                "file_cannot_be_dropped_here"
                                              }
                                            /> // Show error message
                                          ) : (
                                            <GetLanguageString
                                              props={
                                                "drop_files_here_to_upload"
                                              }
                                            /> // Default message
                                          )}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div
                                    className="text-center"
                                    style={{
                                      fontStyle: "italic",
                                      color: "#FF3333",
                                      marginTop: 30,
                                      marginBottom: 10,
                                    }}
                                  >
                                    <h4>
                                      {resultFromSearch ? (
                                        <NoResult />
                                      ) : (
                                        <GetLanguageString
                                          props={
                                            CheckPermission(
                                              "CanAddDocuments"
                                            ) &&
                                            !folderParentUnitIds.includes(
                                              unitFolderUnitId
                                            )
                                              ? "document_no_file_folder_present"
                                              : "document_no_file_folder_present_only"
                                          }
                                        />
                                      )}
                                    </h4>
                                  </div>
                                  <div
                                    className="text-center"
                                    style={{ marginBottom: 30 }}
                                  >
                                    {folderParentUnitIds.includes(
                                      unitFolderUnitId
                                    )
                                      ? null
                                      : !resultFromSearch
                                      ? ButtonsComponent("")
                                      : null}
                                  </div>
                                  {isDragActive && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor:
                                          folderParentUnitIds.includes(
                                            folderUnitId
                                          ) || resultFromSearch
                                            ? "rgba(250, 197, 201, 0.8)" // Light red if the condition is true
                                            : "rgba(255, 255, 255, 0.8)", // Default white background
                                        border: "2px dashed #cccccc",
                                        borderRadius: "4px",
                                        cursor:
                                          folderParentUnitIds.includes(
                                            unitFolderUnitId
                                          ) || resultFromSearch
                                            ? "not-allowed" // Error cursor if the condition is true
                                            : "default",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "24px",
                                          color: "#333",
                                        }}
                                      >
                                        {folderParentUnitIds.includes(
                                          unitFolderUnitId
                                        ) || resultFromSearch ? (
                                          <GetLanguageString
                                            props={
                                              "file_cannot_be_dropped_here"
                                            }
                                          /> // Show error message
                                        ) : (
                                          <GetLanguageString
                                            props={"drop_files_here_to_upload"}
                                          /> // Default message
                                        )}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )
                            ) : (
                              LoadingSpinner()
                            )}
                          </div>
                        )}
                      </TabPane>
                    );
                  })}
                </TabContent>
              </div>
            </Col>
          </Row>

          {error && (
            <Alert color="danger" style={{ marginTop: "20px" }}>
              {error}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Documents);
