import React from "react";
import SelectMemberUnit from "../member/SelectMemberUnit";

const SelectUnitRequestMoveMember = ({
  values,
  setValues,
  unitId,
  showExcludeUnit,
}) => {
  return (
    <div>
      <SelectMemberUnit
        loggedInUnitId={unitId}
        unitId={unitId}
        setValues={setValues}
        values={values}
        showExcludeUnit={showExcludeUnit}
      />
    </div>
  );
};

export default SelectUnitRequestMoveMember;
