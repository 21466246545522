import React, { useState } from "react";
import {
//   ButtonGroup,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SelectUnit from "../../../shared/SelectUnit";
import { ApiUnit } from "../../../unit/ApiUnit";
import { GetLanguageString } from "../../../helper/Components";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import FundFilter from "../../../helper/SearchFilter/FundFilter";
import { convertToDateString } from "../../../../constants/const";
// import { DateValidater } from "../../helper/Method";
// import { convertToDateString } from "../../../constants/const";

const SearchArrivalsDepartures = ({
  memberStatusChangeValues,
  setMemberStatusChangeValues,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(memberStatusChangeValues);
  const [excludeExUnit, setExcludeExUnit] = useState(true);

  if (values.unit.value === 0) {
    ApiUnit()
      .getUnit(loggedInUnitId, loggedInUnitId)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setValues({
          ...values,
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSearch = () => {
    setSearchButton(true);

    if (values.startDate && values.endDate) {
        setMemberStatusChangeValues({
          ...values,
          startDate: convertToDateString(values.startDate),
          endDate: convertToDateString(values.endDate),
        });
        setSearchButton(true);
      }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div className="ibox-content">
        <FundFilter values={values} setValues={setValues} showFullDate={true} />
      <SelectUnit
        values={values}
        setValues={setValues}
        unitId={values.unit.value}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-center m-t-sm">
          <Button color="primary" type="submit" onClick={handleSearch}>
            {<GetLanguageString props="funds_submit" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchArrivalsDepartures;
