import React from "react";
import { Switch, Route } from "react-router-dom";

import DashBoard from "../dashboard/Dashboard";
import Configuration from "../config/Configuration";
import Members from "../member/Members";
import Unit from "../unit/Unit";
import Users from "../users/Users";
import EditMember from "../member/EditMember";
import Courses from "../courses/Courses";
import AddCourse from "../courses/AddCourse";
import EditCourse from "../courses/EditCourse";
import CourseParticipants from "../courses/CourseParticipants";
import Events from "../events/Events";
import AddEvent from "../events/AddEvent";
import EditEvent from "../events/EditEvent";
import EventParticipants from "../events/EventParticipants";
import MoveUnitMembers from "../unit/MoveUnitMember";
import ReportList from "../member/report/ReportList";
import EventReport from "../events/report/EventReport";
import CourseReport from "../courses/report/CourseReport";
import CourseParticipantsReport from "../courses/report/CourseParticipants";
import MemberDetails from "../member/MemberDetails/MemberDetails";
import Funds from "../funds/Funds";
import FundsReport from "../funds/report/FundReportList";
import UnitMonthlyReport from "../unit/report/MonthlyReport";
import EventDetail from "../dashboard/EventDetail";
import SMSReport from "../SMSMessage/SMSReport";
import { encryptStorage } from "../../constants/EncryptStorage";
import AddBulkMembers from "../member/AddBulkParticipants";
import SelectiveEventParticipants from "../events/SelectiveEventParticipants";
import EventResponsibleParticipant from "../events/EventResponsibleParticipant";
import EventInChargeAndResponsibleParticipants from "../events/EventInChargeAndResponsibleParticipants";
import CummulativeReport from "../unit/report/CummulativeReport";
import Books from "../books/Books";
import MembersMeeting from "../membersMeeting/MembersMeeting";
import CreateReports from "../unit/report/CreateReports/UsraMonthlyReport/CreateReports";
import UpdateMember from "../member/UpdateMember/UpdateMember";
import Notifications from "../notification/Notifications";
import QuickReports from "../reports/QuickReports";
import UnitMemberReport from "../reports/members/UnitMemberReport";
import MemberRequests from "../notification/MemberRequests";
import MemberList from "../member/MemberList";
import UnitMemberCountReport from "../reports/members/MembersCount";
import UserPermissions from "../member/UserPermissions";
import MemberStatusChangeReport from "../reports/members/MemberStatusChange";
import AhbabFromContactFormReport from "../ahbab/ContactFormAhbab/report/AhbabFromContactFormReport";
import SearchTutorials from "../tutorials/SearchTutorials";
import EditTutorial from "../tutorials/EditTutorial";
import UnitOfficialsReport from "../reports/members/UnitOfficialsReport";
import CourseAttendees from "../reports/courses/CourseAttendees";
import VacantResponsibilitiesReport from "../reports/responsibilities/VacantResponsibilitiesReport";
import MemberMudarisDetailsFromList from "../member/MemberMudaris/MemberMudarisDetailsFromList";
import MemberMudriseen from "../member/MemberMudaris/MemberMudriseen";
import MemberMudariseenReport from "../reports/mudariseen/MemberMudariseenReport";
import EventWiseEventAttendance from "../events/report/EventWiseEventAttendance";
import QuickEventReports from "../events/report/QuickEventReports";
import UnitWiseEventAttendance from "../events/report/UnitWiseEventAttendance";
import EventReportWithNoAttendance from "../events/report/EventReportWithNoAttendance";
import Education from "../config/education/Education";
import AddHabib from "../ahbab/ContactFormAhbab/AddHabib";
import EditHabib from "../ahbab/ContactFormAhbab/EditHabib";
import AhbabFromContactForm from "../ahbab/ContactFormAhbab/AhbabFromContactForm";
import Ahbab from "../ahbab/Ahbab";
import InfaqQuickLink from "../reports/infaq/InfaqQuickLink";
import UnitWiseInfaqReport from "../reports/infaq/unitWiseReport/UnitWiseInfaqReport";
import AhbabReport from "../ahbab/report/AhbabReport";
import EventAttendance from "../events/EventAttendance";
import MemberDocs from "../member/report/AttachedDocsReport/MemberDocs";
import AddBulkFund from "../funds/AddBulkFund";
import Documents from "../documents/Documents";
import RafiqWiseEventAttendance from "../events/report/RafiqWiseEventAttendance";
import AddLetterForCounselling from "../member/MemberLetterForCounselling/AddLetterForCounselling";
import EditLetterForCounselling from "../member/MemberLetterForCounselling/EditLetterForCounselling";
import ReappointmentReport from "../reports/responsibilities/ReappointmentReport";
import UnitJaizaReport from "../reports/members/UnitJaizaReport";
import ArrivalsDeparturesReport from "../reports/members/arrival-departure/ArrivalsDepartures";

export default function SwitchRouter() {
  const isIncharge = encryptStorage.getItem("canManage");
  return (
    <Switch>
      <Route exact path="/">
        <DashBoard />
      </Route>
      <Route exact path="/personalInfo">
        <MemberDetails />
      </Route>
      <Route exact path="/notifications">
        <Notifications />
      </Route>
      <Route exact path="/memberRequests">
        <MemberRequests />
      </Route>
      {isIncharge ? (
        <>
          <Route exact path="/ahbab/">
            <Ahbab />
          </Route>
          <Route exact path="/ahbab/report">
            <AhbabReport />
          </Route>
          <Route exact path="/ahbabFromContactForm/report">
            <AhbabFromContactFormReport />
          </Route>
          <Route exact path="/ahbabFromContactForm/">
            <AhbabFromContactForm />
          </Route>
          <Route exact path="/ahbabFromContactForm/addHabib">
            <AddHabib />
          </Route>
          <Route exact path="/ahbabFromContactForm/editHabib">
            <EditHabib />
          </Route>
          <Route exact path="/documents">
            <Documents />
          </Route>
          <Route exact path="/message/sms">
            <SMSReport />
          </Route>
          <Route exact path="/eventdetail">
            <EventDetail />
          </Route>
          <Route exact path="/infaqreport">
            <InfaqQuickLink />
          </Route>
          <Route exact path="/infaqreport/unitwise">
            <UnitWiseInfaqReport />
          </Route>
          <Route exact path="/fundsmanagement/report/infaq">
            <FundsReport />
          </Route>
          <Route exact path="/fundsmanagement">
            <Funds />
          </Route>
          <Route exact path="/fundsmanagement/addbulk">
            <AddBulkFund />
          </Route>
          <Route exact path="/eventReports/report">
            <EventReport />
          </Route>
          <Route exact path="/event/eventparticipant">
            <EventParticipants />
          </Route>
          <Route exact path="/event/eventAttendance">
            <EventAttendance />
          </Route>
          <Route exact path="/event/selectiveEventParticipant">
            <SelectiveEventParticipants />
          </Route>
          <Route exact path="/event/responsibleEventParticipants">
            <EventResponsibleParticipant />
          </Route>
          <Route exact path="/event/inChargeAndResponsibleEventParticipants">
            <EventInChargeAndResponsibleParticipants />
          </Route>
          <Route exact path="/event/editevent/:id">
            <EditEvent />
          </Route>
          <Route exact path="/event/addevent">
            <AddEvent />
          </Route>
          <Route exact path="/event">
            <Events />
          </Route>
          <Route exact path="/course/report/courseparticipants">
            <CourseParticipantsReport />
          </Route>
          <Route exact path="/course/report">
            <CourseReport />
          </Route>
          <Route exact path="/course/courseparticipant">
            <CourseParticipants />
          </Route>
          <Route exact path="/course/addcourse">
            <AddCourse />
          </Route>
          <Route exact path="/course/editcourse/:id">
            <EditCourse />
          </Route>
          <Route exact path="/course">
            <Courses />
          </Route>
          <Route exact path="/member/report">
            <ReportList />
          </Route>
          <Route exact path="/member/membersdetails/:id">
            <MemberDetails />
          </Route>
          <Route exact path="/member/editmember/:id">
            <EditMember />
          </Route>
          <Route exact path="/course/addbulkparticipant">
            <AddBulkMembers />
          </Route>
          <Route exact path="/member/updateMember/:id">
            <UpdateMember />
          </Route>
          <Route exact path="/member/updateMember/letterForCounselling/:id">
            <AddLetterForCounselling />
          </Route>
          <Route exact path="/member/updateMember/editLetterForCounselling/:id">
            <EditLetterForCounselling />
          </Route>
          <Route exact path="/member">
            <Members />
          </Route>
          <Route exact path="/memberList">
            <MemberList />
          </Route>
          <Route exact path="/member/mudaris/:id">
            <MemberMudarisDetailsFromList />
          </Route>
          <Route exact path="/unit/monthlyreport">
            <UnitMonthlyReport />
          </Route>
          <Route exact path="/unit/cummulativereport">
            <CummulativeReport />
          </Route>
          <Route exact path="/unit/movemembers">
            <MoveUnitMembers />
          </Route>
          <Route exact path="/unit">
            <Unit />
          </Route>
          <Route exact path="/updateBooks">
            <Books />
          </Route>
          <Route exact path="/meetingMembers">
            <MembersMeeting />
          </Route>
          <Route exact path="/unit/createReports">
            <CreateReports />
          </Route>
          <Route exact path="/mudariseen">
            <MemberMudriseen />
          </Route>
          <Route exact path="/reports">
            <QuickReports />
          </Route>
          <Route exact path="/reports/unitMember">
            <UnitMemberReport />
          </Route>
          <Route exact path="/reports/unitMemberCount">
            <UnitMemberCountReport />
          </Route>
          <Route exact path="/reports/unitJaiza">
            <UnitJaizaReport />
          </Route>
          <Route exact path="/reports/arrivalsDepartures">
            <ArrivalsDeparturesReport />
          </Route>
          <Route exact path="/reports/vacantResponsibilitiesReport">
            <VacantResponsibilitiesReport />
          </Route>
          <Route exact path="/reports/mudariseen">
            <MemberMudariseenReport />
          </Route>
          <Route exact path="/reports/attachedDocumentsReport">
            <MemberDocs />
          </Route>
          <Route exact path="/reports/reappointmentMuqamiAmeer">
            <ReappointmentReport />
          </Route>
          <Route exact path="/reports/memberStatusChange">
            <MemberStatusChangeReport />
          </Route>
          <Route exact path="/reports/unitOfficials">
            <UnitOfficialsReport />
          </Route>
          <Route exact path="/reports/courseAttendees">
            <CourseAttendees />
          </Route>
          <Route exact path="/eventReports">
            <QuickEventReports />
          </Route>
          <Route exact path="/eventReports/rafiqWiseIjtimaAttendance">
            <RafiqWiseEventAttendance />
          </Route>
          <Route exact path="/eventReports/eventWiseIjtimaAttendance">
            <EventWiseEventAttendance />
          </Route>
          <Route exact path="/eventReports/unitWiseIjtimaAttendance">
            <UnitWiseEventAttendance />
          </Route>
          <Route exact path="/eventsReport/eventReportWithNoAttendance">
            <EventReportWithNoAttendance />
          </Route>
          <Route exact path="/config">
            <Configuration />
          </Route>
          <Route exact path="/users">
            <Users />
          </Route>
          <Route exact path="/userPermissions">
            <UserPermissions />
          </Route>
          <Route exact path="/videoTutorials">
            <SearchTutorials />
          </Route>
          <Route exact path="/update/:id">
            <EditTutorial />
          </Route>
          <Route exact path="/settingsEducations">
            <Education />
          </Route>
        </>
      ) : null}
    </Switch>
  );
}
