import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Button, Table } from "reactstrap";

import swal from "sweetalert";
import {
  LoadingSpinner,
  applyClassToCharacters
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import useSorting from "../../shared/useSorting";
import { ApiUnit } from "../../unit/ApiUnit";
import { ApiAhbab } from "../ApiAhbab";
import SearchAhbab from "../SearchAhbab";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  birthDate: "neutral",
  memberType: "neutral",
};

const AhbabReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [ahbab, setAhbab] = useState([]);
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
    unitTypeName: unitTypeName,
  });
  const [searchButton, setSearchButton] = useState(false);
  const [searchAhbabModel, setSearchAhbabModel] = useState({
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    activePage: 1,
    isSubUnits: true,
    sortModel: {},
  });
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          setSearchAhbabModel({
            unitId: temp.id,
            activePage: 1,
          });
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }
  }, [unit.unitId, loggedInUnitId]);

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isFullNameDescending: sortObject.name === "descending",
      isFullNameAscending: sortObject.name === "ascending",
      isBirthDateDescending: sortObject.birthDate === "descending",
      isBirthDateAscending: sortObject.birthDate === "ascending",
      isJoiningDateDescending: sortObject.joiningDate === "descending",
      isJoiningDateAscending: sortObject.joiningDate === "ascending",
      isMemberTypeAscending: sortObject.memberType === "ascending",
      isMemberTypeDescending: sortObject.memberType === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;
    if (searchButton) {
      ApiAhbab()
        .GetAhbab(searchAhbabModel)
        .then((res) => {
          setAhbab(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  }, [searchAhbabModel, sortObject, searchButton]);

  const handlePageChange = (pageNumber) => {
    setSearchAhbabModel({
      ...searchAhbabModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  return (
    <div>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="ahbab_search" />}</h5>
          </div>
          <div className="ibox-content">
            <SearchAhbab
              unitId={unit.unitId}
              setUnit={setUnit}
              searchAhbabModel={searchAhbabModel}
              setSearchAhbabModel={setSearchAhbabModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox ">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="ahbab_details" />}</h5>
            </div>
            <div className="ibox-content">
              {!loading ? (
                ahbab.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("name")}
                          >
                            <GetLanguageString props="common_name" />
                            <GetSortingUI sortObject={sortObject} name="name" />
                          </th>
                          <th>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th>
                            {
                              <GetLanguageString props="ahbab_associated_member" />
                            }
                          </th>
                          <th>
                            {<GetLanguageString props="member_address" />}
                          </th>
                          <th>{<GetLanguageString props="member_phone" />}</th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ahbab?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td className="p-t-custom">
                                {index + recordIndex}
                              </td>
                              <td className="p-t-custom">{record.regNo}</td>
                              <td>
                                {record.fullName
                                  ? applyClassToCharacters(
                                      record.fullName,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>{" "}
                              <td>
                                {record.fatherName
                                  ? applyClassToCharacters(
                                      record.fatherName,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {record.associatedMember
                                  ? applyClassToCharacters(
                                      record.associatedMember,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>{" "}
                              <td>
                                {record.currentAddress
                                  ? applyClassToCharacters(
                                      record.currentAddress,
                                      "ur p-t-custom"
                                    )
                                  : "-"}
                              </td>
                              <td className="p-t-custom p-t-custom">
                                {record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))}
                              </td>
                              <td>
                                {
                                  <Button
                                    size="xs"
                                    color="info"
                                    className="label label-info m-r-xs"
                                  >
                                    <h5 className="ur">{record.unitName}</h5>
                                  </Button>
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="11">
                            <ul className={GetCustomStyles().page_style}>
                              <Pagination
                                activePage={pagination.activePage}
                                itemsCountPerPage={pagination.pageSize}
                                totalItemsCount={pagination.totalItem}
                                pageRangeDisplayed={pagination.pageRange}
                                onChange={handlePageChange}
                              />
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AhbabReport);
