import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  DateOfBirthValidater,
  LoadingSpinner,
  convertToDateString,
  customOption,
  isUrdu,
} from "../../../constants/const";
import { ApiEducation } from "../../config/education/ApiEducation";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember, setupApi } from "../../member/ApiMember";
import "./styles.css";

const AhbabForm = ({ history }) => {
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();
  const [educationDegreeList, setEductationDegreeList] = useState();
  const [educationDegreeUpdated, setEductationDegreeUpdated] = useState(false);
  const [educationFieldsList, setEductationFieldsList] = useState();
  const [occupationList, setOccupationList] = useState();

  const selectedLanguage = GetSelectedLanguage();

  const [formValues, setFormValues] = useState({
    habibName: "",
    birthDate: "",
    educationDegree: {
      value: 0,
      label: "",
    },
    educationField: {
      value: 0,
      label: "",
    },
    educationInstitution: "",
    address: "",
    country: "",
    state: "",
    city: "",
    contactPhone: "",
    contactEmail: "",
    contactMobileNumber: "",
    occupation: {
      value: 0,
      label: "",
    },
    occupationDescription: "",
    influncedPersonalities: "",
    otherParticular: "",
    gender: "m",
    unit: { value: 1, label: "" },
  });

  const [errors, setErrors] = useState(formValues);

  const validate = () => {
    let temp = {};
    temp.habibName =
      formValues.habibName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_first_name_error_message" />
      );
    temp.birthDate =
      selectedDateOfBirth !== "" && selectedDateOfBirth !== null ? (
        DateOfBirthValidater(selectedDateOfBirth) ? (
          ""
        ) : (
          <GetLanguageString props="member_birth_date_less_error_message" />
        )
      ) : (
        <GetLanguageString props="member_birth_date_error_message" />
      );
    temp.contactMobileNumber =
      formValues.contactMobileNumber !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_phone_error_message" />
      );
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    temp.contactEmail =
      formValues.contactEmail !== "" ? (
        !emailRegex.test(formValues.contactEmail) ? (
          <GetLanguageString props="member_email_validate_error_message" />
        ) : (
          ""
        )
      ) : (
        <GetLanguageString props="member_email_error_message" />
      );
    temp.address =
      formValues.address !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member__address_error_message" />
      );

    temp.country =
      Object.keys(formValues.country).length === 0 ? (
        <GetLanguageString props="member_member_details_country_error_message" />
      ) : (
        ""
      );

    temp.state =
      Object.keys(formValues.state).length === 0 && states.length !== 0 ? (
        <GetLanguageString props="member_member_details_state_error_message" />
      ) : (
        ""
      );

    temp.city =
      Object.keys(formValues.city).length === 0 && cities.length !== 0 ? (
        <GetLanguageString props="member_member_details_city_error_message" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    setupApi()
      .GetCountries()
      .then((res) => {
        setCountries(res.data.countries);
        setSelectedCountryId(res.data.selectedCountry.value);
        setFormValues({
          ...formValues,
          country: res.data.selectedCountry,
        });
        if (formValues.state === "" || formValues.city === "") {
          setStates([]);
          setCities([]);
          setSelectedStateId(0);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));

    setLoading(true);
    setButtonDisable(true);
    ApiEducation()
      .getEducationTypesForDropDown()
      .then((res) => {
        setEductationDegreeList(res.data);
        setFormValues({
          ...formValues,
          educationField: "",
        });
        setButtonDisable(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    setLoading(true);
    setButtonDisable(true);
    setupApi()
      .getOccupations()
      .then((res) => {
        setOccupationList(res.data);
        setButtonDisable(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupApi()
      .GetStates(selectedCountryId)
      .then((res) => {
        setStates(res.data);
        if (res.data.length === 0) {
          setCities([]);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedCountryId]);

  useEffect(() => {
    if (formValues.educationDegree.value !== 0) {
      ApiEducation()
        .getEducationFieldsDropDown(formValues.educationDegree.value)
        .then((res) => {
          setEductationFieldsList(res.data);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationDegreeUpdated]);

  useEffect(() => {
    setupApi()
      .GetCities(selectedStateId)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedStateId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleOnChangeCountry = (country) => {
    setFormValues({
      ...formValues,
      country: country,
      state: "",
      city: "",
    });
    setCities([]);
    setSelectedStateId(0);
    setSelectedCountryId(country.value);
  };

  const handleOnChangeCity = (value) => {
    setFormValues({
      ...formValues,
      city: value,
    });
  };
  const handleOnChangeState = (state) => {
    setFormValues({
      ...formValues,
      state: state,
      city: "",
    });
    setSelectedStateId(state.value);
  };

  const handleOnChangeOccupation = (value) => {
    setFormValues({
      ...formValues,
      occupation: value,
    });
  };

  const handleOnChangeEducation = (value) => {
    setFormValues({
      ...formValues,
      educationDegree: value,
      educationField: "",
    });
    setEductationDegreeUpdated(!educationDegreeUpdated);
  };

  const handleOnChangeEducationField = (value) => {
    setFormValues({
      ...formValues,
      educationField: value,
    });
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDateOfBirth(value);
        setErrors({
          ...errors,
          birthDate: "",
        });
      } else {
        setErrors({
          ...errors,
          birthDate: "The Birth date must not be greater than current date.",
        });
      }
    } else {
      setFormValues({
        ...formValues,
        birthDate: null,
      });
      setSelectedDateOfBirth(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      formValues.birthDate = convertToDateString(selectedDateOfBirth);
      addMember()
        .AddHabibFromContactUsForm(formValues)
        .then((res) => {
          swal({
            title: res.data,
            icon: "success",
            buttons: "OK",
          }).then((done) => {
            if (done) window.location.reload();
          });
          setButtonDisable(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setButtonDisable(false);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (document.getElementById("ahbab-form")) {
      document.body.classList.add("form-body");
    }
  }, []);

  return (
    <div id="ahbab-form" className="form-body">
      <Row>
        <Col className="wrapper-form">
          <Form onSubmit={handleSubmit}>
            {!loading ? (
              <>
                <div className="title">
                  <h1> {<GetLanguageString props="member_contact" />}</h1>
                </div>
                <div className="input-fields">
                  <Row>
                    <Col md="6">
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="sidebar_personal_information" />
                            }
                          </h4>
                        </Label>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Row>
                              <Label className="col-form-label">
                                Name{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(نام)"}
                                  <span className="text-error">
                                    &nbsp;&nbsp;&nbsp;*
                                  </span>
                                </span>
                              </Label>
                            </Row>
                            <Input
                              className={
                                isUrdu(formValues.habibName) ? "ur" : ""
                              }
                              type="text"
                              name="habibName"
                              value={formValues.habibName}
                              onChange={handleInputChange}
                              {...(errors?.habibName && { invalid: true })}
                            />
                            {errors.habibName && (
                              <FormFeedback>
                                <h4>{errors.habibName}</h4>
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label className="col-form-label">
                              Date of Birth{" "}
                              <span
                                className="ur"
                                style={{ fontSize: 15, fontWeight: "bold" }}
                              >
                                {"(تاریخ پیدائش)"}{" "}
                                <span className="text-error">
                                  &nbsp;&nbsp;&nbsp;*
                                </span>
                              </span>
                            </Label>
                            <DatePicker
                              placeholderText="dd/mm/yyyy"
                              className="form-control"
                              selected={selectedDateOfBirth}
                              maxDate={new Date()}
                              showYearDropdown
                              scrollableYearDropdown
                              onChange={handleInputChangeBirthDate}
                              dateFormat="dd/MM/yyyy"
                            />
                            {errors.birthDate && (
                              <h4 className="text-error">{errors.birthDate}</h4>
                            )}
                          </FormGroup>
                        </Col>
                        <Row>
                          <FormGroup>
                            <Label className="col-form-label">
                              {<GetLanguageString props="settings_gender" />}
                            </Label>
                            <Row>
                              <Col>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="gender"
                                      value="m"
                                      checked={formValues.gender === "m"}
                                      onChange={handleInputChange}
                                    />{" "}
                                    {
                                      <GetLanguageString props="settings_gender_male" />
                                    }
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="gender"
                                      value="f"
                                      checked={formValues.gender === "f"}
                                      onChange={handleInputChange}
                                    />{" "}
                                    {
                                      <GetLanguageString props="settings_gender_female" />
                                    }
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Row>
                      </Row>{" "}
                      <hr style={{ marginTop: 19 }}></hr>
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="member_contact_details" />
                            }
                          </h4>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  Email{" "}
                                  <span
                                    className="ur"
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {"(ای میل)"}
                                    <span className="text-error">
                                      &nbsp;&nbsp;&nbsp;*
                                    </span>
                                  </span>
                                </Label>
                                <Input
                                  type="text"
                                  name="contactEmail"
                                  value={formValues.contactEmail}
                                  onChange={handleInputChange}
                                  {...(errors?.contactEmail && {
                                    invalid: true,
                                  })}
                                />
                                {errors.contactEmail && (
                                  <FormFeedback>
                                    <h4>{errors.contactEmail}</h4>
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  Mobile Number{" "}
                                  <span
                                    className="ur"
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {"(موبائل نمبر )"}
                                    <span className="text-error">
                                      &nbsp;&nbsp;&nbsp;*
                                    </span>
                                  </span>
                                </Label>
                                <Input
                                  type="text"
                                  name="contactMobileNumber"
                                  value={formValues.contactMobileNumber}
                                  onChange={handleInputChange}
                                  {...(errors?.contactMobileNumber && {
                                    invalid: true,
                                  })}
                                />
                                {errors.contactMobileNumber && (
                                  <FormFeedback>
                                    <h4>{errors.contactMobileNumber}</h4>
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  Landline{" "}
                                  <span
                                    className="ur"
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {"(لینڈ لائن)"}
                                  </span>
                                </Label>
                                <Input
                                  type="text"
                                  name="contactPhone"
                                  value={formValues.contactPhone}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Label>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="member_address_details" />
                            }
                          </h4>
                        </Label>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label className="col-form-label">
                              Address{" "}
                              <span
                                className="ur"
                                style={{ fontSize: 15, fontWeight: "bold" }}
                              >
                                {"(پتہ )"}
                                <span className="text-error">
                                  &nbsp;&nbsp;&nbsp;*
                                </span>
                              </span>
                            </Label>
                            <Input
                              className={isUrdu(formValues.address) ? "ur" : ""}
                              type="text"
                              name="address"
                              value={formValues.address}
                              onChange={handleInputChange}
                              {...(errors?.address && { invalid: true })}
                            />
                            {errors.address && (
                              <FormFeedback>
                                <h4>{errors.address}</h4>
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label className="col-form-label">
                              Country{" "}
                              <span
                                className="ur"
                                style={{ fontSize: 15, fontWeight: "bold" }}
                              >
                                {"(ملک )"}
                                <span className="text-error">
                                  &nbsp;&nbsp;&nbsp;*
                                </span>
                              </span>
                            </Label>
                            <Select
                              options={countries}
                              value={formValues.country}
                              menuPlacement="top"
                              className="basic-single"
                              placeholder={
                                selectedLanguage === "en"
                                  ? "Select"
                                  : "منتخب کریں"
                              }
                              classNamePrefix="select"
                              onChange={(country) => {
                                handleOnChangeCountry(country);
                                setErrors({
                                  ...errors,
                                  country: "",
                                });
                              }}
                            />
                            {errors.country && (
                              <h4 className="text-error">{errors.country}</h4>
                            )}
                          </FormGroup>
                        </Col>
                        {states.length === 0 ? null : (
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                State/Province/Territory{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(صوبہ )"}
                                  {states.length === 0 ? (
                                    ""
                                  ) : (
                                    <span className="text-error">
                                      &nbsp;&nbsp;&nbsp;*
                                    </span>
                                  )}
                                </span>
                              </Label>
                              <Select
                                options={states}
                                value={formValues.state}
                                className="basic-single"
                                menuPlacement="top"
                                placeholder={
                                  states.length === 0
                                    ? selectedLanguage === "en"
                                      ? "No States Available"
                                      : "کوئی صوبہ موجود نہی ہے"
                                    : selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(state) => {
                                  handleOnChangeState(state);
                                  setErrors({
                                    ...errors,
                                    state: "",
                                  });
                                }}
                              />
                              {errors.state && (
                                <h4 className="text-error">{errors.state}</h4>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                        {cities.length === 0 ? null : (
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                City{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(شہر )"}
                                  {cities.length === 0 ? (
                                    ""
                                  ) : (
                                    <span className="text-error">
                                      &nbsp;&nbsp;&nbsp;*
                                    </span>
                                  )}
                                </span>
                              </Label>
                              <Select
                                options={cities}
                                value={formValues.city}
                                className="basic-single"
                                menuPlacement="top"
                                placeholder={
                                  cities.length === 0
                                    ? selectedLanguage === "en"
                                      ? "No Cities Available"
                                      : "کوئی شہر موجود نہی ہے"
                                    : selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(city) => {
                                  handleOnChangeCity(city);
                                  setErrors({
                                    ...errors,
                                    city: "",
                                  });
                                }}
                              />
                              {errors.city && (
                                <h4 className="text-error">{errors.city}</h4>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="member_education_details" />
                            }
                          </h4>
                        </Label>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                Degree{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(ڈگری )"}
                                </span>
                              </Label>
                              <Select
                                options={educationDegreeList}
                                value={formValues.educationDegree}
                                components={{ Option: customOption }}
                                className={
                                  isUrdu(formValues.educationDegree.label)
                                    ? "basic-single ur"
                                    : "basic-single"
                                }
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select Education"
                                    : "منتخب کریں"
                                }
                                // classNamePrefix="select"
                                onChange={(type) => {
                                  handleOnChangeEducation(type);
                                  setErrors({
                                    ...errors,
                                    educationDegree: "",
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>{" "}
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                Field{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(فیلڈ )"}
                                </span>
                              </Label>
                              <Select
                                options={educationFieldsList}
                                value={formValues.educationField}
                                components={{ Option: customOption }}
                                className={
                                  isUrdu(formValues.educationField.label)
                                    ? "basic-single ur"
                                    : "basic-single"
                                }
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select Education Field"
                                    : "منتخب کریں"
                                }
                                // classNamePrefix="select"
                                onChange={(type) => {
                                  handleOnChangeEducationField(type);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                Institution{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(ادارہ)"}
                                </span>
                              </Label>
                              <Input
                                className={
                                  isUrdu(formValues.educationInstitution)
                                    ? "ur"
                                    : ""
                                }
                                type="text"
                                name="educationInstitution"
                                value={formValues.educationInstitution}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="member_occupation_details" />
                            }
                          </h4>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  Occupation{" "}
                                  <span
                                    className="ur"
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {"(پیشہ)"}
                                  </span>
                                </Label>
                                <Select
                                  options={occupationList}
                                  value={formValues.occupation}
                                  components={{ Option: customOption }}
                                  className={
                                    isUrdu(formValues.occupation.label)
                                      ? "basic-single ur"
                                      : "basic-single"
                                  }
                                  classNamePrefix="select"
                                  placeholder={
                                    selectedLanguage === "en"
                                      ? "Select Occupation"
                                      : "منتخب کریں"
                                  }
                                  onChange={(type) => {
                                    handleOnChangeOccupation(type);
                                    setErrors({
                                      ...errors,
                                      occupation: "",
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  Description{" "}
                                  <span
                                    className="ur"
                                    style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    {"(تفصیل)"}
                                  </span>
                                </Label>
                                <Input
                                  type="text"
                                  name="occupationDescription"
                                  className={
                                    isUrdu(formValues.occupationDescription)
                                      ? "ur"
                                      : ""
                                  }
                                  value={formValues.occupationDescription}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Label>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Label className="col-form-label">
                          <h4>
                            {
                              <GetLanguageString props="ahbab_form_other_information" />
                            }
                          </h4>
                          <Row>
                            <FormGroup>
                              <Label className="col-form-label">
                                Who were the personalities who influenced you
                                and why?
                                {"  "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(کن شخصیات سے آپ متاثر ہوئے اور کیوں؟)"}
                                </span>
                              </Label>
                              <Input
                                type="textarea"
                                name="influncedPersonalities"
                                className={
                                  isUrdu(formValues.influncedPersonalities)
                                    ? "ur"
                                    : ""
                                }
                                value={formValues.influncedPersonalities}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Row>
                          <Row>
                            <FormGroup>
                              <Label className="col-form-label">
                                Any other particular you want to mention?{" "}
                                <span
                                  className="ur"
                                  style={{ fontSize: 15, fontWeight: "bold" }}
                                >
                                  {"(دیگر امور جن کا آپ ذکر کرنا چاہیں؟)"}
                                </span>
                              </Label>
                              <Input
                                type="textarea"
                                name="otherParticular"
                                className={
                                  isUrdu(formValues.otherParticular) ? "ur" : ""
                                }
                                value={formValues.otherParticular}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Row>
                        </Label>
                      </Row>
                    </Col>{" "}
                    <hr></hr>
                  </Row>
                </div>
              </>
            ) : (
              LoadingSpinner()
            )}
            <Row>
              <Col md="8"></Col>

              <Col md="4">
                <div className={GetCustomStyles().btn_style}>
                  <Button
                    color="primary"
                    disabled={buttonDisable}
                    className="m-l-sm"
                    type="submit"
                  >
                    {<GetLanguageString props="Submit" />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(AhbabForm);
