import React, { useState, useEffect } from "react";
import {
  Label,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { GetLanguageString } from "../../helper/Components";
import { DateValidater, SelectOnlyDate } from "../../helper/Method";

const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };

const DateFilterUI = ({ values, setValues, checkDisabled, showFullDate = false }) => {
  
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);

  useEffect(() => {
    if(checkDisabled){
        setDateValidations(dateRangeValidation);
    }
  },[checkDisabled])
  
  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, values.endDate)) {
      setValues({
        ...values,
        startDate:SelectOnlyDate(value),
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(values.startDate, value)) {
      setValues({
        ...values,
        endDate: SelectOnlyDate(value),
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  return (
    
    <Row>
    <Col md="6">
      <FormGroup>
        <Label className="col-form-label">
          {<GetLanguageString props="courses_start_date" />}
        </Label>
        <DatePicker
          disabled={checkDisabled}
          showMonthYearPicker={!showFullDate}
          showYearDropdown={showFullDate}
          openToDate={values.startDate}
          className="form-control"
          dateFormat = {showFullDate ? "dd/MM/yyyy" : "MMM yyyy"}
          selected={values.startDate}
          onChange={handleInputChangeStartDate}
        />
        {dateValidations.startDate && (
          <h4 className="text-error">{dateValidations.startDate}</h4>
        )}
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <Label className="col-form-label">
          {<GetLanguageString props="courses_end_date" />}
        </Label>
        <DatePicker
          disabled={checkDisabled}
          showMonthYearPicker={!showFullDate}
          showYearDropdown={showFullDate}
          className="form-control"
          dateFormat = {showFullDate ? "dd/MM/yyyy" : "MMM yyyy"}
          selected={values.endDate}
          onChange={handleInputChangeEndDate}
        />
        {dateValidations.endDate && (
          <h4 className="text-error">{dateValidations.endDate}</h4>
        )}
      </FormGroup>
    </Col>
  </Row>
  );
};

export default DateFilterUI;
