import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isUrdu,
  removeDuplicates,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiDocumentType } from "../config/documentType/ApiDocumentType";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiDocuments } from "./ApiDocuments";
import Permissions from "./PermissionsComponent";
import CreateableTags from "./CreateableTags";
import { toast } from "react-toastify";

const UploadFile = ({
  setUploadFileModal,
  folderId,
  documents,
  setDocuments,
  droppedFile,
  setDroppedFile,
  uploadRefreshDocuments,
  setUploadInProgress,
}) => {
  const toggle = () => {
    setUploadFileModal(false);
    setDroppedFile(null);
  };
  const [loading, setLoading] = useState(false);
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [filePermissions, setFilePermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const [isLink, setIsLink] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(
    droppedFile !== null ? droppedFile : ""
  );
  const [uploadFileValues, setUploadFileValues] = useState({
    uploadedFiles: droppedFile !== null ? droppedFile : "",
    description: "",
    documentType: "",
    permissionsApplied: filePermissions === 1,
    inheritPermissionsFromParentFolder: inheritFolderPermissions === 2,
    permissions: [],
    tags: [],
    isLink: false,
    fileName: "",
    fileLink: "",
  });

  if (folderId === 0) folderId = 1;

  const [permissions, setPermissions] = useState([
    {
      unit: { value: loggedInUnitId, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: true,
      selectResponsibilities: false,
      selectAllResponsibilities: false,
    },
  ]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };
  const handleFilePermissions = (e) => {
    const { value } = e.target;
    setFilePermissions(parseInt(value));
    setInheritFolderPermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      permissionsApplied: value === 1,
      inheritPermissionsFromParentFolder: false,
    });
  };
  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFilePermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      inheritPermissionsFromParentFolder: value === 2,
      permissionsApplied: false,
    });
  };

  const handleIsLink = (e) => {
    const { value } = e.target;
    let val = parseInt(value);
    setIsLink(val);
    setUploadFileValues({
      ...uploadFileValues,
      isLink: val === 2,
    });
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const memberName = encryptStorage.getItem("memberName");
  const unitName = encryptStorage.getItem("loggedInUnitName");
  const responsibiityName = encryptStorage.getItem("responsibilityName");

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .getDocumentTypesForDropDowns()
      .then((res) => {
        setDocumentsTypesDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
  }, []);

  const handleUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    const newFiles = [];
    const fileErrors = [];

    files.forEach((file) => {
      // Size check (25 MB in bytes)
      if (file.size > 26214400) {
        fileErrors.push(
          t("file_size_limit_exceed_error") + " ( " + file.name + " ) "
        );
      }
      // File type check (no executable files)
      const fileType = file.type || file.name.split(".").pop().toLowerCase();
      if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload"
      ) {
        fileErrors.push(t("file_type_error") + "( " + file.name + " ) ");
      }

      // Add valid files to the newFiles array
      if (
        file.size <= 26214400 &&
        !["exe", "msi", "application/x-msdownload"].includes(fileType)
      ) {
        newFiles.push(file);
      }
    });

    // Set error messages if any, otherwise clear errors
    setErrors({
      ...errors,
      uploadedFiles: fileErrors.length ? fileErrors.join(", ") : "",
    });

    // Only set the new files if there are no errors
    if (fileErrors.length === 0) {
      setUploadedFiles(newFiles);
    }
  };

  const validate = () => {
    let temp = {};

    if (!uploadFileValues.isLink) {
      const filesErrors = uploadedFiles.map((file, index) => {
        const fileType =
          file?.type || file?.name.split(".").pop().toLowerCase();

        // Check conditions for each file
        if (!uploadFileValues.isLink && !file) {
          return <GetLanguageString props="file_error" />;
        } else if (file.size > 26214400) {
          return <GetLanguageString props="file_size_limit_exceed_error" />;
        } else if (
          fileType === "exe" ||
          fileType === "msi" ||
          fileType === "application/x-msdownload"
        ) {
          return <GetLanguageString props="file_type_error" />;
        } else {
          return ""; // No error
        }
      });
      temp.uploadedFiles = filesErrors.some((error) => error !== "")
        ? filesErrors
        : "";
    }
    // Check if any file has an error
    if (uploadFileValues.isLink) {
      temp.fileName = uploadFileValues.fileName ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_name_error" />
      );
      temp.fileLink = uploadFileValues.fileLink ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_link_error" />
      );
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeDocumentType = (record) => {
    setUploadFileValues({
      ...uploadFileValues,
      documentType: record,
    });

    setErrors({
      ...errors,
      documentType: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadFileValues({
      ...uploadFileValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      setUploadInProgress(true);
      const toastId = toast.loading(t("document_upload_file_toast"), {
        position: toast.POSITION.TOP_CENTER,
        style: {
          backgroundColor: "#40576d",
          color: "#DFE4ED",
        },
      });
      const tempValues = [...documents];
      const date = new Date();
      if (uploadFileValues.isLink) {
        tempValues.push({
          name: uploadFileValues.fileName,
          fileType: "link",
          uploaderName: memberName,
          uploaderResponsibility: responsibiityName,
          uploaderUnit: unitName,
          description: uploadFileValues.description,
          size: 0,
          fileStatus: "link_added",
          createdDate: date,
        });
      } else {
        uploadedFiles.forEach((file, index) => {
          tempValues.push({
            name:
              uploadFileValues.isLink && uploadFileValues.fileName
                ? uploadFileValues.fileName
                : file.name,
            fileType: uploadFileValues.isLink ? "link" : file.type,
            uploaderName: memberName,
            uploaderResponsibility: responsibiityName,
            uploaderUnit: unitName,
            description: uploadFileValues.description,
            size: uploadFileValues.isLink ? 0 : file.size,
            fileStatus: uploadFileValues.isLink
              ? "link_added"
              : "file_in_progress",
            createdDate: date,
            addedIndex: index,
          });
        });
      }
      setDocuments(tempValues);

      const uploadPromises = uploadedFiles.map((file, index) => {
        const formData = new FormData();
        formData.append(
          "FileType",
          uploadFileValues.documentType === ""
            ? 0
            : uploadFileValues.documentType.value
        );
        formData.append(
          "Description",
          uploadFileValues.description === ""
            ? "^"
            : uploadFileValues.description
        );
        formData.append(
          "FileName",
          uploadFileValues.fileName === "" ? "^" : uploadFileValues.fileName
        );
        formData.append(
          "FileLink",
          uploadFileValues.fileLink === "" ? "^" : uploadFileValues.fileLink
        );
        formData.append("IsLink", uploadFileValues.isLink);
        formData.append(
          "Index",
          tempValues.findIndex((item) => item.addedIndex === index)
        );
        formData.append("ParentFolderId", folderId);
        formData.append("PermissionsApplied", filePermissions === 1);
        formData.append(
          "InheritPermissionsFromParentFolder",
          inheritFolderPermissions === 2
        );
        formData.append(
          "Permissions",
          JSON.stringify(removeDuplicates(permissions))
        );
        formData.append("file", file);
        formData.append("tags", JSON.stringify(selectedTags));

        toggle();

        return ApiDocuments()
          .addDocument(formData)
          .then((res) => {
            const tempValuesEdit = [...tempValues];
            tempValuesEdit[res.data.index].id = res.data.documentId;
            tempValuesEdit[res.data.index].fileUploadBackgroundJobId =
              res.data.jobId;
            tempValuesEdit[res.data.index].fileStatus = res.data.status;
            setDocuments(tempValuesEdit);
            toggle();
          })
          .catch((err) => {
            setLoading(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            toast.update(toastId, {
              render: "Error occurred",
              type: "error",
              isLoading: false,
              autoClose: 3000,
              style: {
                backgroundColor: "#ffffff",
                color: "grey",
              },
            });
            toggle();
            setUploadInProgress(false);
            console.log(err);
          });
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      // Update toast with success message
      toast.update(toastId, {
        render: `${uploadedFiles.length} ${t(
          "document_upload_file_toast_success"
        )}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        style: {
          backgroundColor: "#ffffff",
          color: "grey",
        },
      });

      // Call uploadRefreshDocuments after a 1-second delay
      setTimeout(() => {
        uploadRefreshDocuments();
      }, 1000);
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size={filePermissions ? "lg" : "m"}
          style={{
            maxWidth: filePermissions ? "980px" : "600px",
            width: "100%",
          }}
          isOpen
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="document_upload_file" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <>
                  <Row>
                    {" "}
                    <Row>
                      <Col md="6">
                        <Label check for={"uploadFile"}>
                          <Input
                            type="radio"
                            id="uploadFile"
                            value={1}
                            name="uploadFile"
                            checked={isLink === 1}
                            onChange={handleIsLink}
                          />
                          &nbsp;&nbsp;
                          {
                            <>
                              <GetLanguageString props="member_member_detail_upload_documents" />
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: 8,
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                *maximum file size 25 mb
                              </span>
                            </>
                          }
                        </Label>
                      </Col>
                      <Col md="6">
                        <Label check for={"isLink"}>
                          <Input
                            type="radio"
                            id="isLink"
                            value={2}
                            name="isLink"
                            checked={isLink === 2}
                            onChange={handleIsLink}
                          />
                          &nbsp;&nbsp;
                          {
                            <GetLanguageString
                              props={"document_upload_file_file_link_add"}
                            />
                          }
                        </Label>
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    {isLink === 1 ? (
                      <Col md={"12"}>
                        <FormGroup>
                          {droppedFile !== null ? (
                            <Input
                              type="text"
                              value={uploadedFiles.length + " Files"}
                              readOnly
                            />
                          ) : (
                            <Input
                              type="file"
                              multiple
                              name="uploadedFiles"
                              onChange={handleUpload}
                              {...(errors?.uploadedFiles && { invalid: true })}
                            />
                          )}
                          {errors?.uploadedFiles && (
                            <div className="text-error">
                              {errors.uploadedFiles}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md={filePermissions ? "6" : "12"}>
                          <FormGroup>
                            <Label>
                              {
                                <GetLanguageString props="document_upload_file_file_name" />
                              }
                            </Label>
                            <Input
                              type="text"
                              name="fileName"
                              className={
                                isUrdu(uploadFileValues.fileName)
                                  ? "ur no-drag"
                                  : "no-drag"
                              }
                              value={uploadFileValues.fileName}
                              onChange={handleInputChange}
                              {...(errors?.fileName && { invalid: true })}
                            />
                            {errors?.fileName && (
                              <div className="text-error">
                                {errors.fileName}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={filePermissions ? "6" : "12"}>
                          <FormGroup>
                            <Label>
                              {
                                <GetLanguageString props="document_upload_file_file_link" />
                              }
                            </Label>
                            <Input
                              type="text"
                              name="fileLink"
                              className={
                                isUrdu(uploadFileValues.fileLink)
                                  ? "ur no-drag"
                                  : "no-drag"
                              }
                              value={uploadFileValues.fileLink}
                              onChange={handleInputChange}
                              {...(errors?.fileLink && { invalid: true })}
                            />
                            {errors?.fileLink && (
                              <div className="text-error">
                                {errors.fileLink}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md={filePermissions ? "4" : "12"}>
                      <FormGroup>
                        <Label>
                          {" "}
                          {
                            <GetLanguageString props="member_member_detail_select_documents_type" />
                          }
                        </Label>
                        <Select
                          value={uploadFileValues.documentType}
                          options={documentsTypesDropdown}
                          className={
                            GetSelectedLanguage() === "ur"
                              ? "basic-multi-select ur no-drag"
                              : "basic-multi-select no-drag"
                          }
                          classNamePrefix="select"
                          onChange={(record) => {
                            handleChangeDocumentType(record);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "4" : "12"}>
                      <FormGroup>
                        <Label for="tag-selector">
                          {<GetLanguageString props="document_document_tags" />}
                        </Label>
                        <CreateableTags
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "4" : "12"}>
                      {" "}
                      <FormGroup>
                        <Label>
                          {<GetLanguageString props="common_description" />}
                        </Label>
                        <Input
                          type="text"
                          name="description"
                          className={
                            isUrdu(uploadFileValues.description)
                              ? "ur no-drag"
                              : "no-drag"
                          }
                          value={uploadFileValues.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label check for={"inheritFolderPermissions"}>
                        <Input
                          type="radio"
                          id="inheritFolderPermissions"
                          value={2}
                          name="inheritFolderPermissions"
                          checked={inheritFolderPermissions === 2}
                          onChange={handleInheritFolderPermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_inherit_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                    <Col md="6">
                      <Label check for={"filePermissions"}>
                        <Input
                          type="radio"
                          id="filePermissions"
                          value={1}
                          name="filePermissions"
                          checked={filePermissions === 1}
                          onChange={handleFilePermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_apply_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                  </Row>
                  {filePermissions === 1 && (
                    <>
                      <br></br>
                      <div className="ibox-content">
                        <Row>
                          <Col md="11"></Col>
                          <Col md="1" className="text-center">
                            <div>
                              <FaSquarePlus
                                color="green"
                                size={25}
                                style={{ cursor: "pointer" }}
                                onClick={addPermission}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {permissions?.map((item, index) => {
                            return (
                              <>
                                <div>
                                  <br></br>
                                  <Permissions
                                    item={item}
                                    index={index}
                                    permissions={permissions}
                                    setPermissions={setPermissions}
                                  />
                                  <br></br>
                                </div>
                                {permissions.length !== 1 && (
                                  <Row>
                                    <Col md="11"></Col>
                                    <Col md="1" className="text-center">
                                      <div>
                                        <FaSquareMinus
                                          color="red"
                                          size={25}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => removeObject(index)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                                <div>
                                  {index + 1 !== permissions.length && (
                                    <div class="divider div-transparent div-dot"></div>
                                  )}
                                  <br></br>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  )}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" disabled={buttonDisable} type="submit">
                {<GetLanguageString props="document_upload_file" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default UploadFile;
