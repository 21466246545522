import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { requestApi } from "../../../actions/api";
import { GetLanguageString } from "../../helper/Components";
import { DateValidater, GetSelectedLanguage } from "../../helper/Method";
import CourseAttendeesSelectUnit from "./CourseAttendeesSelectUnit";
import { convertToDateString } from "../../../constants/const";

const SearchCourseAttendees = ({
  searchCourseModel,
  setSearchCourseModel,
  unitId,
  setSearchButton,
}) => {
  const [errors, setErrors] = useState({
    courseTypeIds: [],
  });
  const [courseType, setCourseType] = useState([]);
  const [values, setValues] = useState(searchCourseModel);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [mainUnitId, setMainUnitId] = useState();
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const courseOptions = [];

  useEffect(() => {
    requestApi("coursetype/")
      .fetchAll()
      .then((res) => {
        setCourseType(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (values.unit.value === 0) setMainUnitId(unitId);
    else if (unitId !== values.unit.value) setMainUnitId(values.unit.value);
    else setMainUnitId(unitId);
  }, [unitId, values.unit.value]);

  courseType.forEach((d) => courseOptions.push({ value: d.id, label: d.name }));

  const handleChangeCourseType = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        courseTypeIds: ids,
      });
    } else {
      setValues({
        ...values,
        courseTypeIds: 0,
      });
    }
  };

  const validate = () => {
    let temp = {};
    temp.courseTypeIds =
      values.courseTypeIds.length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="courses_course_type_error_message" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, selectedDates.endDate)) {
      setSelectedDates({
        ...selectedDates,
        startDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(selectedDates.startDate, value)) {
      setSelectedDates({
        ...selectedDates,
        endDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };
  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    if (!v) {
      setValues({
        ...values,
        isSubUnits: v,
      });
    } else {
      setValues({
        ...values,
        isSubUnits: v,
      });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      if (values.unit.value === 0) values.unit = { value: unitId, label: "" };
      setSearchButton(true);

      setSearchCourseModel({
        ...values,
        startDate: convertToDateString(selectedDates.startDate),
        endDate: convertToDateString(selectedDates.endDate),
      });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <Row>
        <Col lg="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="memberName"
              value={values.memberName}
              onChange={(e) => {
                setValues({
                  ...values,
                  memberName: e.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className={
                GetSelectedLanguage() === "en"
                  ? "form-control"
                  : "form-control ur"
              }
              selected={selectedDates.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className={
                GetSelectedLanguage() === "en"
                  ? "form-control"
                  : "form-control ur"
              }
              selected={selectedDates.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_course_type" />}
            </Label>
            <Select
              options={courseOptions}
              className="basic-multi-select ur"
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              isMulti
              isClearable
              onChange={handleChangeCourseType}
            />
          </FormGroup>
          {errors.courseTypeIds && (
            <h4 className="text-error">{errors.courseTypeIds}</h4>
          )}
        </Col>
      </Row>
      <CourseAttendeesSelectUnit
        unitId={mainUnitId}
        setValues={setValues}
        values={values}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_view_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchCourseAttendees;
