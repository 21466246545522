import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isUrdu,
  removeDuplicates,
} from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiDocuments } from "./ApiDocuments";
import { ApiDocumentType } from "../config/documentType/ApiDocumentType";
import Permissions from "./PermissionsComponent";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import CreateableTags from "./CreateableTags";
import { encryptStorage } from "../../constants/EncryptStorage";

const EditFile = ({ setEditFileModal, parentFolderId, setRefresh, fileId }) => {
  const toggle = () => {
    setEditFileModal(false);
  };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filePermissions, setFilePermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const [errors, setErrors] = useState();
  const [uploadFileValues, setUploadFileValues] = useState({
    documentType: "",
    description: "",
    parentFolderId: parentFolderId,
    isLink: false,
    fileName: "",
    fileUrl: "",
  });

  const [permissions, setPermissions] = useState([]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: loggedInUnitId, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];

    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };

  const handleFilePermissions = (e) => {
    const { value } = e.target;
    setFilePermissions(parseInt(value));
    setInheritFolderPermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      permissionsApplied: value === 1,
      inheritPermissionsFromParent: false,
    });
  };
  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFilePermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      inheritPermissionsFromParent: value === 2,
      permissionsApplied: false,
    });
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleChangeDocumentType = (record) => {
    setUploadFileValues({
      ...uploadFileValues,
      documentType: record,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadFileValues({
      ...uploadFileValues,
      [name]: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .getDocumentTypesForDropDowns()
      .then((res) => {
        setDocumentsTypesDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
  }, []);

  const validate = () => {
    let temp = {};
    if (uploadFileValues.isLink) {
      temp.fileName = uploadFileValues.fileName ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_name_error" />
      );
      temp.fileUrl = uploadFileValues.fileUrl ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_link_error" />
      );
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    ApiDocuments()
      .editFile(fileId)
      .then((res) => {
        setUploadFileValues({
          id: res.data.id,
          description: res.data.description,
          documentType: res.data.documentType,
          parentFolderId: parentFolderId,
          driveFileId: res.data.driveFileId,
          tags: res.data.tags,
          isLink: res.data.isLink,
          fileName: res.data.fileName,
          fileUrl: res.data.fileUrl,
        });
        setSelectedTags(res.data.tags);
        setFilePermissions(res.data.permissionsApplied ? 1 : 0);
        setInheritFolderPermissions(
          res.data.inheritPermissionsFromParent ? 2 : 0
        );
        if (res.data.permissionsApplied === true) {
          setPermissions(res.data.permissions);
        } else {
          setPermissions([
            {
              unit: { value: 0, label: "" },
              forChildUnits: false,
              responsibilities: [],
              selectUnit: false,
              selectResponsibilities: false,
              selectAllResponsibilities: false,
            },
          ]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [parentFolderId, fileId]);

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      uploadFileValues.permissionsApplied = filePermissions === 1;
      uploadFileValues.inheritPermissionsFromParent =
        inheritFolderPermissions === 2;
      uploadFileValues.permissions = removeDuplicates(permissions);
      uploadFileValues.tags = selectedTags;
      ApiDocuments()
        .updateFile(uploadFileValues)
        .then((res) => {
          swal({
            title: t("document_edit_file_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          toggle();
          setRefresh((prevState) => !prevState);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal
        size={filePermissions ? "lg" : "m"}
        style={{
          maxWidth: filePermissions ? "1080px" : "600px",
          width: "100%",
        }}
        isOpen
        className="inmodal"
        autoFocus={false}
      >
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="document_edit_file" />}
        </ModalHeader>
        {!loading ? (
          <Form>
            <ModalBody>
              <Form>
                <Row>
                  {uploadFileValues.isLink ? (
                    <>
                      <Col md={filePermissions ? "4" : "12"}>
                        <FormGroup>
                          <Label>
                            {
                              <GetLanguageString props="document_upload_file_file_name" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="fileName"
                            className={
                              isUrdu(uploadFileValues.fileName)
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            value={uploadFileValues.fileName}
                            onChange={handleInputChange}
                            {...(errors?.fileName && { invalid: true })}
                          />
                          {errors?.fileName && (
                            <div className="text-error">{errors.fileName}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={filePermissions ? "4" : "12"}>
                        <FormGroup>
                          <Label>
                            {
                              <GetLanguageString props="document_upload_file_file_link" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="fileUrl"
                            className={
                              isUrdu(uploadFileValues.fileUrl)
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            value={uploadFileValues.fileUrl}
                            onChange={handleInputChange}
                            {...(errors?.fileUrl && { invalid: true })}
                          />
                          {errors?.fileUrl && (
                            <div className="text-error">{errors.fileUrl}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  ) : null}
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup>
                      <Label for="description">
                        <GetLanguageString props={"common_description"} />
                      </Label>
                      <Input
                        className={
                          isUrdu(uploadFileValues.description)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                        type="text"
                        name="description"
                        value={uploadFileValues.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup style={{ marginBottom: 30 }}>
                      <Label>
                        {" "}
                        {
                          <GetLanguageString props="member_member_detail_select_documents_type" />
                        }
                      </Label>
                      <Select
                        value={uploadFileValues.documentType}
                        options={documentsTypesDropdown}
                        className={
                          GetSelectedLanguage() === "ur"
                            ? "basic-multi-select ur no-drag"
                            : "basic-multi-select no-drag"
                        }
                        classNamePrefix="select"
                        onChange={(record) => {
                          handleChangeDocumentType(record);
                        }}
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup>
                      <Label for="tag-selector">
                        {<GetLanguageString props="document_document_tags" />}
                      </Label>
                      <CreateableTags
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                      />
                    </FormGroup>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col md="6">
                    <Label check for={"inheritFolderPermissions"}>
                      <Input
                        type="radio"
                        id="inheritFolderPermissions"
                        value={2}
                        name="inheritFolderPermissions"
                        checked={inheritFolderPermissions === 2}
                        onChange={handleInheritFolderPermissions}
                      />
                      &nbsp;&nbsp;
                      {
                        <GetLanguageString
                          props={"folder_document_inherit_permissions"}
                        />
                      }
                    </Label>
                  </Col>
                  <Col md="6">
                    <Label check for={"filePermissions"}>
                      <Input
                        type="radio"
                        id="filePermissions"
                        value={1}
                        name="filePermissions"
                        checked={filePermissions === 1}
                        onChange={handleFilePermissions}
                      />
                      &nbsp;&nbsp;
                      {
                        <GetLanguageString
                          props={"folder_document_apply_permissions"}
                        />
                      }
                    </Label>
                  </Col>
                </Row>
                {filePermissions === 1 && (
                  <>
                    <br></br>
                    <div className="ibox-content">
                      <Row>
                        <Col md="11"></Col>
                        <Col md="1" className="text-center">
                          <div>
                            <FaSquarePlus
                              color="green"
                              size={25}
                              style={{ cursor: "pointer" }}
                              onClick={addPermission}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {permissions?.map((item, index) => {
                          return (
                            <>
                              <div>
                                <br></br>
                                <Permissions
                                  item={item}
                                  index={index}
                                  permissions={permissions}
                                  setPermissions={setPermissions}
                                />
                                <br></br>
                              </div>
                              {permissions.length !== 1 && (
                                <Row>
                                  <Col md="11"></Col>
                                  <Col md="1" className="text-center">
                                    <div>
                                      <FaSquareMinus
                                        color="red"
                                        size={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => removeObject(index)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <div>
                                {index + 1 !== permissions.length && (
                                  <div class="divider div-transparent div-dot"></div>
                                )}
                                <br></br>
                              </div>
                            </>
                          );
                        })}
                      </Row>
                    </div>
                  </>
                )}
              </Form>
            </ModalBody>
          </Form>
        ) : (
          LoadingSpinner()
        )}
        <ModalFooter>
          <Button color="white" onClick={toggle}>
            {<GetLanguageString props="common_cancel" />}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {<GetLanguageString props="common_save_changes" />}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default EditFile;
