import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link, withRouter } from "react-router-dom";
import { Button, ButtonGroup, Table } from "reactstrap";

import { IoAdd } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import useSorting from "../shared/useSorting";
import { ApiUnit } from "../unit/ApiUnit";
import AddMemberModal from "./AddMemberModal";
import { addMember } from "./ApiMember";
import CancelMemberRegistration from "./CancelMemberRegistration";
import MoveMemberModal from "./MoveMemberModal";
import SearchMembers from "./SearchMembers";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  birthDate: "neutral",
  memberType: "neutral",
};

const Members = ({ history, match }) => {
  const selectedLanguage = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [selectSerialNumberModal, setSelectSerialNumberModal] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const isIncharge = encryptStorage.getItem("canManage");
  const [members, setMembers] = useState([]);
  const [unitId, setUnitId] = useState();
  const [memberId, setMemberId] = useState();
  const [check, setCheck] = useState();
  const [moveMemberModal, setMoveMemberModal] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState(false);
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
    unitTypeName: unitTypeName,
  });
  const [searchButton, setSearchButton] = useState(false);
  const [cancelMemberRegistrationModal, setCancelMemberRegistrationModal] =
    useState(false);
  const [memberIdForCancellation, setMemberIdForCancellation] = useState();
  const [searchMemberModel, setSearchMemberModel] = useState({
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    activePage: 1,
    isSubUnits: true,
    sortModel: {},
  });
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          setSearchMemberModel({
            unitId: temp.id,
            activePage: 1,
          });
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }
  }, [unit.unitId, loggedInUnitId]);

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isFullNameDescending: sortObject.name === "descending",
      isFullNameAscending: sortObject.name === "ascending",
      isBirthDateDescending: sortObject.birthDate === "descending",
      isBirthDateAscending: sortObject.birthDate === "ascending",
      isJoiningDateDescending: sortObject.joiningDate === "descending",
      isJoiningDateAscending: sortObject.joiningDate === "ascending",
      isMemberTypeAscending: sortObject.memberType === "ascending",
      isMemberTypeDescending: sortObject.memberType === "descending",
    };
    searchMemberModel.sortModel = tempSortObject;
    if (searchButton) {
      addMember()
        .getMember(searchMemberModel)
        .then((res) => {
          setMembers(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [
    searchMemberModel,
    sortObject,
    cancelMemberRegistrationModal,
    searchButton,
    updatedDetails,
  ]);

  const handleOnMoveMember = (e, memberId) => {
    const { value, name } = e.target;
    setUnitId(value);
    setCheck(name);
    setMemberId(memberId);
    setMoveMemberModal(true);
  };

  const handlePageChange = (pageNumber) => {
    setSearchMemberModel({
      ...searchMemberModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  const handleOnDelete = (id) => {
    setMemberIdForCancellation(id);
    setCancelMemberRegistrationModal(true);
  };

  return (
    <div>
      <>
        <ToastContainer
          autoClose={3000}
          closeOnClick
          bodyClassName={selectedLanguage}
          pauseOnHover={false}
          draggable
        />
        {moveMemberModal && (
          <MoveMemberModal
            memberId={memberId}
            unitId={unitId}
            check={check}
            setMoveMemberModal={setMoveMemberModal}
            setRefresh={setUpdatedDetails}
            refresh={updatedDetails}
          />
        )}
        {selectSerialNumberModal && (
          <AddMemberModal
            setSelectSerialNumberModal={setSelectSerialNumberModal}
            unit={unit}
            request={false}
            setSendRequest={null}
            notificationId={null}
            sendRequest={null}
            refreshList={null}
            setActionModal={null}
            setRefreshList={null}
            fromMemberCheck={
              CheckPermission("CanAddMember") || CheckIsAdmin()
                ? false
                : CheckPermission("CanRequestAddMember")
                ? true
                : false
            }
            setUnit={setUnit}
          />
        )}
        {cancelMemberRegistrationModal && (
          <CancelMemberRegistration
            setCancelMemberRegistrationModal={setCancelMemberRegistrationModal}
            memberId={memberIdForCancellation}
          />
        )}
      </>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="member_search_member" />}</h5>
            {CheckPermission("CanAddMember") ||
            CheckPermission("CanRequestAddMember") ? (
              <Button
                color="primary"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => {
                  setSelectSerialNumberModal(true);
                }}
              >
                <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
                {<GetLanguageString props="member_add_member" />}
              </Button>
            ) : null}
          </div>
          <div className="ibox-content">
            <SearchMembers
              unitId={unit.unitId}
              setUnit={setUnit}
              searchMemberModel={searchMemberModel}
              setSearchMemberModel={setSearchMemberModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox ">
            <div className="ibox-title">
              <h5>
                {<GetLanguageString props="member_report_members_details" />}
              </h5>
            </div>
            <div className="ibox-content">
              {!loading ? (
                members.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("name")}
                          >
                            <GetLanguageString props="common_name" />
                            <GetSortingUI sortObject={sortObject} name="name" />
                          </th>
                          <th>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th>
                            {<GetLanguageString props="member_address" />}
                          </th>
                          <th>{<GetLanguageString props="member_phone" />}</th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("memberType")}
                          >
                            <GetLanguageString props="settings_member_type" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="memberType"
                            />
                          </th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                          {isIncharge ? (
                            <th className="text-center">
                              {<GetLanguageString props="common_action" />}
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {members?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td className="p-t-custom">
                                {index + recordIndex}
                              </td>
                              <td className="p-t-custom">{record.regNo}</td>
                              <td className="p-t-custom">
                                {applyClassToCharacters(record.fullName, "ur")}
                              </td>
                              <td className="p-t-custom">
                                {applyClassToCharacters(
                                  record.fatherName,
                                  "ur"
                                )}
                              </td>
                              <td>
                                {record.currentAddress
                                  ? applyClassToCharacters(
                                      record.currentAddress,
                                      "ur p-t-custom"
                                    )
                                  : ""}
                              </td>
                              <td className="p-t-custom p-t-custom">
                                {record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))}
                              </td>
                              <td>
                                {
                                  <Button
                                    size="xs"
                                    color="info"
                                    className="label label-info m-r-xs"
                                  >
                                    <h5 className="ur">{record.memberType}</h5>
                                  </Button>
                                }
                              </td>
                              <td>
                                {
                                  <Button
                                    size="xs"
                                    color="info"
                                    className="label label-info m-r-xs"
                                  >
                                    <h5 className="ur">{record.unitName}</h5>
                                  </Button>
                                }
                              </td>
                              {isIncharge ? (
                                <td className="text-center">
                                  <ButtonGroup>
                                    {CheckIsAdmin() ? (
                                      <Button
                                        color="white"
                                        size="m"
                                        onClick={(e) =>
                                          handleOnMoveMember(e, record.id)
                                        }
                                        name="admin"
                                        value={record.unitId}
                                      >
                                        {
                                          <GetLanguageString props="unit_unitmember_move_member" />
                                        }
                                      </Button>
                                    ) : null}
                                    {!CheckIsAdmin() &&
                                    (CheckPermission(
                                      "CanMoveMembersLocalTransfer"
                                    ) ||
                                      CheckPermission(
                                        "CanRequestMoveMembersLocalTransfer"
                                      )) ? (
                                      <Button
                                        color="white"
                                        size="m"
                                        onClick={(e) =>
                                          handleOnMoveMember(e, record.id)
                                        }
                                        name="Local"
                                        value={record.unitId}
                                      >
                                        {unitTypeName === "Halqa" ? (
                                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                                        ) : unitTypeName === "Tanzeem" ? (
                                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                                        ) : (
                                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                                        )}
                                      </Button>
                                    ) : null}
                                    {!CheckIsAdmin() &&
                                    (CheckPermission(
                                      "CanMoveMembersOutsideTransfer"
                                    ) ||
                                      CheckPermission(
                                        "CanRequestMoveMembersOutsideTransfer"
                                      )) ? (
                                      <Button
                                        color="white"
                                        size="m"
                                        onClick={(e) =>
                                          handleOnMoveMember(e, record.id)
                                        }
                                        name="Outside"
                                        value={record.unitId}
                                      >
                                        {unitTypeName === "Halqa" ? (
                                          <GetLanguageString props="unit_unitmember_move_members_other" />
                                        ) : unitTypeName === "Tanzeem" ? (
                                          <GetLanguageString props="unit_unitmember_move_members_other" />
                                        ) : (
                                          <GetLanguageString props="unit_unitmember_move_members_other" />
                                        )}
                                      </Button>
                                    ) : null}
                                    <Link
                                      to={{
                                        pathname: `${match.url}/membersdetails/${record.id}`,
                                        state: {
                                          unitId: record.unitId,
                                          isEditable: record.isEditable,
                                          isDeletable: record.isDeletable,
                                          canModifyType: record.canModifyType,
                                        },
                                      }}
                                      className="btn btn-small btn-white"
                                    >
                                      {
                                        <GetLanguageString props="member_detail_btn" />
                                      }
                                    </Link>
                                    {record.isEditable ? (
                                      <Link
                                        to={{
                                          pathname: `${match.url}/updateMember/${record.id}`,
                                          state: {
                                            unitId: loggedInUnitId,
                                            currentUnitId: record.unitId,
                                            unitName: "",
                                            canModifyType: record.canModifyType,
                                            addNewRafiq: false,
                                          },
                                        }}
                                        className="btn btn-small btn-white"
                                      >
                                        {
                                          <GetLanguageString props="common_edit" />
                                        }
                                      </Link>
                                    ) : null}
                                    {record.isDeletable ? (
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={() =>
                                          handleOnDelete(record.id)
                                        }
                                      >
                                        {
                                          <GetLanguageString props="member_cancel_registration" />
                                        }
                                      </Button>
                                    ) : null}
                                  </ButtonGroup>
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="11">
                            <ul className={GetCustomStyles().page_style}>
                              <Pagination
                                activePage={pagination.activePage}
                                itemsCountPerPage={pagination.pageSize}
                                totalItemsCount={pagination.totalItem}
                                pageRangeDisplayed={pagination.pageRange}
                                onChange={handlePageChange}
                              />
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Members);
