import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegFileArchive, FaRegFileImage, FaRegFilePdf } from "react-icons/fa";
import { FcFile, FcFolder, FcLink, FcVideoCall } from "react-icons/fc";
import { IoIosClose } from "react-icons/io";
import { RiFileExcel2Line, RiFilePpt2Line } from "react-icons/ri";
import { TbFileTypeZip } from "react-icons/tb";
import { Col } from "reactstrap";
import {
  applyClassToCharacters,
  capitalizeFirstLetter,
  isUrdu,
} from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";

const selectedLanguage = GetSelectedLanguage();
const DetailBox = ({
  detailBox,
  setDetailBox,
  selectedFile,
  formatSize,
  getUnitName,
}) => {
  const { t } = useTranslation();
  if (!detailBox) return null;

  const getFileIcon = () => {
    const iconSize = 50;
    const iconColor = "#4A90E2";
    if (selectedFile?.fileType.includes("folder"))
      return <FcFolder size={iconSize} />;
    if (selectedFile?.fileType.includes("pdf"))
      return <FaRegFilePdf size={iconSize} color="red" />;
    if (
      selectedFile?.fileType.includes("jpg") ||
      selectedFile?.fileType.includes("png") ||
      selectedFile?.fileType.includes("jpeg")
    )
      return <FaRegFileImage size={iconSize} color={iconColor} />;
    if (
      selectedFile?.fileType.includes("pptx") ||
      selectedFile?.fileType.includes("presentation")
    )
      return <RiFilePpt2Line size={iconSize} color="#B94623" />;
    if (selectedFile?.fileType.includes("mp4"))
      return <FcVideoCall size={iconSize} />;
    if (selectedFile?.fileType.includes("x-zip-compressed"))
      return <TbFileTypeZip size={iconSize} color="#5A509B" />;
    if (selectedFile?.fileType.includes("x-compressed"))
      return <FaRegFileArchive size={iconSize} color="#5A509B" />;
    if (selectedFile?.fileType.includes("spreadsheet"))
      return <RiFileExcel2Line size={iconSize} color="#009440" />;
    if (selectedFile?.fileType.includes("link"))
      return <FcLink size={iconSize} />;
    return <FcFile size={iconSize} />;
  };

  return (
    <Col md="3" className="p-0">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-primary p-1 text-white d-flex justify-content-between align-items-center">
          <h4 className="m-2 font-weight-bold">
            <GetLanguageString props={"member_detail_btn"} />
          </h4>
          <IoIosClose onClick={() => setDetailBox(false)} size={30} />
        </div>
        <div className="p-3">
          <div className="d-flex align-items-center">
            <div className="bg-light rounded-circle p-1">
              {getFileIcon()}
            </div>
            <h3 className="m-3 font-weight-bold ">
              {applyClassToCharacters(selectedFile?.name, "ur")}
            </h3>
          </div>
          <DetailItem
            label={t("common_type")}
            value={capitalizeFirstLetter(selectedFile?.fileType)}
          />
          <DetailItem
            label={
              <GetLanguageString props="member_member_detail_document_size" />
            }
            value={selectedFile?.size ? formatSize(selectedFile?.size) : "-"}
          />
          <DetailItem
            label={t("settings_document_type")}
            value={selectedFile?.documentType || "-"}
          />
          <DetailItem
            label={<GetLanguageString props="common_description" />}
            value={applyClassToCharacters(
              selectedFile?.description || "-",
              "ur"
            )}
          />
          <DetailItem
            label={<GetLanguageString props="document_uploader_name" />}
            value={selectedFile?.uploaderName}
            className="ur"
          />
          <DetailItem
            label={
              <GetLanguageString props="document_uploader_responsibility" />
            }
            value={applyClassToCharacters(
              selectedFile?.uploaderResponsibility,
              "ur"
            )}
          />
          <DetailItem
            label={<GetLanguageString props="document_uploader_unit" />}
            value={getUnitName(selectedFile?.uploaderUnit)}
          />
          <DetailItem
            label={<GetLanguageString props="tags" />}
            value={
              <div className="d-flex">
                {selectedFile?.tags?.map((item, index) => (
                  <span
                    key={index}
                    className={`bg-primary text-white rounded-pill px-2 py-1 mr-1 small ${
                      isUrdu(item) ? "ur" : ""
                    }`}
                  >
                    {item}
                  </span>
                ))}
              </div>
            }
          />
        </div>
      </div>
    </Col>
  );
};

const DetailItem = ({ label, value, className = "" }) => (
  <div className="d-flex justify-content-between py-2 border-bottom">
    <span>{label}:</span>
    <span
      className={
        selectedLanguage === "ur"
          ? `text-left ${className}`
          : `text-right ${className}`
      }
    >
      {value}
    </span>
  </div>
);

export default DetailBox;
