import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { GetLanguageString } from "../../helper/Components";

import Draggable from "react-draggable";
import {
  LoadingSpinner,
  convertToDateString,
  isUrdu,
} from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";

const MemberResponsibilityRenewal = ({
  memberResponsibilityId,
  setMemberResponsibilityRenewalModal,
  setResponsibilityStateChange,
}) => {
  const toggle = () => {
    setMemberResponsibilityRenewalModal(false);
  };
  const [errors, setErrors] = useState();
  const [values, setValues] = useState({
    responsibilityRenewalDate: null,
    memberResponsibilityId: memberResponsibilityId,
    referenceNote: "",
  });
  const [responsibilityRenewalDate, setResponsibilityRenewalDate] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.responsibilityRenewalDate =
      responsibilityRenewalDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_responsibility_renewal_date_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleInputChangeResponsibilityRenewalDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setResponsibilityRenewalDate(value);
        setErrors({
          ...errors,
          responsibilityRenewalDate: "",
        });
      } else {
        setErrors({
          ...errors,
          responsibilityRenewalDate:
            GetSelectedLanguage() === "en"
              ? "The End date must not be greater than current date."
              : "ذمہ داری ک ختم ہونے ک تاریخ موجودہ تاریخ سی بری نہیں ہو سکتی",
        });
      }
    } else {
      setResponsibilityRenewalDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      values.responsibilityRenewalDate = convertToDateString(
        responsibilityRenewalDate
      );
      setButtonDisable(true);
      setLoading(true);
      addMember()
        .memberResponsibilityRenewal(values)
        .then((res) => {
          swal({
            title: t("member_responsibility_renew_success"),
            icon: "success",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
          });
          setResponsibilityStateChange((prevState) => !prevState);
          setLoading(false);
          setButtonDisable(false);
          toggle();
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal size="m" isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_responsibility_renewal_title" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        <GetLanguageString props="member_responsibility_renewal_date_title" />
                      </Label>
                      <DatePicker
                        showYearDropdown
                        placeholderText="dd/mm/yyyy"
                        className="form-control no-drag"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        selected={responsibilityRenewalDate}
                        onChange={(value) =>
                          handleInputChangeResponsibilityRenewalDate(value)
                        }
                      />
                    </FormGroup>
                  </Row>
                  {errors?.responsibilityRenewalDate && (
                    <h4 className="text-error">
                      {errors?.responsibilityRenewalDate}
                    </h4>
                  )}{" "}
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_reference_note" />}
                      </Label>
                      <Input
                        type="text"
                        name="referenceNote"
                        className={
                          isUrdu(values.referenceNote)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                        value={values.referenceNote}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Row>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberResponsibilityRenewal;
